import React, { useCallback, useState } from "react";
import { MessageBody } from "../../../common/home/models/MessageBody";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import TypingDots from "../../../widgets/TypingDots";
import { updateIsDetails, updateIsVote } from "../../../redux/utils";
import {
    addMessage,
    updateCurrentMessage,
    updateMessageSelectedTags,
    updateMessageStatus,
} from "../../../redux/messages";
import { updateIsError, updateIsSuccess } from "../../../redux/error";
import {
    sendMessage,
    sendMessageAnon,
    sendVote,
    sendVoteAnon,
} from "../../../common/home/api";
import {
    VoteDirection,
    VoteRequest,
} from "../../../common/home/requests/VoteRequest";
import { isMobile, maybeHandleError } from "../../../utils";
import { MessageTag } from "../../../widgets/MessageTag";
import { MainButton } from "../../../widgets/MainButton";
import {
    MessageTagТ,
    MessageType,
} from "../../../common/home/models/MessageType";
import { MessageRequest } from "../../../common/home/requests/MessageRequest";
import Vote from "../../vote/Vote";

function Message({ msg, isLast }: { msg: MessageBody; isLast: boolean }) {
    const dispatch = useAppDispatch();
    const messageTagsByMessageType = useAppSelector(
        (state) => state.messages.messageTagsByMessageType
    );
    const messages = useAppSelector((state) => state.messages.messages);
    const selectedMessageCategory = useAppSelector(
        (state) => state.chats.selectedMessageCategory
    );
    const customerId = useAppSelector((state) => state.user.customerId);

    const isDetails = useAppSelector((state) => state.utils.isDetails);
    const auth_token = useAppSelector((state) => state.user.access_token);
    const isVote = useAppSelector((state) => state.utils.isVote);
    const adminModeEnabled = useAppSelector(
        (state) => state.utils.adminModeEnabled
    );
    const isAdminUser = useAppSelector((state) => state.user);
    const isSocketConnected = useAppSelector(
        (state) => state.utils.isSocketConnected
    );
    const chat_id = useAppSelector((state) => state.chats.currentChatId);
    const gpt4Enabled = useAppSelector((state) => state.utils.gpt4Enabled);
    const modelName = gpt4Enabled ? "gpt-4" : "gpt-3.5-turbo";
    const [isLiked, setIsLiked] = useState<boolean>(false);
    const [isDisliked, setIsDisliked] = useState<boolean>(false);

    const [selectedTags, setSelectedTags] = useState<MessageTagТ["id"][]>([]);

    const containerClassName = isMobile
        ? "w-full flex flex-row items-start justify-start m-0 cursor-pointer message-container"
        : "w-full flex flex-row items-start justify-start m-0 cursor-pointer message-container";

    const pictureUrl = msg.isBot
        ? "/212-avatar.png"
        : "/default-user-avatar.svg";
    const pictureUrlDark = msg.isBot
        ? "/212-avatar.png"
        : "/default-user-avatar-dark.png";

    const copyToClipboard = () => {
        if (navigator.clipboard) {
            navigator.clipboard
                .writeText(msg.message)
                .then(() => {
                    dispatch(
                        updateIsSuccess({ message: "Copied to clipboard" })
                    );
                })
                .catch((err) => {
                    console.error("Failed to copy message: ", err);
                });
        } else {
            // Fallback for older browsers or insecure contexts
            const textArea = document.createElement("textarea");
            textArea.setAttribute(
                "style",
                "display:none; position:absolute; pointerEvents:none;"
            );
            textArea.value = msg.message;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand("copy");
                dispatch(updateIsSuccess({ message: "Copied to clipboard" }));
            } catch (err) {
                console.error("Failed to copy message: ", err);
            }
            document.body.removeChild(textArea);
        }
    };

    const onSubmitSelectedTags = useCallback(async () => {
        if (!selectedTags.length) {
            return;
        }

        const messageRequest = {
            message_type: msg.message_type,
            model_name: modelName,
            tags: selectedTags,
            question: "",
            chat_id: chat_id !== -1 ? chat_id : undefined,
        };

        try {
            if (auth_token) {
                await sendMessage({ ...messageRequest, auth_token });
            } else {
                // await sendMessageAnon({
                //   ...messageRequest,
                //   chat_history: messages.map((message) => message.message)
                // });
                // sendInquiryRequest(...inquiryRequest)
            }
        } catch (err) {
            return maybeHandleError(err, true);
        }

        dispatch(
            updateMessageSelectedTags({
                messageId: msg.message_id,
                tags: selectedTags,
            })
        );

        dispatch(
            addMessage({
                message_type: MessageType.general,
                isBot: true,
                message: "",
                docs: [
                    {
                        page_content: "",
                        metadata: {
                            chunk: "",
                            source: "",
                        },
                    },
                ],
                thoughts: [],
            })
        );
    }, [selectedTags, msg.message_type, modelName, chat_id, auth_token]);

    const handleUpvote = async () => {
        try {
            if (isLiked) {
                return;
            }
            setIsLiked(true);

            const vote: VoteRequest = {
                direction: VoteDirection.UPVOTE,
                category: selectedMessageCategory,
                metadata: {
                    ticker: null,
                },
            };
            let response;

            // if (auth_token) {
            //   response = await sendVote(vote, auth_token);
            // } else {
            //   response = await sendVoteAnon(vote);
            // }

            response = await sendVote(vote, customerId);

            if (response.status === 200) {
                // dispatch(
                //     updateMessageStatus({ id: msg.message_id, status: 1 })
                // );
            }
        } catch (error: any) {
            dispatch(
                updateIsError({
                    message: "Failed to upvote",
                    err: error.message,
                })
            );
        }
    };

    const shouldRenderTags =
        msg.isBot &&
        messageTagsByMessageType &&
        messageTagsByMessageType[msg.message_type] &&
        messageTagsByMessageType[msg.message_type].tags.length;

    return (
        <div
            className={containerClassName}
            style={{
                paddingTop: 10,
                paddingBottom: msg.isBot ? 20 : 10,
                borderRadius: 15,
                backgroundColor: !msg.isBot
                    ? "rgba(0, 167, 225, 0.05)"
                    : undefined,
                paddingLeft: 10,
                paddingRight: 10,
                cursor: "default",
                marginBottom: 10,
            }}
        >
            <div className="flex flex-col items-center w-12">
                <img
                    src={pictureUrl}
                    alt=""
                    className="dark:hidden flex-none w-14 h-full rounded-full object-cover bg-transparent mb-auto mt-0 mx-0"
                />
                <img
                    src={pictureUrlDark}
                    alt=""
                    className="hidden dark:flex flex-none w-14 h-full rounded-full object-cover mb-auto mt-0 mx-0"
                />
            </div>
            <div
                className="w-full flex flex-col justify-start"
                style={{
                    marginLeft: 10,
                }}
            >
                {msg.total_cost && adminModeEnabled && isAdminUser ? (
                    <p className="text-left font-bold text-sm dark:text-gray-300 mr-2">
                        {msg.total_tokens} 🪙
                    </p>
                ) : null}
                <div
                    className="w-full h-full rounded-lg"
                    style={{ display: "flex", flex: 1 }}
                >
                    {msg.message ? (
                        <div className="relative flex flex-1 flex-col">
                            <div
                                className="text-left whitespace-pre-wrap break-words w-full text-text1-light dark:text-text1-dark"
                                style={{
                                    wordBreak: "break-word",
                                    cursor: "text",
                                    fontSize: 15,
                                    fontWeight: 300,
                                    lineHeight: "25px",
                                    paddingTop: "10px",
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: msg.message,
                                }}
                            />
                            {shouldRenderTags ? (
                                <div
                                    className="flex flex-1 flex-row"
                                    style={{ flexWrap: "wrap" }}
                                >
                                    {messageTagsByMessageType[
                                        msg.message_type
                                    ].tags.map((tag) => (
                                        <MessageTag
                                            id={tag.id}
                                            tag_name={tag.tag_name}
                                            key={tag.id}
                                            isDisabled={
                                                !!msg.tags ||
                                                !isLast ||
                                                !isSocketConnected
                                            }
                                            isSelected={msg.tags?.includes(
                                                tag.id
                                            )}
                                            messageType={msg.message_type}
                                            onChangeSelection={(isSelected) => {
                                                if (
                                                    isSelected &&
                                                    !selectedTags.includes(
                                                        tag.id
                                                    )
                                                ) {
                                                    setSelectedTags([
                                                        ...selectedTags,
                                                        tag.id,
                                                    ]);
                                                } else if (
                                                    !isSelected &&
                                                    selectedTags.includes(
                                                        tag.id
                                                    )
                                                ) {
                                                    setSelectedTags(
                                                        selectedTags.filter(
                                                            (id) =>
                                                                id !== tag.id
                                                        )
                                                    );
                                                }
                                            }}
                                        />
                                    ))}
                                    {shouldRenderTags && !msg.tags && isLast ? (
                                        <div style={{ marginTop: 10 }}>
                                            <MainButton
                                                text="Confirm"
                                                onClick={onSubmitSelectedTags}
                                                isDisabled={
                                                    !selectedTags.length ||
                                                    !isLast ||
                                                    !isSocketConnected
                                                }
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}
                            {msg.isBot ? (
                                <div
                                    style={{
                                        display: "flex",
                                        flex: 1,
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        marginTop: 10,
                                        cursor: "default",
                                    }}
                                >
                                    {msg.categories?.length &&
                                    adminModeEnabled &&
                                    isAdminUser ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                flex: 1,
                                                marginRight: 20,
                                                cursor: "text",
                                                marginBottom: 10,
                                            }}
                                            className="text-text2-light dark:text-text2-dark whitespace-pre-wrap break-words"
                                        >
                                            <span
                                                className="border-stroke-light dark:border-stroke-dark"
                                                style={{
                                                    borderRightWidth: 1,
                                                    paddingRight: 10,
                                                }}
                                            >
                                                {(msg.categories?.length === 1
                                                    ? "Category: "
                                                    : "Categories: ") +
                                                    msg.categories?.join(", ")}
                                            </span>
                                            <span style={{ marginLeft: 10 }}>
                                                Response time:{" "}
                                                {msg.response_time != undefined
                                                    ? `${msg.response_time}s`
                                                    : "None"}
                                            </span>
                                            <span></span>
                                        </div>
                                    ) : null}
                                    <div
                                        style={{
                                            display: "flex",
                                            alignSelf: "flex-start",
                                            flexWrap: "wrap",
                                        }}
                                    >
                                        {/*  */}
                                        {msg.message ===
                                        messages[messages.length - 1]
                                            .message ? (
                                            <div>
                                                <button
                                                    className="transition duration-300 ease-in-out"
                                                    style={{ marginRight: 10 }}
                                                    onClick={handleUpvote}
                                                >
                                                    <img
                                                        src={
                                                            isLiked
                                                                ? "/upvote-button-selected.svg"
                                                                : "/upvote-button.svg"
                                                        }
                                                        className="dark:hidden"
                                                    />
                                                    <img
                                                        src={
                                                            isLiked
                                                                ? "/upvote-button-selected-dark.svg"
                                                                : "/upvote-button-dark.svg"
                                                        }
                                                        className="hidden dark:flex"
                                                    />
                                                </button>
                                                <button
                                                    className="transition duration-300 ease-in-out"
                                                    style={{ marginRight: 10 }}
                                                    onClick={() => {
                                                        if (isDisliked) {
                                                            return;
                                                        }

                                                        dispatch(
                                                            updateIsVote(
                                                                !isVote
                                                            )
                                                        );
                                                        dispatch(
                                                            updateCurrentMessage(
                                                                msg
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <img
                                                        src={
                                                            isDisliked
                                                                ? "/downvote-button-selected.svg"
                                                                : "/downvote-button.svg"
                                                        }
                                                        className="dark:hidden"
                                                    />
                                                    <img
                                                        src={
                                                            isDisliked
                                                                ? "/downvote-button-selected-dark.svg"
                                                                : "/downvote-button-dark.svg"
                                                        }
                                                        className="hidden dark:flex"
                                                    />
                                                </button>
                                            </div>
                                        ) : null}

                                        {/*  */}
                                        <button
                                            className="transition duration-300 ease-in-out "
                                            style={{ marginRight: 10 }}
                                            onClick={() => {
                                                if (msg.isBot === true) {
                                                    dispatch(
                                                        updateIsDetails(
                                                            !isDetails
                                                        )
                                                    );
                                                    dispatch(
                                                        updateCurrentMessage(
                                                            msg
                                                        )
                                                    );
                                                }
                                            }}
                                        >
                                            <img
                                                src="/details-button.svg"
                                                className="dark:hidden"
                                            />
                                            <img
                                                src="/details-button-dark.svg"
                                                className="hidden dark:flex"
                                            />
                                        </button>

                                        {/* <button
                      style={{ marginRight: 10 }}
                      className="transition duration-300 ease-in-out "
                      onClick={copyToClipboard}
                    >
                      <img src="/copy-button.svg" className="dark:hidden" />
                      <img
                        src="/copy-button-dark.svg"
                        className="hidden dark:flex"
                      />
                    </button> */}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    ) : (
                        <div style={{ paddingTop: 12 }}>
                            <TypingDots />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Message;
