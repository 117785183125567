import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "../hooks/hooks";
import { connect as connectWebSocket } from "../hooks/WebSocket";
import { isMobile } from "../utils";

export function ReconnectingBar() {
  const auth_token = useAppSelector((state) => state.user.access_token);
  const [shouldShow, setShouldShow] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const isSocketConnected = useAppSelector(
    (state) => state.utils.isSocketConnected
  );
  const isSocketConnecting = useAppSelector(
    (state) => state.utils.isSocketConnecting
  );

  const retryConnection = useCallback(() => {
    connectWebSocket(() => {});
  }, []);

  useEffect(() => {
    // Prevent 'Connected successfully' from showing on app init.
    const isInitTimeout = setTimeout(() => {
      setIsInitialized(true);
    }, 10000);

    return () => clearTimeout(isInitTimeout);
  }, []);

  useEffect(() => {
    // Prevent 'Connected successfully' from showing on logout / login.
    setIsInitialized(false);
    setShouldShow(false);

    const isInitTimeout = setTimeout(() => {
      setIsInitialized(true);
    }, 10000);

    return () => clearTimeout(isInitTimeout);
  }, [auth_token]);

  useEffect(() => {
    let hideTimeout: NodeJS.Timeout;

    if (!isInitialized) {
      setShouldShow(false);
      return;
    }

    if (!shouldShow && !isSocketConnected) {
      setShouldShow(true);
    } else if (shouldShow && isSocketConnected) {
      hideTimeout = setTimeout(() => {
        if (isSocketConnected) {
          setShouldShow(false);
        }
      }, 2000);
    }

    return () => clearTimeout(hideTimeout);
  }, [isSocketConnected, isSocketConnecting, shouldShow, isInitialized]);

  const text = isSocketConnected
    ? "Connected successfully."
    : isSocketConnecting
    ? "Trying to establish connection..."
    : "Could not establish connection.";

  return shouldShow && isInitialized ? (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: 1000,
        width: "100%",
        pointerEvents: "none"
      }}
    >
      <div
        className="bg-altBackground-light dark:bg-altBackground-dark text-text1-light dark:text-text1-dark border-stroke-light dark:border-stroke-dark shadow-xl"
        style={{
          display: "flex",
          alignSelf: "center",
          borderWidth: 1,
          borderRadius: 10,
          paddingLeft: 20,
          paddingRight: !isSocketConnected && !isSocketConnecting && !isMobile ? 10 : 20,
          paddingTop: 10,
          paddingBottom: 10,
          marginRight: 20,
          marginLeft: 20,
          marginTop: 20,
          minHeight: 55,
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "all"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile ? "column" : "row"
          }}
        >
          <span>{text}</span>
          {!isSocketConnecting && !isSocketConnected ? (
            <button
              className="bg-mainBackground-light dark:bg-mainBackground-dark transition duration-300 ease-in-out"
              style={{
                display: "flex",
                alignSelf: isMobile ? "center" : undefined,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 5,
                paddingBottom: 5,
                borderRadius: 10,
                marginLeft: isMobile ? 0 : 20,
                marginTop: isMobile ? 10 : 0
              }}
              onClick={retryConnection}
            >
              <span>Retry</span>
            </button>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}
