import React from "react";
import {useAppDispatch} from "../hooks/hooks";
import {updateIsAdmin, updateIsDatabaseChunks, updateIsDetails, updateIsPreferences, updateIsVote} from "../redux/utils";
import {BsPlus} from "react-icons/bs";

function CloseButton() {
    const dispatch = useAppDispatch();

    return (
        <button className="absolute top-0 right-0" type="button" onClick={() => {
            dispatch(updateIsDetails(false));
            dispatch(updateIsAdmin(false));
            dispatch(updateIsVote(false))
            dispatch(updateIsPreferences(false))
            dispatch(updateIsDatabaseChunks(false))
        }}>
            <div
                className="flex justify-center items-center transform w-10 h-10 mt-2 mr-2 bg-altBackground-light dark:bg-altBackground-dark text-text1-light dark:text-text1-dark rounded-3xl transition-all duration-300 hover:bg-red-500 rotate-45 hover:dark:bg-red-500 dark:rotate-45">
                <BsPlus size="28"/>
            </div>
        </button>
    );
}

export default CloseButton;