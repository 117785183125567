import {
    CategoryFlowSettings,
    FlowSettings,
} from "./../common/home/models/FlowSettings";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Chat, ChatList } from "../common/home/models/ChatBody";
import { resetStore } from "./resetStore";
import { MessageCategory } from "../common/home/models/MessageCategory";
import { ChatConfig } from "../common/home/models/ChatConfig";
import { LLMModel } from "../common/home/models/LLMModel";

type InitialState = {
    currentChatId: number;
    lastAnonChatId: number;
    chats: ChatList;
    anonChats: ChatList;
    selectedMessageCategory: MessageCategory;
    chatConfig: ChatConfig;
    selectedTicker: string;
    models: LLMModel[];
    categoryFlowSettings: CategoryFlowSettings[];
    olderThanFiltration: boolean;
    newerThanFiltration: boolean;
    vectorCollections: string[];
};

const initialState: InitialState = {
    currentChatId: -1,
    lastAnonChatId: -1,
    chats: [],
    anonChats: [],
    selectedMessageCategory: MessageCategory.PRODUCT_ASSISTANCE,
    selectedTicker: "None",
    chatConfig: {} as ChatConfig,
    models: [],
    categoryFlowSettings: [],
    olderThanFiltration: true,
    newerThanFiltration: true,
    vectorCollections: [],
};

export const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        updateCurrentChatId: (state, action: PayloadAction<number>) => {
            state.currentChatId = action.payload;
        },
        updateLastAnonChatId: (state, action: PayloadAction<number>) => {
            state.lastAnonChatId = action.payload;
        },
        setAllChats: (state, action: PayloadAction<ChatList>) => {
            state.chats = action.payload;
        },
        addChat: (state, action: PayloadAction<Chat>) => {
            state.chats.push(action.payload);
        },
        addAnonChat: (state, action: PayloadAction<Chat>) => {
            state.anonChats.push(action.payload);
        },
        clearChats: (state) => {
            state.chats = [];
        },
        clearAnonChats: (state) => {
            state.anonChats = [];
        },
        updateSelectedMessageCategory: (
            state,
            action: PayloadAction<MessageCategory>
        ) => {
            state.selectedMessageCategory = action.payload;
        },
        updateSelectedTicker: (state, action: PayloadAction<string>) => {
            state.selectedTicker = action.payload;
        },
        setChatConfig: (state, action: PayloadAction<ChatConfig>) => {
            state.chatConfig = action.payload;
        },
        updateFlowSettingsByCategory: (
            state,
            action: PayloadAction<CategoryFlowSettings>
        ) => {
            let categories = state.categoryFlowSettings.filter(
                (category) => category.category !== action.payload.category
            );
            categories.push(action.payload);
            state.categoryFlowSettings = categories;
            console.log(state.categoryFlowSettings);
        },
        updateModels: (state, action: PayloadAction<LLMModel[]>) => {
            state.models = action.payload;
        },
        updateOlderThanFiltration: (state, action: PayloadAction<boolean>) => {
            state.olderThanFiltration = action.payload;
        },
        updateNewerThanFiltration: (state, action: PayloadAction<boolean>) => {
            state.newerThanFiltration = action.payload;
        },
        setVectorCollections: (state, action: PayloadAction<string[]>) => {
            state.vectorCollections = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, () => initialState);
    },
});

export const {
    updateCurrentChatId,
    updateLastAnonChatId,
    setAllChats,
    addChat,
    addAnonChat,
    clearChats,
    clearAnonChats,
    updateSelectedMessageCategory,
    updateSelectedTicker,
    setChatConfig,
    updateModels,
    updateFlowSettingsByCategory,
    updateOlderThanFiltration,
    updateNewerThanFiltration,
    setVectorCollections
} = chatSlice.actions;
export default chatSlice.reducer;
