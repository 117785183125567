import axios, { AxiosResponse } from "axios";

import { LoginRequest } from "./requests/LoginRequest";
import { RegisterRequest } from "./requests/RegisterRequest";
import { User, isUser } from "./models/User";
import { axiosInstance } from "../../utils";

export async function sendLoginRequest(
  email: string,
  password: string
): Promise<User> {
  const loginBody: LoginRequest = { email, password };
  const user: User = { email: "", access_token: "", isAdmin: null };

  const response: AxiosResponse<User> = await axiosInstance.post(
    `/api/v0/login`,
    loginBody,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  if (!isUser(response.data)) {
    throw new Error("Invalid response data");
  }

  user.email = response.data.email;
  user.access_token = response.data.access_token;
  user.isAdmin = response.data.isAdmin;

  document.cookie = `t212ChatAuth=${user.access_token};path=/;max-age=${
    1000 * 60 * 60 * 24 * 14
  };`; // 14 Days expiration

  return user;
}

export async function sendRegisterRequest(
  email: string,
  password: string
): Promise<User> {
  const registerBody: RegisterRequest = { email, password };
  const user: User = { email: "", access_token: "", isAdmin: null };

  const response: AxiosResponse<User> = await axiosInstance.post(
    `/api/v0/register`,
    registerBody,
    {
      headers: {
        "Content-Type": "application/json"
      }
    }
  );

  if (!isUser(response.data)) {
    throw new Error("Invalid response data");
  }

  user.email = response.data.email;
  user.access_token = response.data.access_token;
  user.isAdmin = response.data.isAdmin;

  document.cookie = `t212ChatAuth=${user.access_token};path=/;max-age=${
    1000 * 60 * 60 * 24 * 14
  };`; // 14 Days expiration

  return user;
}

export async function changePassword(
  oldPassword: string,
  newPassword: string,
  auth_token: string
): Promise<void> {
  const response: AxiosResponse<{}> = await axiosInstance.post(
    `/api/v0/change-password`,
    {
      old_password: oldPassword,
      new_password: newPassword
    },
    {
      headers: {
        Authorization: `Bearer ${auth_token}`,
        "Content-Type": "application/json"
      }
    }
  );
}

export async function validateSession(auth_token: string): Promise<boolean> {
  try {
    await axiosInstance.get(`/api/v0/session/validate`, {
      headers: {
        Authorization: `Bearer ${auth_token}`,
        "Content-Type": "application/json"
      }
    });

    return true;
  } catch (err: any) {
    // Session invalid only if 401. If the request throws any other error, we assume the session is valid.
    if (err.response?.status === 401) {
      return false;
    } else {
      return true;
    }
  }
}
