import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetStore } from "./resetStore";
import { RootState } from "./store";
import { GraphPopupProps } from "../views/home/widgets/GraphPopup";
import { Chunk } from "../common/home/models/MessageBody";

export const currentLimitMessagesMaxCount = 2; // Max 10 messages
export const currentLimitMessagesMS = 5 * 60 * 1000; // Per 1 hour

type InitialState = {
    isDetails: boolean;
    isDatabaseChunks: boolean;
    isAdmin: boolean;
    isVote: boolean;
    isPreferences: boolean;
    isLoginOpen: boolean;
    isRegisterOpen: boolean;
    loginPopupMessage: string | undefined;
    isChangePasswordOpen: boolean;
    isNewChatAnonPopupOpen: boolean;
    isNewVersionAlertOpen: boolean;
    adminModeEnabled: boolean;
    gpt4Enabled: boolean;
    contextLimit: number;
    answerLimit: number;
    isSocketConnected: boolean;
    isSocketConnecting: boolean;
    graphPopup: GraphPopupProps;
    apiKey: string | undefined;
    followupChunks: Chunk[];
};

const initialState: InitialState = {
    isDetails: false,
    isAdmin: false,
    isVote: false,
    isPreferences: false,
    isLoginOpen: false,
    isRegisterOpen: false,
    loginPopupMessage: undefined,
    isChangePasswordOpen: false,
    isNewChatAnonPopupOpen: false,
    isNewVersionAlertOpen: false,
    adminModeEnabled: false,
    gpt4Enabled: false,
    contextLimit: -1,
    answerLimit: -1,
    isSocketConnected: false,
    isSocketConnecting: false,
    isDatabaseChunks: false,
    graphPopup: { open: false, selectedUrl: null },
    apiKey: undefined,
    followupChunks: [],
};

export const detailsSlice = createSlice({
    name: "util",
    initialState,
    reducers: {
        updateIsDetails: (state, action: PayloadAction<boolean>) => {
            state.isDetails = action.payload;
        },
        updateIsAdmin: (state, action: PayloadAction<boolean>) => {
            state.isAdmin = action.payload;
        },
        updateIsVote: (state, action: PayloadAction<boolean>) => {
            state.isVote = action.payload;
        },
        updateIsPreferences: (state, action: PayloadAction<boolean>) => {
            state.isPreferences = action.payload;
        },
        updateIsLoginOpen: (state, action: PayloadAction<boolean>) => {
            state.isLoginOpen = action.payload;
        },
        updateIsRegisterOpen: (state, action: PayloadAction<boolean>) => {
            state.isRegisterOpen = action.payload;
        },
        updateIsChangePasswordOpen: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.isChangePasswordOpen = true;
                state.isPreferences = false;
            } else {
                state.isChangePasswordOpen = false;
                state.isPreferences = true;
            }
        },
        updateIsNewChatAnonPopupOpen: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.isNewChatAnonPopupOpen = action.payload;
        },
        passwordChangedSuccessfully: (state) => {
            state.isChangePasswordOpen = false;
            state.isPreferences = false;
        },
        updateIsNewVersionAlertOpen: (
            state,
            action: PayloadAction<boolean>
        ) => {
            state.isNewVersionAlertOpen = action.payload;
        },
        updateAdminModeEnabled: (state, action: PayloadAction<boolean>) => {
            state.adminModeEnabled = action.payload;
        },
        updateModel: (state, action: PayloadAction<boolean>) => {
            state.gpt4Enabled = action.payload;
        },
        updateContextLimit: (state, action: PayloadAction<number>) => {
            state.contextLimit = action.payload;
        },
        updateAnswerLimit: (state, action: PayloadAction<number>) => {
            state.answerLimit = action.payload;
        },
        updateIsSocketConnected: (state, action: PayloadAction<boolean>) => {
            state.isSocketConnected = action.payload;
        },
        updateIsSocketConnecting: (state, action: PayloadAction<boolean>) => {
            state.isSocketConnecting = action.payload;
        },
        updateLoginPopupMessage: (
            state,
            action: PayloadAction<string | undefined>
        ) => {
            state.loginPopupMessage = action.payload;
        },
        updateIsDatabaseChunks: (state, action: PayloadAction<boolean>) => {
            state.isDatabaseChunks = action.payload;
        },
        updateGraphPopup: (state, action: PayloadAction<GraphPopupProps>) => {
            state.graphPopup = {
                open: action.payload.open,
                selectedUrl: action.payload.selectedUrl,
            };
        },
        updateApiKey: (state, action: PayloadAction<string | undefined>) => {
            state.apiKey = action.payload;
        },
        updateFollowups: (state, action: PayloadAction<Chunk[]>) => {
            state.followupChunks = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, () => initialState);
    },
});

export default detailsSlice.reducer;
export const {
    updateIsDetails,
    updateIsAdmin,
    updateIsVote,
    updateIsPreferences,
    updateIsLoginOpen,
    updateIsRegisterOpen,
    updateIsChangePasswordOpen,
    updateIsNewChatAnonPopupOpen,
    passwordChangedSuccessfully,
    updateIsNewVersionAlertOpen,
    updateAdminModeEnabled,
    updateModel,
    updateContextLimit,
    updateAnswerLimit,
    updateIsSocketConnected,
    updateIsSocketConnecting,
    updateLoginPopupMessage,
    updateIsDatabaseChunks,
    updateGraphPopup,
    updateApiKey,
    updateFollowups
} = detailsSlice.actions;
