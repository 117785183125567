import React, { useCallback, useEffect, useState } from "react";
import TopNavigationBar from "./TopNavigationBar";
import Bar from "./Bar";
import MessageInput from "./widgets/MessageInput";
import Message from "./widgets/Message";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
  getAllChats,
  getChatMessages,
  getLatestVersion,
  getMessageTypes
} from "../../common/home/api";
import { setAllChats, updateCurrentChatId } from "../../redux/chats";
import Error from "../../widgets/errors/Error";
import DetailsPopup from "./widgets/DetailsPopup";
import {
  connect as connectWebSocket,
  disconnect as disconnectWebSocket
} from "../../hooks/WebSocket";
import {
  addMessage,
  clearMessages,
  setMessageTagsByMessageType
} from "../../redux/messages";
import { useNavigate, useParams } from "react-router-dom";
import AdminPopup from "./widgets/AdminPopup";
import Success from "../../widgets/errors/Success";
import { isMobile, logOut } from "../../utils";
import VotePopup from "./widgets/VotePopup";
import PreferencesPopup from "./widgets/PreferencesPopup";
import NewVersionAlert from "./widgets/NewVersionAlert";
import { updateIsNewVersionAlertOpen } from "../../redux/utils";
import { APP_VERSION } from "../../version";
import RightBar from "./RightBar";
import { resetStore } from "../../redux/resetStore";
import SuggestedMessages from "./widgets/SuggestedMessages";
import { ReconnectingBar } from "../../widgets/ReconnectingBar";
import ChangePasswordPopup from "./widgets/ChangePasswordPopup";
import LoginPopup from "../auth/LoginPopup";
import RegisterPopup from "../auth/RegisterPopup";
import NewChatAnonPopup from "./widgets/NewChatAnonPopup";
import GraphPopup from "./widgets/GraphPopup";
import FollowUpsComponent from "./widgets/FollowUpsComponent";

function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const email = useAppSelector((state) => state.user.email);
  const authToken = useAppSelector((state) => state.user.access_token);
  const messages = useAppSelector((state) => state.messages.messages);
  const currentChatId = useAppSelector((state) => state.chats.currentChatId);
  const successMessage = useAppSelector((state) => state.error.isSuccess);
  const isDetails = useAppSelector((state) => state.utils.isDetails);
  const isAdmin = useAppSelector((state) => state.utils.isAdmin);
  const isVote = useAppSelector((state) => state.utils.isVote);
  const [didGetAllChats, setDidGetAllChats] = useState(false);
  const isSocketConnected = useAppSelector(
    (state) => state.utils.isSocketConnected
  );
  const followupChunks = useAppSelector((state) => state.utils.followupChunks);
  const messageInputRef = React.useRef<typeof MessageInput>();

  let { chatId } = useParams();

  const adminModeEnabled = useAppSelector(
    (state) => state.utils.adminModeEnabled
  );
  const isAdminUser = useAppSelector((state) => state.user.isAdmin);
  const accessToken: string = useAppSelector(
    (state) => state.user.access_token
  );

  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  const [showBar, setShowBar] = useState(!isMobile);
  const [hasSubmittedMessage, setHasSubmittedMessage] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Scroll currently opened chat to bottom of messages list
  useEffect(() => {
    if (messages) {
      scrollToBottom();
    }
  }, [messages]);

  // Reset empty state flag
  useEffect(() => {
    if (currentChatId !== -1) {
      setHasSubmittedMessage(false);
    }
  }, [currentChatId]);

  // On mount / auth token change logic
  useEffect(() => {
    // Clear everything
    if (!authToken) {
      dispatch(resetStore());
      setHasSubmittedMessage(false);
    }

    dispatch(clearMessages());
    dispatch(updateCurrentChatId(-1));
    // getMessageTypes(authToken).then((messageTypes) => {
    //   dispatch(setMessageTagsByMessageType(messageTypes));
    // });

    if (authToken) {
      getAllChats(email, authToken)
        .then((allChats) => {
          dispatch(setAllChats(allChats));

          setDidGetAllChats(true);

          if (chatId) {
            dispatch(updateCurrentChatId(-1));
            dispatch(clearMessages());
            openChat(Number(chatId));
          } else if (currentChatId !== -1) {
            // Open the previously opened chat or the first one in the list
            openChat();
          }
        })
        .finally(() => setDidGetAllChats(true));
    }
  }, [authToken]);

  useEffect(() => {
    if (!authToken) {
      return;
    }

    getAllChats(email, authToken)
      .then((allChats) => {
        dispatch(setAllChats(allChats));

        setDidGetAllChats(true);
      })
      .finally(() => setDidGetAllChats(true));
  }, [currentChatId]);

  // Newer app version check started ON MOUNT ONLY, everything will break if that changes.
  useEffect(() => {
    // Hack: when mounting reset the version alert state as the state is for some reason persisted in LS
    dispatch(updateIsNewVersionAlertOpen(false));

    let intervalId: NodeJS.Timer | undefined = undefined;

    try {
      // intervalId = setInterval(async () => {
      //   const version = await getLatestVersion();
      //   if (Number(version) > APP_VERSION) {
      //     clearInterval(intervalId);
      //     dispatch(updateIsNewVersionAlertOpen(true));
      //   }
      // }, 10000);
    } catch (err) {
      console.error(err);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const toggleMenu = useCallback(
    () => setShowBar(!showBar),
    [showBar, setShowBar]
  );

  const openChat = useCallback(
    (chatId?: number) => {
      let chatIdToOpen = chatId ?? currentChatId;

      if (!chatIdToOpen) {
        return;
      }

      dispatch(updateCurrentChatId(chatIdToOpen));

      if (isMobile && showBar) {
        toggleMenu();
      }

      dispatch(clearMessages());
      getChatMessages(chatIdToOpen, accessToken)
        .then((allMessagesChat) => {
          for (const message of allMessagesChat) {
            dispatch(addMessage(message));
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 401) {
            logOut();
          } else if (error.response && error.response.status === 403) {
            navigate("/home");
            dispatch(updateCurrentChatId(-1));
          } else {
            console.error(error);
            dispatch(updateCurrentChatId(-1));
          }
        });
    },
    [isMobile, toggleMenu, accessToken, currentChatId]
  );

  return (
      <div className="flex flex-col" style={{ height: "100%" }}>
          <div
              className="bg-mainBackground-light dark:bg-mainBackground-dark text-text-light dark:text-text1-dark border-altBackground-light dark:border-altBackground-dark"
              style={{
                  borderRadius: isMobile ? 0 : 10,
                  padding: 15,
                  margin: isMobile ? 0 : 10,
                  marginBottom: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomWidth: isMobile ? 1 : 0,
              }}
          >
              <span
                  style={{
                      textAlign: "center",
                      fontSize: 15,
                      lineHeight: "20px",
                      fontWeight: 300,
                  }}
              >
                  Not financial advice. This is an experimental AI chat
                  playground. Be aware of inaccurate information and
                  hallucinations by the bot. test
              </span>
          </div>
          {isMobile ? (
              <TopNavigationBar onClickBurger={() => setShowBar(!showBar)} />
          ) : null}
          <ReconnectingBar />
          <div className="flex flex-row transition duration-300 ease-in-out bg-altBackground-light dark:bg-altBackground-dark m-0 h-full overflow-hidden">
              <Error />
              <Success />
              {isDetails && <DetailsPopup />}
              {isAdmin && <AdminPopup />}
              {isVote && <VotePopup handleVoteSubmit={() => {}} />}
              <PreferencesPopup />
              <ChangePasswordPopup />
              <NewChatAnonPopup />
              <LoginPopup />
              <RegisterPopup />
              <NewVersionAlert />
              <GraphPopup />
              {showBar && (
                  <Bar openChat={openChat} toggleMenu={toggleMenu} />
              )}{" "}
              {currentChatId !== -1 || hasSubmittedMessage ? (
                  <div className="flex flex-1 flex-col transition duration-300 ease-in-out bg-altBackground-light dark:bg-altBackground-dark m-0 h-full overflow-hidden">
                      <div
                          className="flex flex-1 flex-col overflow-hidden transition duration-300 ease-in-out bg-mainBackground-light h-full dark:bg-mainBackground-dark"
                          style={{
                              marginTop: isMobile ? 1 : 10,
                              marginBottom: isMobile ? 0 : 10,
                              marginRight: isMobile ? 0 : 10,
                              marginLeft: isMobile ? 0 : 0,
                              borderRadius: isMobile ? 0 : 10,
                          }}
                      >
                          <div
                              className="flex flex-col items-center transition duration-300 ease-in-out bg-mainBackground-light dark:bg-mainBackground-dark h-full overflow-y-scroll"
                              style={{ paddingRight: 5 }}
                          >
                              <div
                                  className="w-full"
                                  style={{
                                      maxWidth: 850,
                                      display: "flex",
                                      flex: 1,
                                      flexDirection: "column",
                                  }}
                              >
                                  <div
                                      style={{
                                          display: "flex",
                                          flex: 1,
                                          flexDirection: "column",
                                          paddingLeft: 10,
                                          paddingTop: 20,
                                      }}
                                  >
                                      {Array.isArray(messages) &&
                                          messages.map((msg, index) => (
                                              <Message
                                                  key={index}
                                                  msg={msg}
                                                  isLast={
                                                      index ===
                                                      messages.length - 1
                                                  }
                                              />
                                          ))}
                                  </div>

                                  <div ref={messagesEndRef} />
                              </div>
                          </div>
                          <FollowUpsComponent
                              messageInputRef={messageInputRef}
                          />

                          <div
                              style={{
                                  display: "flex",
                                  flex: 1,
                                  width: "100%",
                                  paddingRight: 10,
                                  maxWidth: 850,
                                  alignSelf: "center",
                              }}
                          >
                              <MessageInput ref={messageInputRef} />
                          </div>
                      </div>
                  </div>
              ) : (
                  <SuggestedMessages
                      onSubmit={() => setHasSubmittedMessage(true)}
                  />
              )}
              {isAdminUser && adminModeEnabled && !isMobile ? (
                  <RightBar />
              ) : null}
          </div>
      </div>
  );
}

export default Home;
