import React from "react";
import { MdHowToVote } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function VoteMenuButton() {
  const navigate = useNavigate();

  return (
    <span
      className="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-200 ease-in-out"
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 10,
        paddingRight: 10,
        lineHeight: "30px",
        borderWidth: 1,
        borderRadius: 10,
        marginTop: 10,
        alignItems: "center",
        justifyContent: "center",
        fontSize: 15,
        fontWeight: 500,
        userSelect: "none",
        cursor: "pointer"
      }}
      onClick={() => {
        navigate("/vote");
      }}
    >
      Voting
    </span>
  );
}

export default VoteMenuButton;
