import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { updateIsLoginOpen, updateIsPreferences } from "../../../redux/utils";

export default function OpenAccountItem() {
  const dispatch = useAppDispatch();

  return (
    <div className="flex flex-row">
      {/* <div
        className="bg-altBackground-light dark:bg-altBackground-dark hover:bg-opacity-70 dark:hover:bg-opacity-70 transition duration-200 ease-in-out"
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          height: 50,
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
          overflow: "hidden",
          cursor: "pointer",
          margin: 10,
          marginRight: 0,
          borderRadius: 10,
          userSelect: "none"
        }}
        onClick={() => {
          dispatch(updateIsLoginOpen(true));
        }}
      >
        <div
          className="transition duration-300 ease-in-out flex"
          style={{ overflow: "hidden" }}
        >
          <span
            className="text-text1-light dark:text-text1-dark"
            style={{
              display: "inline-block",
              fontSize: 15,
              fontWeight: 500,
              lineHeight: "30px",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            Log in / Sign up
          </span>
        </div>
      </div> */}
      <div
        className="bg-altBackground-light dark:bg-altBackground-dark hover:bg-opacity-70 dark:hover:bg-opacity-70 transition duration-200 ease-in-out"
        style={{
          display: "none",
          justifyContent: "space-between",
          height: 50,
          alignItems: "center",
          paddingLeft: 10,
          paddingRight: 10,
          overflow: "hidden",
          cursor: "pointer",
          margin: 10,
          marginLeft: 10,
          borderRadius: 10
        }}
        onClick={() => {
          dispatch(updateIsPreferences(true));
        }}
      >
        {/* <img src="/settings-button.svg" className="dark:hidden" /> */}
        {/* <img src="/settings-button-dark.svg" className="hidden dark:flex" /> */}
        Settings
      </div>
    </div>
  );
}
