import React, {
    useEffect,
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    useLayoutEffect,
} from "react";
import { IoMdSend } from "react-icons/io";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { addMessage } from "../../../redux/messages";
import { MessageBody } from "../../../common/home/models/MessageBody";
import { MessageRequest } from "../../../common/home/requests/MessageRequest";
import { isMobile, maybeHandleError } from "../../../utils";
import { MessageType } from "../../../common/home/models/MessageType";
import TypingDots from "../../../widgets/TypingDots";
import { useSelector } from "react-redux";
import {
    getAllChats,
    sendInquiryRequest,
    sendMessage,
    sendMessageAnon,
} from "../../../common/home/api";
import {
    addAnonChat,
    clearAnonChats,
    setAllChats,
    updateCurrentChatId,
    updateLastAnonChatId,
} from "../../../redux/chats";
import { ChatHistoryMessage } from "../../../common/home/models/ChatHistoryMessage";
import { InquiryRequest } from "../../../common/home/requests/InquiryRequest";
import { MessageCategory } from "../../../common/home/models/MessageCategory";
import { FlowSettings } from "../../../common/home/models/FlowSettings";

const defaultLineHeight = 25;
const maxHeight = defaultLineHeight * 5;
const maxLength = 300;

const MessageInput = forwardRef(
    (
        props: {
            defaultText?: string;
            placeholderText?: string;
            onSubmit?: () => void;
        },
        ref
    ) => {
        const authToken = useAppSelector((state) => state.user.access_token);
        const customerId = useAppSelector((state) => state.user.customerId);
        const email = useAppSelector((state) => state.user.email);
        const categoryFlowSettings = useAppSelector(
            (state) => state.chats.categoryFlowSettings
        );

        const dispatch = useAppDispatch();
        const [input, setInput] = useState("");
        const [flowSettings, setFlowSettings] = useState<FlowSettings>(
            {} as FlowSettings
        );
        const [height, setHeight] = useState<number | undefined>(undefined);
        const chat_id = useAppSelector((state) => state.chats.currentChatId);
        const selectedMessageCategory = useAppSelector(
            (state) => state.chats.selectedMessageCategory
        );
        const selectedTicker = useAppSelector(
            (state) => state.chats.selectedTicker
        );
        const chatConfig = useAppSelector((state) => state.chats.chatConfig);
        const messages = useAppSelector((state) => state.messages.messages);
        const measureTextRef = useRef<HTMLSpanElement>(null);
        const gpt4Enabled = useAppSelector((state) => state.utils.gpt4Enabled);
        const contextLimit = useAppSelector(
            (state) => state.utils.contextLimit
        );
        const answerLimit = useAppSelector((state) => state.utils.answerLimit);
        const isSocketConnected = useAppSelector(
            (state) => state.utils.isSocketConnected
        );
        const currentChatId = useAppSelector(
            (state) => state.chats.currentChatId
        );
        const auth_token = useAppSelector((state) => state.user.access_token);

        const containerClassName = isMobile
            ? "transition duration-300 ease-in-out flex flex-row w-full items-center justify-between bottom-0 bg-altBackground-light dark:bg-altBackground-dark px-2 mt-3 mb-2 message-container"
            : "transition duration-300 ease-in-out flex flex-row w-full items-center justify-between bottom-2 bg-altBackground-light dark:bg-altBackground-dark px-2 mt-3 message-container";

        useImperativeHandle(ref, () => ({
            submit,
        }));

        useEffect(() => {
            setInput(props.defaultText ?? "");
        }, [props.defaultText]);

        // useEffect(() => {
        //     setFlowSettings(
        //         categoryFlowSettings.filter(
        //             (setting) => setting.category === selectedMessageCategory
        //         )[0]?.settings
        //     );
        // }, categoryFlowSettings);

        useEffect(() => {
            setInput(props.defaultText ?? "");
        }, [currentChatId]);

        useEffect(() => {
            setFlowSettings(
                categoryFlowSettings.filter(
                    (setting) => setting.category === selectedMessageCategory
                )[0]?.settings
            );
        }, [selectedMessageCategory, categoryFlowSettings]);

        useLayoutEffect(() => {
            const current = measureTextRef?.current;

            if (current) {
                if (current.clientHeight !== height) {
                    setHeight(current.clientHeight);
                }
            }
        }, [input]);

        const submit = async (externalInput?: string) => {
            const inputToSubmit = externalInput || input;

            if (inputToSubmit.length === 0) {
                return;
            }

            const messageUser: MessageBody = {
                message_type: MessageType.general,
                isBot: false,
                chat_id: chat_id,
                message: inputToSubmit,
                docs: [
                    {
                        page_content: "",
                        metadata: {
                            chunk: "",
                            source: "",
                        },
                    },
                ],
                thoughts: [],
            };

            const messageBot: MessageBody = {
                message_type: MessageType.general,
                isBot: true,
                message: "",
                docs: [
                    {
                        page_content: "",
                        metadata: {
                            chunk: "",
                            source: "",
                        },
                    },
                ],
                thoughts: [],
            };

            let modelName = "gpt-3.5-turbo";
            if (gpt4Enabled) {
                modelName = "gpt-4";
            }
            // let answer_limit = 0
            // let context_limit = 0
            // if (answerLimit === undefined || answerLimit < 0){
            //     answer_limit = -1
            // }
            // if (contextLimit === undefined || contextLimit < 0){
            //     context_limit = -1
            // }
            let messageRequest: MessageRequest = {
                message_type: MessageType.general,
                question: inputToSubmit,
                model_name: modelName,
                context_limit: contextLimit,
                answer_limit: answerLimit,
            };

            let history: ChatHistoryMessage[] = messages.map((message, i) => {
                const author = i % 2 === 0 ? "BOT" : "CLIENT";
                return {
                    message: message.message,
                    author: author,
                };
            });
            history = history.slice(-chatConfig.historyMessageCount);

            const inquiryRequest: InquiryRequest = {
                userQuery: inputToSubmit,
                category: selectedMessageCategory,
                metadata: {
                    ticker: selectedTicker === "None" ? null : selectedTicker,
                },
                chatHistory: history,
                temperature: flowSettings?.temperature || null,
                contextLimit: flowSettings?.contextLimit || null,
                chunksSearchLimit: flowSettings?.chunksSearchLimit || null,
                answerTokenLimit: flowSettings?.answerTokenLimit || null,
                modelName: flowSettings?.modelName || null,
                minimumChunkDistance:
                    flowSettings?.minimumChunkDistance || null,
                olderThanTimestamp: flowSettings?.olderThanTimestamp || null,
                newerThanTimestamp: flowSettings?.newerThanTimestamp || null,
            };

            if (chat_id !== -1) {
                messageRequest = { chat_id: chat_id, ...messageRequest };
            }

            try {
                if (auth_token) {
                    await sendMessage({ ...messageRequest, auth_token });
                } else {
                    const chatId = chat_id === -1 ? Date.now() : chat_id;
                    dispatch(updateLastAnonChatId(chatId));

                    // await sendMessageAnon({
                    //   ...messageRequest,
                    //   chat_id,
                    //   chat_history: messages.map((message) => message.message)
                    // });
                    sendInquiryRequest({ customerId, ...inquiryRequest });

                    // Create the chat here for anons
                    if (chat_id === -1) {
                        dispatch(clearAnonChats());
                        dispatch(
                            addAnonChat({
                                id: chatId,
                                chat_name: inputToSubmit.slice(0, 40),
                            })
                        );

                        dispatch(updateCurrentChatId(chatId));
                    }
                }
            } catch (err) {
                return maybeHandleError(err, true);
            }

            if (authToken) {
                getAllChats(email, authToken).then((allChats) => {
                    dispatch(setAllChats(allChats));

                    const chatIdToOpen = allChats[allChats.length - 1]?.id;

                    if (chatIdToOpen && currentChatId === -1) {
                        dispatch(
                            updateCurrentChatId(
                                allChats[allChats.length - 1]?.id
                            )
                        );
                    }
                });
            }
            dispatch(addMessage(messageUser));
            dispatch(addMessage(messageBot));

            setInput("");
            props.onSubmit?.();
        };

        const isBotThinking =
            messages[messages.length - 1]?.isBot &&
            messages[messages.length - 1]?.message === "";
        const buttonDisabled =
            input.trim().length === 0 || !isSocketConnected || isBotThinking;

        return (
            <div
                className="flex flex-1 flex-row"
                style={{ position: "relative", display: "flex" }}
            >
                {/* <div
          style={{
            display: "flex",
            position: "absolute",
            zIndex: 10,
            left: 20,
            bottom: 15,
            opacity: 0.6
          }}
        >
          <span
            className="text-text2-light dark:text-text2-dark"
            style={{
              fontSize: 12,
              lineHeight: "15px",
              fontWeight: 300
            }}
          >
            {input.length}/{maxLength}
          </span>
        </div> */}
                <div
                    className={containerClassName}
                    style={{
                        borderRadius: 10,
                        padding: 10,
                        bottom: 0,
                        marginLeft: 10,
                        position: "relative",
                        alignItems: "center",
                        minHeight: 45,
                        marginBottom: 10,
                    }}
                >
                    <textarea
                        maxLength={maxLength}
                        style={{
                            fontSize: 15,
                            fontWeight: 300,
                            lineHeight: `${defaultLineHeight}px`,
                            // Don't you dare touch the magic 5
                            height:
                                (input.length && height
                                    ? height
                                    : defaultLineHeight) + 5,
                            marginRight: 10,
                            resize: "none",
                            maxHeight,
                            paddingTop: 3,
                            minHeight:
                                window.innerWidth < 430 &&
                                props.placeholderText &&
                                props.placeholderText.length > 45
                                    ? 55
                                    : undefined,
                        }}
                        className="transition duration-300 ease-in-out hover:opacity-70 w-full font-semibold bg-transparent outline-none dark:text-text1-dark text-text1-light placeholder-text2-light dark:placeholder-text2-dark pl-1"
                        placeholder={
                            props.placeholderText ?? "Type your message..."
                        }
                        value={input}
                        onKeyDown={(event) => {
                            if (
                                !isMobile &&
                                event.key === "Enter" &&
                                !event.shiftKey
                            ) {
                                event.preventDefault();

                                if (isBotThinking) {
                                    return;
                                }
                                submit();
                            }
                        }}
                        onChange={(event) => {
                            setInput(event.target.value);
                        }}
                    />
                    <span
                        ref={measureTextRef}
                        className="w-full font-semibold bg-transparent outline-none dark:text-text1-dark text-text1-light placeholder-text2-light dark:placeholder-text2-dark pl-1"
                        style={{
                            position: "absolute",
                            bottom: 500,
                            overflow: "hidden",
                            wordBreak: "break-word",
                            fontSize: 15,
                            fontWeight: 300,
                            lineHeight: `${defaultLineHeight}px`,
                            paddingRight: 75,
                            left: 10,
                            opacity: 0,
                            pointerEvents: "none",
                            maxHeight,
                            whiteSpace: "pre-wrap",
                        }}
                    >
                        {input?.replaceAll(new RegExp(/\n/g), "\n")}
                    </span>
                </div>
                {isBotThinking ? (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <TypingDots />
                    </div>
                ) : (
                    <button
                        className="transition duration-300 ease-in-out mt-3"
                        style={{
                            backgroundColor: "#00A7E1",
                            width: 50,
                            height: 50,
                            borderRadius: 25,
                            marginLeft: 10,
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            flexShrink: 0,
                            alignSelf: "flex-start",
                            opacity: buttonDisabled ? 0.5 : undefined,
                        }}
                        type="button"
                        onClick={() => {
                            const lastIndex: number = messages.length - 1;
                            if (messages[lastIndex]?.message === "") {
                                return;
                            }
                            submit();
                        }}
                    >
                        <IoMdSend
                            size="22"
                            className="my-auto mx-2 cursor-pointer"
                            style={{
                                alignSelf: "center",
                                color: "#ffffff",
                                margin: 0,
                                marginLeft: 2,
                            }}
                        />
                    </button>
                )}
            </div>
        );
    }
);

export default MessageInput;
