import React from "react";
import {AiFillHome} from "react-icons/ai";
import {useNavigate} from "react-router-dom";

function HomeMenuButton() {
    const navigate = useNavigate();

    return (
        <span
            onClick={() => {
                navigate("/home");
            }}
        >
    <AiFillHome
        size="28"
        className="text-text2-light dark:text-text2-dark hover:text-text2-dark dark:hover:text-text1-dark mr-3 ml-4 transition duration-300 ease-in-out hover:text-blue-400 cursor-pointer"
    />
        </span>
    );
}

export default HomeMenuButton;
