import { MessageBody } from "./MessageBody";

export type MessageTagТ = { tag_name: string; id: number };

export type MessageTagsByMessageTypeТ = Record<
  MessageBody["message_type"],
  { tags: MessageTagТ[]; messageTypeId: number }
>;

export type MessageTypeResponse = {
  id: number;
  type: MessageBody["message_type"];
  tags: MessageTagТ[];
}[];

export enum MessageType {
  general = 1,
  stockAnalyse = 2,
  confirmation = 3
}
