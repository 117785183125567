import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetStore } from "./resetStore";

type InitialState = {
  email: string;
  access_token: string;
  isAdmin: boolean | null;
  customerId: string;
};

const initialState: InitialState = {
  email: "",
  access_token: "",
  isAdmin: null,
  customerId: Date.now().toString()
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateAccessToken: (state, action: PayloadAction<string>) => {
      state.access_token = action.payload;
    },
    updateEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    returnUserToDefault: (state) => {
      state.email = "";
      state.access_token = "";
      state.isAdmin = null;
    },
    updateIsAdminUser: (state, action: PayloadAction<boolean | null>) => {
      state.isAdmin = action.payload;
    },
    updateCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, () => initialState);
  }
});

export const {
  updateAccessToken,
  updateEmail,
  returnUserToDefault,
  updateIsAdminUser,
  updateCustomerId
} = userSlice.actions;
export default userSlice.reducer;
