import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import App from "./App";
import {Provider} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import store from "./redux/store";

const root = document.getElementById("root");
createRoot(root).render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>
);
