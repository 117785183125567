import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { updateIsError } from "../../redux/error";

function Error() {
  const dispatch = useAppDispatch();
  const errorInfo = useAppSelector((state) => state.error.isError);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(
      () => {
        dispatch(updateIsError(false));
        setShowMore(false);
      },
      showMore ? 10000 : 5000
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [errorInfo, showMore]);

  return errorInfo ? (
    <div
      style={{ marginTop: 85, pointerEvents: "none", zIndex: 100 }}
      className="fixed inset-0"
    >
      <div
        style={{
          pointerEvents: "all",
          borderWidth: 1,
          borderRadius: 10,
          overflow: "hidden"
        }}
        className="relative max-w-md mx-auto flex items-center justify-center px-6 py-4 bg-altBackground-light dark:bg-altBackground-dark border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark shadow-xl"
      >
        <div className="flex flex-col flex-1 text-center font-semibold">
          {typeof errorInfo === "boolean"
            ? "Something went wrong. Please try again."
            : errorInfo.message}
          {/* {typeof errorInfo !== "boolean" && errorInfo.err ? (
            <>
              <div
                onClick={() => setShowMore(!showMore)}
                style={{
                  cursor: "pointer",
                  pointerEvents: "all",
                  marginTop: 10
                }}
              >
                <span
                  style={{
                    alignSelf: "flex-start",
                    fontSize: 12,
                    textDecoration: "underline",
                    fontWeight: 400
                  }}
                >
                  {showMore ? "Hide info" : "More info"}
                </span>
              </div>
              {showMore ? (
                <div
                  className="text-center font-normal"
                  style={{
                    fontSize: 12,
                    backgroundColor: "rgba(256, 256, 256, 0.1)",
                    borderRadius: 5,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    marginTop: 10,
                    padding: 10,
                    alignItems: "flex-start"
                  }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        "<div style='display: flex; flex: 1;'><pre>" +
                        errorInfo.err +
                        "</pre></div>"
                    }}
                  />
                  {errorInfo.url ? <span>{errorInfo.url}</span> : null}
                </div>
              ) : null}
            </>
          ) : null} */}
        </div>
      </div>
    </div>
  ) : null;
}

export default Error;
