import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "./widgets/Button";
import Logo from "../../widgets/Logo";
import Error from "../../widgets/errors/Error";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { returnUserToDefault } from "../../redux/user";
import { sendRegisterRequest } from "../../common/auth/api";
import { updateIsError } from "../../redux/error";
import { updateAccessToken, updateEmail } from "../../redux/user";
import { maybeHandleError, validateEmail, validatePassword } from "../../utils";
import Popup from "../home/widgets/Popup";
import {
  updateIsLoginOpen,
  updateIsRegisterOpen,
  updateLoginPopupMessage
} from "../../redux/utils";
import { useSelector } from "react-redux";

function RegisterPopup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isRegisterOpen = useAppSelector((state) => state.utils.isRegisterOpen);
  const loginPopupMessage = useAppSelector(
    (state) => state.utils.loginPopupMessage
  );

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleRegister = () => {
    if (email === "" || password === "") {
      return;
    }

    if (!validateEmail(email)) {
      dispatch(
        updateIsError({
          message: "Please enter a valid email!"
        })
      );

      return;
    }

    if (!validatePassword(password)) {
      dispatch(
        updateIsError({
          message:
            "Password must be at least 6 characters long and cannot contain only spaces!"
        })
      );

      return;
    }

    sendRegisterRequest(email, password)
      .then((user) => {
        dispatch(returnUserToDefault());

        setEmail("");
        setPassword("");

        dispatch(updateAccessToken(user.access_token));
        dispatch(updateEmail(user.email));
        dispatch(updateIsRegisterOpen(false));
      })
      .catch((error) => {
        maybeHandleError(error, true);
      });
  };

  return isRegisterOpen ? (
    <Popup
      onClose={() => {
        dispatch(updateIsRegisterOpen(false));
        dispatch(updateLoginPopupMessage(undefined));
      }}
      width={390}
    >
      <div
        className="grid grid-cols-1 sm:grid-cols-1"
        style={{
          width: 390
        }}
      >
        <Error />
        <div className="bg-mainBackground-light dark:bg-mainBackground-dark flex flex-col justify-center">
          <div
            className="w-full mx-auto bg-mainBackground-light dark:bg-mainBackground-dark"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 30,
              borderRadius: 20
            }}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();

                handleRegister();
              }
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Logo />
            </div>
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                marginBottom: loginPopupMessage ? 20 : 30,
                marginTop: 10
              }}
              className="text-brand"
            >
              <span
                style={{
                  fontSize: 22,
                  lineHeight: "35px",
                  fontWeight: 500
                }}
              >
                AI chat
              </span>
            </div>
            {loginPopupMessage ? (
              <div
                className="bg-strongStroke-light dark:bg-strongStroke-dark"
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: 30,
                  borderRadius: 10,
                  padding: 10
                }}
              >
                <span
                  className="text-text3-light dark:text-text3-dark"
                  style={{
                    fontSize: 15,
                    lineHeight: "25px",
                    fontWeight: 300,
                    textAlign: "center"
                  }}
                >
                  {loginPopupMessage}
                </span>
              </div>
            ) : null}
            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 43
              }}
            >
              <span
                style={{
                  fontSize: 34,
                  lineHeight: "40px",
                  fontWeight: 300
                }}
                className="text-text1-light dark:text-text1-dark"
              >
                Create account
              </span>
            </div>
            <div className="flex flex-col" style={{ marginBottom: 13 }}>
              <label
                className="text-text2-light dark:text-text2-dark"
                style={{ fontSize: 12, fontWeight: 500, lineHeight: "19px" }}
              >
                EMAIL
              </label>
              <input
                className="border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark bg-mainBackground-light dark:bg-mainBackground-dark focus:outline-none"
                style={{
                  borderBottomWidth: 1,
                  paddingBottom: 10
                }}
                type="text"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoCapitalize="off"
              />
            </div>
            <div className="flex flex-col">
              <label
                className="text-text2-light dark:text-text2-dark"
                style={{ fontSize: 12, fontWeight: 500, lineHeight: "19px" }}
              >
                PASSWORD
              </label>
              <input
                className="border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark bg-mainBackground-light dark:bg-mainBackground-dark focus:outline-none"
                type="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                style={{
                  borderBottomWidth: 1,
                  paddingBottom: 10
                }}
                autoCapitalize="off"
              />
            </div>
            <Button text="Create account" onClick={handleRegister} />
            <button
              type="button"
              className="flex justify-center"
              style={{ marginTop: 10, display: "flex", alignSelf: "center" }}
            >
              <p>
                <span
                  className="ease-in-out text-text1-light dark:text-text1-dark"
                  style={{
                    textDecoration: "none",
                    fontSize: 15,
                    fontWeight: 500,
                    lineHeight: "40px",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    dispatch(updateIsRegisterOpen(false));
                    dispatch(updateIsLoginOpen(true));
                  }}
                >
                  Log in
                </span>
              </p>
            </button>
          </div>
        </div>
      </div>
    </Popup>
  ) : null;
}

export default RegisterPopup;
