import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { sendAccountApiKey } from "../../common/home/api";
import SettingsButton from "../../widgets/SettingsButton";
import VoteMenuButton from "../../widgets/VoteMenuButton";
import { updateIsError, updateIsSuccess } from "../../redux/error";
import FlowSettingsPopup from "./widgets/FlowSettingsPopup";
import {
    updateAnswerLimit,
    updateContextLimit,
    updateModel,
} from "../../redux/utils";
import Toggle from "react-toggle";

function RightBar() {
    const dispatch = useAppDispatch();

    const inputRef = React.useRef<HTMLInputElement>(null);
    const authToken = useAppSelector((state) => state.user.access_token);
    const gpt4Enabled = useAppSelector((state) => state.utils.gpt4Enabled);
    const contextLimit = useAppSelector((state) => state.utils.contextLimit);
    const answerLimit = useAppSelector((state) => state.utils.answerLimit);
    const [promptsPopup, setPromptsPopup] = useState<boolean>(false);

    const handleKeyDown = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === "Enter") {
            const inputValue = inputRef.current?.value;
            try {
                const response = await sendAccountApiKey(
                    inputValue || "",
                    authToken
                );
                dispatch(
                    updateIsSuccess({ message: "API key sent successfully!" })
                );
            } catch (error: any) {
                dispatch(
                    updateIsError({
                        message: "Sending API key failed!",
                        err: error?.message,
                    })
                );
                console.error("Error while sending account api key", error);
            }
            inputRef.current!.value = "";
        }
    };

    return (
        <>
            <div
                className="transition duration-300 ease-in-out flex flex-col bg-mainBackground-light dark:bg-mainBackground-dark z-10 overflow-hidden"
                style={{
                    width: "25%",
                    minWidth: 200,
                    maxWidth: 300,
                    borderRadius: 10,
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    margin: 10,
                    marginLeft: 0,
                    top: 60,
                    bottom: 0,
                    padding: 20,
                }}
            >
                <div
                    className={`flex flex-col items-center justify-end transition duration-300 ease-in-out bg-mainBackground-light dark:bg-mainBackground-dark`}
                >
                    <input
                        type="text"
                        ref={inputRef}
                        onKeyDown={handleKeyDown}
                        className="bg-altBackground-light dark:bg-altBackground-dark transition duration-300 ease-in-out hover:opacity-70 border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark focus:outline-none placeholder-text2-light dark:placeholder-text2-dark"
                        placeholder="Insert API Key"
                        style={{
                            width: "100%",
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderWidth: 1,
                            borderRadius: 10,
                            fontSize: 15,
                            fontWeight: 300,
                            lineHeight: "25px",
                        }}
                    />

                    <>
                        {promptsPopup && (
                            <FlowSettingsPopup
                                setPromptsPopup={setPromptsPopup}
                            />
                        )}

                        <VoteMenuButton />
                        <div
                            className="w-full border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                borderBottomWidth: 1,
                                borderTopWidth: 1,
                                justifyContent: "space-between",
                                paddingTop: 20,
                                paddingBottom: 20,
                                marginTop: 30,
                            }}
                        >
                            <span
                                style={{
                                    fontSize: 17,
                                    lineHeight: "30px",
                                    fontWeight: 300,
                                }}
                            >
                                GPT4 mode
                            </span>
                            <Toggle
                                className="gp4-toggle dark"
                                defaultChecked={gpt4Enabled}
                                icons={false}
                                onChange={() => {
                                    dispatch(updateModel(!gpt4Enabled));
                                }}
                            />
                        </div>
                        <div
                            className="border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                borderBottomWidth: 1,
                                justifyContent: "space-between",
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: 17,
                                    lineHeight: "30px",
                                    fontWeight: 300,
                                }}
                            >
                                Context limit
                            </span>
                            <input
                                onChange={(e) =>
                                    dispatch(
                                        updateContextLimit(
                                            Number(e.target.value)
                                        )
                                    )
                                }
                                defaultValue={
                                    contextLimit == -1
                                        ? undefined
                                        : contextLimit
                                }
                                type="text"
                                className="text-text1-light dark:text-text1-dark bg-altBackground-light dark:bg-altBackground-dark transition duration-300 ease-in-out hover:opacity-70 border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark focus:outline-none placeholder-text2-light dark:placeholder-text2-dark"
                                placeholder="context limit"
                                style={{
                                    width: "50%",
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    fontSize: 15,
                                    fontWeight: 300,
                                    lineHeight: "20px",
                                }}
                            />
                        </div>
                        <div
                            className="text-text1-light dark:text-text1-dark border-stroke-light dark:border-stroke-dark"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                borderBottomWidth: 1,
                                justifyContent: "space-between",
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: 17,
                                    lineHeight: "30px",
                                    fontWeight: 300,
                                }}
                            >
                                Answer limit
                            </span>
                            <input
                                onChange={(e) =>
                                    dispatch(
                                        updateAnswerLimit(
                                            Number(e.target.value)
                                        )
                                    )
                                }
                                defaultValue={
                                    answerLimit == -1 ? undefined : answerLimit
                                }
                                type="text"
                                className="text-text1-light dark:text-text1-dark bg-altBackground-light dark:bg-altBackground-dark transition duration-300 ease-in-out hover:opacity-70 border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark focus:outline-none placeholder-text2-light dark:placeholder-text2-dark"
                                placeholder="answer limit"
                                style={{
                                    width: "50%",
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    fontSize: 15,
                                    fontWeight: 300,
                                    lineHeight: "20px",
                                }}
                            />
                        </div>
                        <div
                            className="w-full text-text1-light dark:text-text1-dark border-stroke-light dark:border-stroke-dark"
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                borderBottomWidth: 1,
                                justifyContent: "space-between",
                                paddingTop: 20,
                                paddingBottom: 20,
                                alignItems: "center",
                            }}
                        >
                            <span
                                style={{
                                    fontSize: 17,
                                    lineHeight: "30px",
                                    fontWeight: 300,
                                }}
                            >
                                Prompts maintance
                            </span>
                            <button
                                onClick={(e) => setPromptsPopup(true)}
                                defaultValue={
                                    answerLimit == -1 ? undefined : answerLimit
                                }
                                className="bg-altBackground-light dark:bg-altBackground-dark transition duration-300 ease-in-out hover:opacity-70 border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark focus:outline-none placeholder-text2-light dark:placeholder-text2-dark"
                                style={{
                                    width: "50%",
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    fontSize: 15,
                                    fontWeight: 300,
                                    lineHeight: "20px",
                                    minWidth: 130,
                                }}
                            >
                                Change
                            </button>
                        </div>
                    </>
                </div>
            </div>
        </>
    );
}

export default RightBar;
