import { ChatList } from "./models/ChatBody";
import { Chunk, ChunkFields, MessageBody } from "./models/MessageBody";
import { MessageRequest, MessageRequestAnon } from "./requests/MessageRequest";
import { AllCollections } from "./models/AllCollections";
import { IndexAddRequestBody } from "./models/IndexAddRequestBody";
import { IndexDeleteRequestBody } from "./models/IndexDeleteRequestBody";
import { ActiveUsersRequestBody } from "./models/ActiveUsersRequestBody";
import { IndexUpdateRequestBody } from "./models/IndexUpdateRequestBody";
import { ResponseAddIndex } from "./requests/ResponseAddIndex";
import { ResponseGetIndex } from "./requests/ResponseGetIndex";
import { ResponseEditIndex } from "./requests/ResponseEditIndex";
import { VoteRequest } from "./requests/VoteRequest";
import { VoteBody } from "./models/VoteBody";
import { Prompt } from "./models/Prompt";
import { MessageType, MessageTypeResponse } from "./models/MessageType";
import { axiosInstance, maybeHandleError } from "../../utils";
import { MessageCategory } from "./models/MessageCategory";
import { MessageMetadata } from "./models/MessageMetadata";
import { ChatHistoryMessage } from "./models/ChatHistoryMessage";
import { InquiryRequest } from "./requests/InquiryRequest";
import { ChatConfig } from "./models/ChatConfig";
import { FlowSettings } from "./models/FlowSettings";
import { LLMModel } from "./models/LLMModel";
import { ImageResult } from "./models/ImageResult";
import { GraphResponse } from "./models/GraphResponse";
import { ApiKey, UpdateApiKeyRequest } from "./models/ApiKey";

export async function getUserPrompts(auth_token: string): Promise<Prompt[]> {
    try {
        const response = await axiosInstance.get<Prompt[]>(`/api/v0/prompts`, {
            headers: {
                Authorization: `Bearer ${auth_token}`,
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchApiKey(): Promise<ApiKey> {
    try {
        const response = await axiosInstance.get<ApiKey>(
            `/api/v0/api-key`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function updateApiKeyRequest(apiKey: string): Promise<UpdateApiKeyRequest> {
    try {
        const request: UpdateApiKeyRequest = {
            apiKey
        }
        const response = await axiosInstance.post<UpdateApiKeyRequest>(
            `/api/v0/api-key`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function updatePrompt(prompt: Prompt): Promise<void> {
    try {
        const request = {prompt: prompt.prompt}
        const response = await axiosInstance.post<Object>(
            `/api/v0/prompts/${prompt.type}`,
            request,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
    } catch (error) {
        throw error;
    }
}

export async function getPromptByType(promptType: string): Promise<Prompt> {
    try {
        const response = await axiosInstance.get<Prompt>(
            `/api/v0/prompts/${promptType}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getAllPromptTypes(): Promise<string[]> {
    try {
        const response = await axiosInstance.get<string[]>(
            `/api/v0/prompts/types`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}


export async function generateGraph(webpageUrl: string): Promise<string> {
    let graphRequest = {
        webpageUrl
    };
    try {
        const response = await axiosInstance.post<string>(
            `/api/v0/graphgen`,
            graphRequest,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                timeout: 120000
            }
        );

        return response.data
    } catch (error) {
        throw error;
    }
}

export async function resetUserPrompt(
    auth_token: string,
    prompt_name: string
): Promise<void> {
    try {
        const response = await axiosInstance.post(
            `/api/v0/prompts/reset`,
            { prompt_name: prompt_name },
            {
                headers: {
                    Authorization: `Bearer ${auth_token}`,
                    "Content-Type": "application/json",
                },
            }
        );
    } catch (error) {
        throw error;
    }
}

export async function getAllChats(
    email: string,
    auth_token: string
): Promise<ChatList> {
    try {
        const response = await axiosInstance.get<ChatList>(`/api/v0/chats`, {
            headers: {
                Authorization: `Bearer ${auth_token}`,
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error: any) {
        maybeHandleError(error);

        throw error;
    }
}

export async function getChatMessages(
    chatId: number,
    auth_token: string
): Promise<MessageBody[]> {
    try {
        const response = await axiosInstance.get<VoteBody[]>(
            `/api/v0/chats/${chatId}`,
            {
                headers: {
                    Authorization: `Bearer ${auth_token}`,
                    "Content-Type": "application/json",
                },
            }
        );

        const messages: MessageBody[] = [];

        response.data.forEach((message) => {
            if (message.question) {
                const questionMessage: MessageBody = {
                    message_type: message.message_type,
                    isBot: false,
                    message_id: message.message_id,
                    message: message.question,
                    docs: message.docs,
                };
                messages.push(questionMessage);
            }

            const answerMessage: MessageBody = {
                isBot: true,
                message_id: message.message_id,
                message_type: message.message_type,
                message: message.answer,
                docs: message.docs,
                status: message.status,
                categories: message.categories,
                qa_prompt: message.qa_prompt,
                categorization_prompt: message.categorization_prompt,
                qa_answer: message.qa_answer,
                categorization_answer: message.categorization_answer,
                response_time: message.response_time,
            };
            messages.push(answerMessage);
        });

        return messages;
    } catch (error) {
        throw error;
    }
}

export async function sendMessage({
    auth_token,
    question,
    model_name,
    context_limit,
    answer_limit,
    message_type,
    chat_id,
    tags,
}: {
    auth_token: string;
    question: string;
    model_name: string;
    message_type?: MessageBody["message_type"];
    context_limit?: number;
    answer_limit?: number;
    chat_id?: number;
    tags?: MessageBody["tags"];
}): Promise<void> {
    let messageRequest: MessageRequest = {
        question,
        model_name,
        context_limit,
        answer_limit,
        message_type: message_type ?? MessageType.general,
    };

    if (chat_id && chat_id !== -1) {
        messageRequest.chat_id = chat_id;
    }

    if (tags) {
        messageRequest.tags = tags;
    }

    try {
        await axiosInstance.post(`/api/v0/chat`, messageRequest, {
            headers: {
                Authorization: `Bearer ${auth_token}`,
                "Content-Type": "application/json",
            },
            timeout: 60000,
        });
    } catch (error) {
        throw error;
    }
}

export async function sendMessageAnon({
    question,
    chat_id,
    model_name,
    context_limit,
    answer_limit,
    message_type,
    chat_history,
    tags,
}: {
    question: string;
    model_name: string;
    chat_history: string[];
    message_type?: MessageBody["message_type"];
    chat_id?: number;
    context_limit?: number;
    answer_limit?: number;
    tags?: MessageBody["tags"];
}): Promise<void> {
    let messageRequest: MessageRequestAnon = {
        question,
        model_name,
        context_limit,
        answer_limit,
        message_type: message_type ?? MessageType.general,
        chat_history,
    };

    if (chat_id && chat_id !== -1) {
        messageRequest.chat_id = chat_id;
    }

    if (tags) {
        messageRequest.tags = tags;
    }

    try {
        await axiosInstance.post(`/api/v0/chat-anon`, messageRequest, {
            headers: {
                "Content-Type": "application/json",
            },
            timeout: 60000,
        });
    } catch (error) {
        throw error;
    }
}

export async function getChatConfig(): Promise<ChatConfig> {
    try {
        const response = await axiosInstance.get<ChatConfig>(`/api/v0/config`, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getModels(): Promise<LLMModel[]> {
    try {
        const response = await axiosInstance.get<LLMModel[]>(
            `/api/v0/llm/models`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchVectorCollections(): Promise<string[]> {
    try {
        const response = await axiosInstance.get<string[]>(
            `/api/v0/vector-dwh/collections`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function fetchLatestFromDB(source: string, limit: number): Promise<ChunkFields[]> {
    try {
        const response = await axiosInstance.get<ChunkFields[]>(
            `/api/v0/dwh/latest?source=${source}&limit=${limit}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getFlowSettings(category: string): Promise<FlowSettings> {
    try {
        const response = await axiosInstance.get<FlowSettings>(
            `/api/v0/llm/flows?category=${category}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function updateFlowSettingsByCategoryApi(
    category: string,
    flowSettings: FlowSettings
): Promise<void> {
    try {
        const response = await axiosInstance.post<void>(
            `/api/v0/llm/flows?category=${category}`,
            flowSettings,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function generateThumbnailRequest(
    query: string,
): Promise<ImageResult> {
    try {
        const payload = {query: query}
        const response = await axiosInstance.post<ImageResult>(
            `/api/v0/imagegen`,
            payload,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function sendInquiryRequest({
    customerId,
    userQuery,
    category,
    metadata,
    chatHistory,
    temperature,
    contextLimit,
    chunksSearchLimit,
    answerTokenLimit,
    modelName,
    minimumChunkDistance,
    olderThanTimestamp,
    newerThanTimestamp,
}: {
    customerId: string;
    userQuery: string;
    category: MessageCategory;
    metadata: MessageMetadata;
    chatHistory: ChatHistoryMessage[];
    temperature: number | null;
    contextLimit: number | null;
    chunksSearchLimit: number | null;
    answerTokenLimit: number | null;
    modelName: string | null;
    minimumChunkDistance: number | null;
    olderThanTimestamp: number | null;
    newerThanTimestamp: number | null;
}): Promise<void> {
    let messageRequest: InquiryRequest = {
        userQuery,
        category,
        metadata,
        chatHistory,
        temperature,
        contextLimit,
        chunksSearchLimit,
        answerTokenLimit,
        modelName,
        minimumChunkDistance,
        olderThanTimestamp,
        newerThanTimestamp,
    };

    try {
        await axiosInstance.post(
            `/api/v0/customers/${customerId}/chats`,
            messageRequest,
            {
                headers: {
                    "Content-Type": "application/json",
                },
                timeout: 60000,
            }
        );
    } catch (error) {
        throw error;
    }
}

export async function getAllCollections(): Promise<AllCollections> {
    try {
        const response = await axiosInstance.get<AllCollections>(
            `/api/v0/index`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function addToIndex(
    indexRequestBody: IndexAddRequestBody
): Promise<ResponseAddIndex> {
    try {
        const response = await axiosInstance.post(
            "/api/v0/index/add",
            indexRequestBody,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function editToIndex(
    editRequestBody: IndexUpdateRequestBody
): Promise<ResponseEditIndex> {
    try {
        const response = await axiosInstance.post(
            "api/v0/index/update",
            editRequestBody,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function deleteToIndex(
    indexRequestBody: IndexDeleteRequestBody
): Promise<any> {
    try {
        const response = await axiosInstance.post(
            "/api/v0/index/delete",
            indexRequestBody,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getActiveUsers(): Promise<ActiveUsersRequestBody> {
    try {
        const response = await axiosInstance.get<ActiveUsersRequestBody>(
            `/api/v0/websocket`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getChunk(
    collectionName: string,
    source?: string | null,
    chunkId?: string | null
): Promise<ResponseGetIndex> {
    let queryParams = new URLSearchParams();
    if (source) {
        queryParams.append("source", source);
    }

    if (chunkId) {
        queryParams.append("chunk_id", chunkId.toString());
    }

    try {
        const response = await axiosInstance.get<ResponseGetIndex>(
            `/api/v0/index/${collectionName}?${queryParams.toString()}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function sendVote(
    requestVote: VoteRequest,
    customerId: string
): Promise<any> {
    try {
        const response = await axiosInstance.post(
            `/api/v0/customers/${customerId}/votes`,
            requestVote
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function sendVoteAnon(requestVote: VoteRequest): Promise<any> {
    try {
        const response = await axiosInstance.post(
            "/api/v0/vote-anon",
            requestVote,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getAllVotes(): Promise<VoteBody[]> {
    try {
        const response = await axiosInstance.get<VoteBody[]>(`/api/v0/vote`, {
            headers: {
                "Content-Type": "application/json",
            },
        });

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function getAllVotesByStatus(status: string): Promise<VoteBody[]> {
    try {
        const response = await axiosInstance.get<VoteBody[]>(
            `/api/v0/vote?status=${status}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data;
    } catch (error) {
        throw error;
    }
}

export async function sendAccountApiKey(
    account_api_key: string,
    auth_token: string
): Promise<any> {
    try {
        const response = await axiosInstance.post(
            "/api/v0/account/api-key",
            { account_api_key },
            {
                headers: {
                    Authorization: `Bearer ${auth_token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response;
    } catch (error) {
        throw error;
    }
}

export async function getLatestVersion(): Promise<string> {
    try {
        const response: any = await axiosInstance.get(
            "/api/v0/ui/latest-version",
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data.version;
    } catch (error) {
        throw error;
    }
}

export async function getChatIdByMessageId(
    messageId: number,
    auth_token: string
): Promise<number> {
    try {
        const response: any = await axiosInstance.get(
            `/api/v0/messages/${messageId}/chat`,
            {
                headers: {
                    Authorization: `Bearer ${auth_token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data.chat_id;
    } catch (error) {
        throw error;
    }
}

export async function getMessageTypes(
    auth_token: string
): Promise<MessageTypeResponse> {
    try {
        const response: { data: MessageTypeResponse } = await axiosInstance.get(
            `/api/v0/messages/types`,
            {
                headers: {
                    Authorization: `Bearer ${auth_token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        return response.data;
    } catch (error) {
        throw error;
    }
}
