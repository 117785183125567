import React, { useState } from "react";
import {
    VoteDirection,
    VoteRequest,
} from "../../../common/home/requests/VoteRequest";
import { sendVote, sendVoteAnon } from "../../../common/home/api";
import { updateIsError } from "../../../redux/error";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { updateIsVote } from "../../../redux/utils";
import Popup from "./Popup";
import { updateMessageStatus } from "../../../redux/messages";

type Props = {
    handleVoteSubmit: () => void;
};

function VotePopup(props: Props) {
    const customerId = useAppSelector((state) => state.user.customerId);
    const selectedMessageCategory = useAppSelector(
        (state) => state.chats.selectedMessageCategory
    );
    const dispatch = useAppDispatch();
    const auth_token = useAppSelector((state) => state.user.access_token);
    const currentMessage = useAppSelector(
        (state) => state.messages.currentMessage
    );

    const [comment, setComment] = useState("");
    const [manualAnswer, setManualAnswer] = useState("");

    const close = () => dispatch(updateIsVote(false));

    const handleSubmit = async () => {
      try {          
          if (currentMessage) {
                let status = 2;
                if (currentMessage.status == 2) {
                    status = 1;
                }
                const vote: VoteRequest = {
                    direction: VoteDirection.DOWNVOTE,
                    category: selectedMessageCategory,
                    note: comment,
                    metadata: {
                        ticker: null,
                    },
                };
                let response = await sendVote(vote, customerId);

                if (response.status === 200) {
                    dispatch(
                        updateMessageStatus({
                            id: currentMessage.message_id,
                            status: 2,
                        })
                    );
                    close();
                }
                props.handleVoteSubmit();
            }
        } catch (error: any) {
            dispatch(
                updateIsError({
                    message: "Failed to send downvote feedback",
                    err: error.message,
                })
            );
        }
    };

    return (
        <Popup title="Provide additional feedback" onClose={close}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <textarea
                    className="w-full bg-altBackground-light dark:bg-altBackground-dark focus:outline-none text-text1-light dark:text-text1-dark placeholder-text2-light dark:placeholder-text2-dark"
                    style={{
                        borderRadius: 10,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 15,
                        paddingRight: 15,
                        width: 350,
                        resize: "none",
                        height: 200,
                    }}
                    placeholder="Describe the problem..."
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                />
                {/* <textarea
          className="w-full bg-altBackground-light dark:bg-altBackground-dark focus:outline-none text-text1-light dark:text-text1-dark placeholder-text2-light dark:placeholder-text2-dark"
          style={{
            borderRadius: 10,
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
            width: 350,
            marginTop: 10,
            resize: "none",
            height: 200
          }}
          placeholder="Manual answer..."
          value={manualAnswer}
          onChange={(event) => setManualAnswer(event.target.value)}
        /> */}
                <button
                    type="button"
                    className="bg-brand text-white"
                    style={{
                        borderRadius: 10,
                        marginTop: 20,
                        paddingTop: 15,
                        paddingBottom: 15,
                        fontSize: 19,
                        fontWeight: 500,
                        lineHeight: "30px",
                    }}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </button>
            </div>
        </Popup>
    );
}

export default VotePopup;
