import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  updateAdminModeEnabled,
  updateIsChangePasswordOpen,
  updateIsPreferences
} from "../../../redux/utils";
import Popup from "./Popup";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import useDarkMode from "../../../hooks/Theme";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { logOut } from "../../../utils";

function PreferencesPopup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isPreferencesOpen = useAppSelector(
    (state) => state.utils.isPreferences
  );
  const adminModeEnabled = useAppSelector(
    (state) => state.utils.adminModeEnabled
  );
  const isAdminUser = useAppSelector((state) => state.user.isAdmin);
  const authToken = useAppSelector((state) => state.user.access_token);

  const [darkTheme, setDarkTheme] = useDarkMode();

  const options = [
    {
      value: "Dark",
      label: "Dark",
      className: "text-text1-light dark:text-text1-dark"
    },
    {
      value: "Light",
      label: "Light",
      className: "text-text1-light dark:text-text1-dark"
    }
  ];
  const defaultOption = darkTheme ? "Dark" : "Light";

  return isPreferencesOpen ? (
    <Popup
      title="Options"
      onClose={() => {
        dispatch(updateIsPreferences(false));
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: 390
        }}
      >
        <div
          className="border-stroke-light dark:border-stroke-dark"
          style={{
            display: "flex",
            flexDirection: "row",
            borderBottomWidth: authToken ? 1 : 0,
            justifyContent: "space-between",
            paddingBottom: authToken ? 15 : 0
          }}
        >
          <span
            style={{
              fontSize: 17,
              lineHeight: "30px",
              fontWeight: 300
            }}
          >
            Appearance
          </span>
          <Dropdown
            controlClassName="bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
            placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
            menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
            arrowClassName="flex flex-row"
            arrowClosed={
              <div style={{ width: 32, height: 32 }}>
                <img src="/dropdown-arrow.svg" className="dark:hidden" />
                <img
                  src="/dropdown-arrow-dark.svg"
                  className="hidden dark:flex"
                />
              </div>
            }
            arrowOpen={
              <div
                style={{ width: 32, height: 32 }}
                className="transform rotate-180"
              >
                <img src="/dropdown-arrow.svg" className="dark:hidden" />
                <img
                  src="/dropdown-arrow-dark.svg"
                  className="hidden dark:flex"
                />
              </div>
            }
            options={options}
            onChange={(selectedOption) => {
              setDarkTheme(selectedOption.value === "Dark");
            }}
            value={defaultOption}
            placeholder="Select an option"
          />
        </div>
        {isAdminUser && authToken ? (
          <div
            className="border-stroke-light dark:border-stroke-dark"
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottomWidth: 1,
              justifyContent: "space-between",
              paddingTop: 20,
              paddingBottom: 15
            }}
          >
            <span
              style={{
                fontSize: 17,
                lineHeight: "30px",
                fontWeight: 300
              }}
            >
              Admin mode
            </span>
            <Toggle
              className="admin-toggle"
              defaultChecked={adminModeEnabled}
              style={{
                backgroundColor: "grey"
              }}
              icons={false}
              onChange={() => {
                dispatch(updateAdminModeEnabled(!adminModeEnabled));
              }}
            />
          </div>
        ) : null}
        {authToken ? (
          <button
            className="transition duration-300 ease-in-out bg-altBackground-light dark:bg-altBackground-dark"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 20,
              display: "flex",
              flex: 1,
              borderRadius: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={() => {
              dispatch(updateIsChangePasswordOpen(true));
            }}
          >
            <span className="text-text1-light dark:text-text1-dark">
              Change password
            </span>
          </button>
        ) : null}
        {authToken ? (
          <button
            className="transition duration-300 ease-in-out bg-altBackground-light dark:bg-altBackground-dark"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 20,
              display: "flex",
              flex: 1,
              borderRadius: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
            onClick={logOut}
          >
            <span className="text-text1-light dark:text-text1-dark">
              Log out
            </span>
          </button>
        ) : null}
      </div>
    </Popup>
  ) : null;
}

export default PreferencesPopup;
