import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
    updateCurrentChatId,
    updateSelectedMessageCategory,
    updateSelectedTicker,
} from "../../redux/chats";
import { clearMessages } from "../../redux/messages";
import { useNavigate } from "react-router-dom";
import { isMobile } from "../../utils";
import { BsPlus } from "react-icons/bs";
import ProfileItem from "./widgets/ProfileItem";
import OpenAccountItem from "./widgets/OpenAccountItem";
import { updateIsNewChatAnonPopupOpen } from "../../redux/utils";
import Message from "./widgets/Message";
import { MessageCategory } from "../../common/home/models/MessageCategory";
import Dropdown from "react-dropdown";
import FlowSettingsPopup from "./widgets/FlowSettingsPopup";
import DatabasePopup from "./widgets/DatabasePopup";
import ApiPopup from "./widgets/ApiPopup";
import PromptsPopup from "./widgets/PromptsPopup";

function Bar(props: {
    toggleMenu: () => void;
    openChat: (chatId: number) => void;
}) {
    const dispatch = useAppDispatch();
    const allChats = useAppSelector((state) => state.chats.chats);
    const chatConfig = useAppSelector((state) => state.chats.chatConfig);
    const selectedMessageCategory = useAppSelector(
        (state) => state.chats.selectedMessageCategory
    );
    const selectedTicker = useAppSelector(
        (state) => state.chats.selectedTicker
    );
    const allAnonChats = useAppSelector((state) => state.chats.anonChats);
    const currentChatId = useAppSelector((state) => state.chats.currentChatId);
    const authToken = useAppSelector((state) => state.user.access_token);
    const messages = useAppSelector((state) => state.messages.messages);
    const isBotThinking: boolean = Boolean(
        messages[messages.length - 1]?.isBot &&
            messages[messages.length - 1]?.message === ""
    );

    const chatsToUse = authToken ? allChats : allAnonChats;
    const tickers: string[] = ["None", ...(chatConfig?.tickers || [])];
    const tickerOptions = tickers.map((ticker) => {
        return {
            value: ticker,
            label: ticker,
            className: "text-text1-light dark:text-text1-dark",
        };
    });
    const defaultTicker = "None";
    const [flowSettingsPopup, setFlowSettingsPopup] = useState<boolean>(false);
    const [databasePopup, setDatabasePopup] = useState<boolean>(false);
    const [apiPopup, setApiPopup] = useState<boolean>(false);
    const [promptsPopup, setPromptsPopup] = useState<boolean>(false);

    return (
        <>
            {flowSettingsPopup && (
                <FlowSettingsPopup setPromptsPopup={setFlowSettingsPopup} />
            )}
            {databasePopup && (
                <DatabasePopup onClose={() => setDatabasePopup(false)} />
            )}
            {apiPopup && <ApiPopup onClose={() => setApiPopup(false)} />}
            {apiPopup && <ApiPopup onClose={() => setApiPopup(false)} />}
            {promptsPopup && (
                <PromptsPopup onClose={() => setPromptsPopup(false)} />
            )}
            {isMobile ? (
                <div
                    style={{
                        position: "fixed",
                        bottom: 0,
                        top: 0,
                        left: 0,
                        right: 0,
                        backgroundColor: "rgba(0,0,0,0.3)",
                        zIndex: 9,
                    }}
                    onClick={props.toggleMenu}
                />
            ) : null}
            <div
                className="transition duration-300 ease-in-out flex flex-col bg-mainBackground-light dark:bg-mainBackground-dark z-10 "
                style={{
                    width: isMobile ? undefined : 300,
                    minWidth: isMobile ? undefined : 300,
                    maxWidth: isMobile ? "85%" : undefined,
                    borderRadius: 10,
                    borderTopLeftRadius: isMobile ? 0 : 10,
                    borderBottomLeftRadius: isMobile ? 0 : 10,
                    margin: isMobile ? 0 : 10,
                    top: isMobile ? 0 : 60,
                    bottom: 0,
                    position: isMobile ? "fixed" : undefined,
                }}
            >
                <div className="flex justify-center items-center">
                    {isMobile ? (
                        <div className="flex flex-1">
                            <div
                                onClick={props.toggleMenu}
                                className="flex transform w-15 h-15 rotate-45 dark:rotate-45 text-text2-light dark:text-text2-dark transition duration-300 ease-in-out hover:text-text2-dark dark:hover:text-text1-dark cursor-pointer"
                                style={{
                                    marginLeft: 3,
                                    marginTop: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <BsPlus size="55" />
                            </div>
                        </div>
                    ) : null}
                    {!isMobile ? (
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <img
                                src="/212-logo.png"
                                className="dark:hidden"
                                width={158}
                                height={20}
                                style={{
                                    paddingTop: 40,
                                    userSelect: "none",
                                }}
                            />
                            <img
                                src="/212-logo-dark.png"
                                className="hidden dark:flex"
                                width={158}
                                height={20}
                                style={{
                                    paddingTop: 40,
                                    userSelect: "none",
                                }}
                            />
                            <div
                                style={{
                                    display: "flex",
                                    flex: 1,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginTop: 15,
                                    marginBottom: 30,
                                }}
                            >
                                <span
                                    style={{
                                        fontSize: 22,
                                        lineHeight: "35px",
                                        fontWeight: 500,
                                    }}
                                    className="text-brand"
                                >
                                    AI chat
                                </span>
                            </div>
                        </div>
                    ) : null}
                </div>
                {authToken ? (
                    <div className="flex w-full">
                        <button
                            type="button"
                            className="w-full mb-5 py-2 text-text1-light dark:text-text1-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-200 ease-in-out"
                            style={{
                                height: 50,
                                fontSize: 15,
                                fontWeight: 500,
                                borderWidth: 1,
                                borderRadius: 10,
                                marginLeft: 20,
                                marginRight: 20,
                                lineHeight: "30px",
                            }}
                            onClick={() => {
                                if (!authToken && allAnonChats.length) {
                                    dispatch(
                                        updateIsNewChatAnonPopupOpen(true)
                                    );
                                }
                                const lastIndex: number = messages.length - 1;
                                if (messages[lastIndex]?.message === "") {
                                    return;
                                }

                                dispatch(updateCurrentChatId(-1));
                                dispatch(clearMessages());

                                if (isMobile) {
                                    props.toggleMenu();
                                }
                            }}
                        >
                            New chat
                        </button>
                    </div>
                ) : (
                    <div
                        className="ticker-container"
                        style={{
                            margin: "0 auto",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "80%",
                            gap: 3,
                            // overflow: "scroll"
                        }}
                    >
                        {/* <p>Ticker</p>
                        <Dropdown
                            // baseClassName="w-100"
                            controlClassName="w-100 bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
                            placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
                            menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
                            arrowClassName="flex flex-row"
                            arrowClosed={
                                <div style={{ width: 32, height: 32 }}>
                                    <img
                                        src="/dropdown-arrow.svg"
                                        className="dark:hidden"
                                    />
                                    <img
                                        src="/dropdown-arrow-dark.svg"
                                        className="hidden dark:flex"
                                    />
                                </div>
                            }
                            arrowOpen={
                                <div
                                    style={{ width: 32, height: 32 }}
                                    className="transform rotate-180"
                                >
                                    <img
                                        src="/dropdown-arrow.svg"
                                        className="dark:hidden"
                                    />
                                    <img
                                        src="/dropdown-arrow-dark.svg"
                                        className="hidden dark:flex"
                                    />
                                </div>
                            }
                            options={tickerOptions}
                            onChange={(selectedOption) => {
                                dispatch(
                                    updateSelectedTicker(selectedOption.value)
                                );
                            }}
                            value={defaultTicker}
                            placeholder="Select an option"
                        /> */}
                        <div className="mt-10 mb-2">Category</div>
                        {chatConfig?.categories?.map((category: string) => {
                            return (
                                <button
                                    key={category}
                                    type="button"
                                    className={`w-full mb-5 py-2 text-text1-light dark:text-text1-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-10 ease-in-out ${
                                        selectedMessageCategory === category
                                            ? "bg-stroke-light dark:bg-stroke-dark"
                                            : ""
                                    }`}
                                    style={{
                                        height: 50,
                                        fontSize: 15,
                                        fontWeight: 500,
                                        borderWidth: 1,
                                        borderRadius: 10,
                                        marginLeft: 20,
                                        marginRight: 20,
                                        lineHeight: "30px",
                                    }}
                                    onClick={() => {
                                        dispatch(
                                            updateSelectedMessageCategory(
                                                MessageCategory[
                                                    category as keyof typeof MessageCategory
                                                ]
                                            )
                                        );
                                        console.log(selectedMessageCategory);
                                    }}
                                >
                                    {category}
                                </button>
                            );
                        })}
                        <div
                            style={{
                                borderWidth: 1,
                                width: "100%",
                                color: "black",
                                padding: "0 0",
                            }}
                        ></div>
                        <div
                            className="special-button-container"
                            style={{
                                margin: "0 auto",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "100%",
                                gap: 3,
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                        >
                            <button
                                type="button"
                                className={`mt-5 w-full mb-5 py-2 bg-strongStroke2-light dark:bg-strongStroke2-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-10 ease-in-out`}
                                style={{
                                    height: 50,
                                    fontSize: 15,
                                    fontWeight: 500,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    lineHeight: "30px",
                                }}
                                onClick={() => {
                                    dispatch(clearMessages());
                                }}
                            >
                                Clear Chat
                            </button>
                            <button
                                type="button"
                                className={`w-full mb-5 py-2 bg-strongStroke2-light dark:bg-strongStroke2-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-10 ease-in-out`}
                                style={{
                                    height: 50,
                                    fontSize: 15,
                                    fontWeight: 500,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    lineHeight: "30px",
                                }}
                                onClick={() => {
                                    setFlowSettingsPopup(true);
                                }}
                            >
                                Settings
                            </button>
                            <button
                                type="button"
                                className={`w-full mb-5 py-2 bg-strongStroke2-light dark:bg-strongStroke2-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-10 ease-in-out`}
                                style={{
                                    height: 50,
                                    fontSize: 15,
                                    fontWeight: 500,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    lineHeight: "30px",
                                }}
                                onClick={() => {
                                    setDatabasePopup(true);
                                }}
                            >
                                Database
                            </button>
                            <button
                                type="button"
                                className={`w-full mb-5 py-2 bg-strongStroke2-light dark:bg-strongStroke2-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-10 ease-in-out`}
                                style={{
                                    height: 50,
                                    fontSize: 15,
                                    fontWeight: 500,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    lineHeight: "30px",
                                }}
                                onClick={() => {
                                    setApiPopup(true);
                                }}
                            >
                                Public API
                            </button>
                            <button
                                type="button"
                                className={`w-full mb-5 py-2 bg-strongStroke2-light dark:bg-strongStroke2-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-10 ease-in-out`}
                                style={{
                                    height: 50,
                                    fontSize: 15,
                                    fontWeight: 500,
                                    borderWidth: 1,
                                    borderRadius: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    lineHeight: "30px",
                                }}
                                onClick={() => {
                                    setPromptsPopup(true);
                                }}
                            >
                                Prompts
                            </button>
                        </div>
                    </div>
                )}

                {authToken && Array.isArray(chatsToUse) && chatsToUse.length ? (
                    <div
                        style={{
                            marginTop: 15,
                        }}
                    >
                        <span
                            className="text-text2-light dark:text-text2-dark"
                            style={{
                                fontSize: 12,
                                fontWeight: 500,
                                lineHeight: "20px",
                                marginLeft: 20,
                            }}
                        >
                            CHAT SESSIONS
                        </span>
                    </div>
                ) : null}
                <div
                    // className="flex flex-1 overflow-hidden"
                    style={{ position: "relative" }}
                >
                    <div className=" flex-1 justify-center items-center">
                        <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                            {authToken &&
                                Array.isArray(chatsToUse) &&
                                chatsToUse
                                    .slice()
                                    .reverse()
                                    .map((chat) => {
                                        const isSelected =
                                            currentChatId === chat.id;

                                        return (
                                            <button
                                                key={chat.id}
                                                type="button"
                                                className="transition duration-300 ease-in-out text-text1-light dark:text-text1-dark w-full"
                                                style={{
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    paddingTop: 12.5,
                                                    paddingBottom: 12.5,
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    fontWeight: 300,
                                                    fontSize: 15,
                                                    lineHeight: "25px",
                                                    textAlign: "left",
                                                    backgroundColor: isSelected
                                                        ? "rgba(0, 167, 225, 0.05)"
                                                        : undefined,
                                                }}
                                                onClick={() => {
                                                    if (
                                                        !authToken ||
                                                        isBotThinking ||
                                                        currentChatId ===
                                                            chat.id
                                                    ) {
                                                        return;
                                                    }

                                                    props.openChat(chat.id);
                                                }}
                                            >
                                                {chat.chat_name}
                                            </button>
                                        );
                                    })}
                        </div>
                    </div>
                </div>
                {authToken ? (
                    <div
                        className="border-stroke-light dark:border-stroke-dark"
                        style={{
                            borderTopWidth: 1,
                            marginLeft: 20,
                            marginRight: 20,
                        }}
                    />
                ) : null}
                {authToken ? <ProfileItem /> : <OpenAccountItem />}
            </div>
        </>
    );
}

export default Bar;
