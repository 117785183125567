import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import SettingsButton from "../../widgets/SettingsButton";
import { isMobile } from "../../utils";
import VoteMenuButton from "../../widgets/VoteMenuButton";
import { useAppSelector } from "../../hooks/hooks";
import { sendAccountApiKey } from "../../common/home/api";

interface Props {
  onClickBurger: any;
}

const TopNavigationBar: React.FC<Props> = ({ onClickBurger }) => {
  return (
    <div
      className={`flex items-center ${
        isMobile ? "justify-start" : "justify-end"
      } transition duration-300 ease-in-out bg-mainBackground-light dark:bg-mainBackground-dark h-16`}
    >
      {isMobile && (
        <div className="flex items-center">
          <button
            onClick={onClickBurger}
            className="text-3xl text-text1-light dark:text-text1-dark focus:outline-none"
          >
            <GiHamburgerMenu
              size="28"
              className="text-text2-light dark:text-text2-dark mr-3 ml-4 transition duration-300 ease-in-out hover:text-text2-dark dark:hover:text-text1-dark cursor-pointer"
            />
          </button>
        </div>
      )}
      {!isMobile ? (
        <>
          <VoteMenuButton />
          <SettingsButton />
        </>
      ) : (
        <div
          className="mobile-logo-wrapper"
          style={{
            display: "flex",
            flexDirection: "row",
            paddingTop: 40,
            paddingBottom: 40,
            marginLeft: 20,
            alignItems: "center"
          }}
        >
          <img
            src="/212-logo.png"
            className="dark:hidden"
            width={158}
            height={20}
            style={{ marginRight: 15, userSelect: "none", height: "unset" }}
          />
          <img
            src="/212-logo-dark.png"
            className="hidden dark:flex"
            width={158}
            height={20}
            style={{ marginRight: 15, userSelect: "none" }}
          />
          {window.innerWidth > 300 ? (
            <span
              style={{
                fontSize: 27,
                lineHeight: "35px",
                fontWeight: 500
              }}
              className="text-brand"
            >
              AI chat
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default TopNavigationBar;
