import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { updateIsNewVersionAlertOpen } from "../../../redux/utils";
import "react-dropdown/style.css";
import Alert from "./Alert";

function NewVersionAlert() {
  const dispatch = useAppDispatch();

  const isNewVersionAlertOpen = useAppSelector(
    (state) => state.utils.isNewVersionAlertOpen
  );

  return isNewVersionAlertOpen ? (
    <Alert
      title="Update available"
      onClose={() => {
        dispatch(updateIsNewVersionAlertOpen(false));
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: 390
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: 15
          }}
        >
          <span
            style={{
              fontSize: 15,
              lineHeight: "30px",
              fontWeight: 300
            }}
          >
            Updating will refresh the current page.
          </span>
          <span
            style={{
              fontSize: 15,
              lineHeight: "30px",
              fontWeight: 300
            }}
          ></span>
        </div>
        <button
          className="transition duration-300 ease-in-out bg-altBackground-light dark:bg-altBackground-dark"
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
            display: "flex",
            flex: 1,
            borderRadius: 10,
            alignItems: "center",
            justifyContent: "center"
          }}
          onClick={() => {
            dispatch(updateIsNewVersionAlertOpen(false));
            window.location.reload();
          }}
        >
          <span className="text-text1-light dark:text-text1-dark">
            Update now
          </span>
        </button>
      </div>
    </Alert>
  ) : null;
}

export default NewVersionAlert;
