import React, { useCallback, useState } from "react";

export function CheckBox(props: {
  onChange?: (isChecked: boolean) => void;
  defaultChecked?: boolean;
  isSelected?: boolean;
  label?: string;
}) {
  const [isChecked, setIsChecked] = useState(props.defaultChecked || false);

  const onClick = useCallback(() => {
    setIsChecked(!isChecked);
    props.onChange?.(!isChecked);
  }, [props.onChange, isChecked]);

  return (
      <div style={{display: "flex", gap: "5px"}}>
          <div
              className="border-strongStroke-light dark:border-strongStroke-dark"
              style={{
                  width: 22,
                  height: 22,
                  display: "flex",
                  borderRadius: 5,
                  borderWidth: 1,
                  alignItems: "center",
                  justifyContent: "center",
                  userSelect: "none",
              }}
              onClick={props.isSelected !== undefined ? undefined : onClick}
          >
              {isChecked || props.isSelected ? (
                  <img src="/check.svg" width={16} height={16} />
              ) : null}
          </div>
          {props.label}
      </div>
  );
}
