import { MessageCategory } from "../models/MessageCategory";
import { MessageMetadata } from "../models/MessageMetadata";

export type VoteRequest = {
    direction: VoteDirection;
    note?: string;
    category: MessageCategory;
    metadata: MessageMetadata
}

export enum VoteDirection {
    UPVOTE,
    DOWNVOTE
}