import React, { useCallback, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./views/home/Home";
import Vote from "./views/vote/Vote";
import useDarkMode from "./hooks/Theme";
import { useAppSelector } from "./hooks/hooks";
import {
    connect as connectWebSocket,
    disconnect as disconnectWebSocket,
} from "./hooks/WebSocket";
import { validateSession } from "./common/auth/api";
import { useDispatch } from "react-redux";
import { resetStore } from "./redux/resetStore";
import { currentLimitMessagesMS, updateApiKey, updateGraphPopup } from "./redux/utils";
import { v4 as uuidv4 } from "uuid";
import { logOut } from "./utils";
import {
    setChatConfig,
    setVectorCollections,
    updateFlowSettingsByCategory,
    updateModels,
    updateNewerThanFiltration,
    updateOlderThanFiltration,
} from "./redux/chats";
import {
    fetchApiKey,
    fetchVectorCollections,
    getChatConfig,
    getFlowSettings,
    getModels,
} from "./common/home/api";
import { MessageCategory } from "./common/home/models/MessageCategory";
import { LLMModel } from "./common/home/models/LLMModel";

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const cookie = document.cookie;

    if (!cookie || !cookie.includes("inf")) {
        document.cookie = `inf=${uuidv4()};path=/;max-age=${
            1000 * 60 * 60 * 24 * 365
        };`; // 1 year
    }

    // This is here because it attaches the dark mode class to the body when called
    useDarkMode();
    const auth_token = useAppSelector((state) => state.user.access_token);
    const isSocketConnected = useAppSelector(
        (state) => state.utils.isSocketConnected
    );
    const isSocketConnecting = useAppSelector(
        (state) => state.utils.isSocketConnecting
    );
    const selectedMessageCategory = useAppSelector(
        (state) => state.chats.selectedMessageCategory
    );
    const messages = useAppSelector((state) => state.messages.messages);

    const checkAndHandleSessionValidity =
        useCallback(async (): Promise<void> => {
            // let hasValidSession;
            // if (!auth_token) {
            //   hasValidSession = false;
            // } else {
            //   hasValidSession = await validateSession(auth_token);
            // }
            // if (!hasValidSession) {
            //   logOut();
            // }
            // return hasValidSession;
        }, [auth_token]);

    // Reconnect WS on anon -> logged in and logged in -> anon
    useEffect(() => {
        if (isSocketConnected) {
            disconnectWebSocket();
        }

        connectWebSocket(() => {});
    }, [auth_token]);

    // Check session validity on app load
    useEffect(() => {
        checkAndHandleSessionValidity();
    }, []);

    useEffect(() => {
        fetchVectorCollections().then((res) => {
            dispatch(setVectorCollections(res));
        });
    }, []);

    useEffect(() => {
        fetchApiKey().then((res) => {
            dispatch(updateApiKey(res.apiKey));
        });
    }, []);

    //Links onClick handler
    useEffect(() => {
        const elements = document.querySelectorAll(".generated-link");

        elements.forEach((element) => {
            element.addEventListener("click", (e) => {
                e.preventDefault();
                dispatch(updateGraphPopup({open: true, selectedUrl: element.getAttribute("href")}));
            });
        });

        // Cleanup function to remove event listener when component unmounts
        return () => {
            elements.forEach((element) => {
                element.removeEventListener("click", () => {
                    console.log("You clicked the element!");
                });
            });
        };
    }, [messages]);

    //load chat config
    useEffect(() => {
        getChatConfig().then((config) => {
            dispatch(setChatConfig(config));
            config.categories.forEach((category) => {
                getFlowSettings(category).then((res) => {
                    dispatch(
                        updateFlowSettingsByCategory({
                            category:
                                MessageCategory[
                                    category as keyof typeof MessageCategory
                                ],
                            settings: res,
                        })
                    );
                });
            });
        });
    }, []);

    useEffect(() => {
        getFlowSettings(selectedMessageCategory).then((res) => {
            dispatch(updateNewerThanFiltration(res.newerThanTimestamp != null));
            dispatch(updateOlderThanFiltration(res.olderThanTimestamp != null));
        });
    }, [selectedMessageCategory]);

    useEffect(() => {
        getModels().then((res: LLMModel[]) => {
            dispatch(updateModels(res));
            console.log(res);
        });
    }, []);

    const onFocus = useCallback(async () => {
        if (!isSocketConnected && !isSocketConnecting) {
            connectWebSocket(() => {});
        }
    }, [isSocketConnected, isSocketConnecting]);

    useEffect(() => {
        window.addEventListener("focus", onFocus);

        return () => window.removeEventListener("focus", onFocus);
    }, [onFocus]);
    const isProd = window.location.hostname === "chat.trading212.com";

    return (
        <div style={{ height: "100%" }}>
            <Routes>
                <Route path={isProd ? "/hal9000" : "/"} element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/chat/:chatId" element={<Home />} />
                <Route path="/vote" element={<Vote />} />
            </Routes>
        </div>
    );
}

export default App;
