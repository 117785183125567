import React, { useState } from "react";
import Popup from "./Popup";
import Dropdown from "react-dropdown";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import CloseButton from "../../../widgets/CloseButton";
import { Chunk, ChunkFields } from "../../../common/home/models/MessageBody";
import { fetchLatestFromDB } from "../../../common/home/api";
import { updateIsDatabaseChunks, updateIsDetails } from "../../../redux/utils";

type Props = {
    onClose: () => void;
};

function DatabasePopup(props: Props) {
    const dispatch = useAppDispatch();
    const vectorCollections = useAppSelector(
        (state) => state.chats.vectorCollections
    );
    const [selectedCollection, setSelectedCollection] = useState<string>("");
    const [limit, setLimit] = useState<number>(0);
    const [chunks, setChunks] = useState<ChunkFields[]>([]);
    // const [chunkDetailsPopup, setChunkDetailsPopup] = useState<boolean>(false);
    const chunkDetailsPopup = useAppSelector(
        (state) => state.utils.isDatabaseChunks
    );

    const handleSubmit = () => {
        dispatch(updateIsDatabaseChunks(true));
        fetchLatestFromDB(selectedCollection, limit).then((res) => {
            setChunks(res);
        });
    };

    const ChunkDetailsPopup = () => {
        const viewDetails =
            "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col h-1/2 w-1/2 bg-altBackground-light dark:bg-altBackground-dark rounded-lg z-20";
        return (
            <div>
                {chunkDetailsPopup && (
                    <div className={viewDetails}>
                        {/* <Popup
                            onClose={() =>
                                dispatch(updateIsDatabaseChunks(false))
                            }
                        > */}
                        <div className="relative flex items-center justify-center h-full">
                            <div className="absolute top-10 right-0 left-0 bottom-0 overflow-y-scroll p-2">
                                <div className="flex flex-col break-words m-3 p-1 rounded-md leading-6 whitespace-pre-wrap text-sm text-text1-light dark:text-text1-dark">
                                    {chunks.map((chunk, index) => (
                                        <div key={index} className="mb-4">
                                            <h2 className="text-xl font-semibold mb-2">
                                                ID: {chunk.id}, SOURCE=
                                                {chunk.source}
                                            </h2>
                                            <h2 className="text-xl font-semibold mb-2">
                                                Timestamp:{" "}
                                                {new Date(
                                                    chunk.timestamp
                                                ).toLocaleString()}
                                            </h2>
                                            <p className="text-sm">
                                                {chunk.content}
                                            </p>
                                        </div>
                                    ))}
                                    {chunks.length === 0 && (<div>No results!</div>)}
                                </div>
                            </div>
                        </div>
                        {/* </Popup> */}
                        <CloseButton />
                    </div>
                )}
            </div>
        );
    };

    return (
        <Popup onClose={props.onClose} width={400}>
            <ChunkDetailsPopup></ChunkDetailsPopup>
            <div
                className="popup-content"
                style={{ display: "flex", flexDirection: "column", width: 400, padding: 10 }}
            >
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 20,
                            lineHeight: "30px",
                            fontWeight: 300,
                            display: "flex",
                            gap: "5px",
                        }}
                    >
                        Source
                    </span>
                    <Dropdown
                        controlClassName="bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
                        placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
                        menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
                        arrowClassName="flex flex-row"
                        arrowClosed={
                            <>
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                    alt=""
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                    alt=""
                                />
                            </>
                        }
                        arrowOpen={
                            <div className="transform rotate-180">
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                    alt=""
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                    alt=""
                                />
                            </div>
                        }
                        options={vectorCollections}
                        onChange={(selectedOption) => {
                            setSelectedCollection(selectedOption.value);
                        }}
                        value={selectedCollection}
                        placeholder="Select an option"
                    />
                </div>
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 20,
                            lineHeight: "30px",
                            fontWeight: 300,
                            display: "flex",
                            gap: "5px",
                        }}
                    >
                        Limit
                    </span>
                    <input
                        onChange={(event) =>
                            setLimit(Number(event?.target.value) || 0)
                        }
                        value={limit}
                        type="text"
                        className="text-text1-light dark:text-text1-dark bg-altBackground-light dark:bg-altBackground-dark transition duration-300 ease-in-out hover:opacity-70 border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark focus:outline-none placeholder-text2-light dark:placeholder-text2-dark"
                        placeholder="Limit"
                        style={{
                            width: "50%",
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderWidth: 1,
                            borderRadius: 10,
                            fontSize: 15,
                            fontWeight: 300,
                            lineHeight: "20px",
                        }}
                    />
                </div>
                <button
                    type="button"
                    className="bg-brand text-white"
                    style={{
                        borderRadius: 10,
                        marginTop: 20,
                        paddingTop: 15,
                        paddingBottom: 15,
                        fontSize: 19,
                        fontWeight: 500,
                        lineHeight: "30px",
                    }}
                    onClick={() => handleSubmit()}
                >
                    Search
                </button>
            </div>
        </Popup>
    );
}

export default DatabasePopup;
