import React, { useEffect, useState } from "react";
import Popup from "./Popup";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { updateApiKey } from "../../../redux/utils";
import { updateApiKeyRequest } from "../../../common/home/api";
import { updateIsSuccess } from "../../../redux/error";

type Props = {
    onClose: () => void;
};

function ApiPopup(props: Props) {
    const dispatch = useAppDispatch();
    const apiKey = useAppSelector((state) => state.utils.apiKey);
    const [localApiKey, setLocalApiKey] = useState<string>(apiKey || "");

    const handleSubmit = () => {
        dispatch(updateApiKey(localApiKey))
        updateApiKeyRequest(localApiKey);
        dispatch(updateIsSuccess({ message: "API Key updated" }));
        props.onClose();
    }

    return (
        <Popup onClose={props.onClose} width={500}>
            <div
                className="popup-content"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 500,
                    padding: 30,
                }}
            >
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                        alignItems: "center",
                    }}
                >
                    <span
                        style={{
                            fontSize: 20,
                            lineHeight: "30px",
                            fontWeight: 300,
                            display: "flex",
                            gap: "5px",
                        }}
                    >
                        API KEY
                    </span>
                    <input
                        onChange={(event) =>
                            setLocalApiKey(event.target.value)
                        }
                        value={localApiKey}
                        type="text"
                        className="text-text1-light dark:text-text1-dark bg-altBackground-light dark:bg-altBackground-dark transition duration-300 ease-in-out hover:opacity-70 border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark focus:outline-none placeholder-text2-light dark:placeholder-text2-dark"
                        placeholder="api-key"
                        style={{
                            width: "70%",
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderWidth: 1,
                            borderRadius: 10,
                            fontSize: 15,
                            fontWeight: 300,
                            lineHeight: "20px",
                        }}
                    />
                </div>
                <button
                    type="button"
                    className="bg-brand text-white"
                    style={{
                        borderRadius: 10,
                        marginTop: 20,
                        paddingTop: 15,
                        paddingBottom: 15,
                        fontSize: 19,
                        fontWeight: 500,
                        lineHeight: "30px",
                    }}
                    onClick={() => handleSubmit()}
                >
                    Update
                </button>
            </div>
        </Popup>
    );
}

export default ApiPopup;
