import { TextareaHTMLAttributes, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import Popup from "./Popup";
import Dropdown from "react-dropdown";
import store from "../../../redux/store";
import {
    getFlowSettings,
    resetUserPrompt,
    updateFlowSettingsByCategoryApi,
    updatePrompt,
} from "../../../common/home/api";
import { Prompt } from "../../../common/home/models/Prompt";
import { updateIsError, updateIsSuccess } from "../../../redux/error";
import { LLMSettingsField } from "../../../widgets/LLMSettingsField";
import { MessageCategory } from "../../../common/home/models/MessageCategory";
import {
    CategoryFlowSettings,
    FlowSettings,
} from "../../../common/home/models/FlowSettings";
import {
    updateFlowSettingsByCategory,
    updateNewerThanFiltration,
    updateOlderThanFiltration,
} from "../../../redux/chats";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { Value } from "react-datetime-picker/dist/cjs/shared/types";
import { CheckBox } from "../../../widgets/CheckBox";
import { time } from "console";
import { SortBy, SortOrder } from "../../../common/home/models/Sorting";
import Button from "../../auth/widgets/Button";
import { NewsVectorCollections } from "../../../common/home/models/NewsVectorCollections";

type Props = {
    setPromptsPopup: React.Dispatch<React.SetStateAction<boolean>>;
};

function FlowSettingsPopup(props: Props) {
    const dispatch = useAppDispatch();

    const olderThanFiltration = useAppSelector(
        (state) => state.chats.olderThanFiltration
    );
    const newerThanFiltration = useAppSelector(
        (state) => state.chats.newerThanFiltration
    );

    const selectedCategory = useAppSelector(
        (state) => state.chats.selectedMessageCategory
    );
    const [
        selectedInternalMessageCategory,
        setSelectedInternalMessageCategory,
    ] = useState<MessageCategory>(selectedCategory);

    const flowSettingsMap: CategoryFlowSettings[] = useAppSelector(
        (state) => state.chats.categoryFlowSettings
    );
    const [internalFlowSettings, setInternalFlowSettings] = useState<
        FlowSettings | undefined
    >(undefined);
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const authToken = store.getState().user.access_token;
    const chatConfig = useAppSelector((state) => state.chats.chatConfig);
    const models = useAppSelector((state) => state.chats.models);

    useEffect(() => {
        const flowSettings: CategoryFlowSettings = flowSettingsMap.filter(
            (flowSetting) =>
                flowSetting.category === selectedInternalMessageCategory
        )[0];
        setInternalFlowSettings(flowSettings?.settings || undefined);
        console.log(internalFlowSettings)
    }, [selectedInternalMessageCategory]);

    const handleSubmit = () => {
        // if (selectedPrompt == undefined) {
        //     console.log("Can't update not selected prompt");
        //     return;
        // }

        const selectedModel = models.filter(
            (model) => model.modelName === internalFlowSettings?.modelName
        )[0];
        const answerTokenLimit =
            Number(internalFlowSettings?.answerTokenLimit) || 0;
        const contextLimit = Number(internalFlowSettings?.contextLimit || 0);
        console.log(
            answerTokenLimit,
            contextLimit,
            selectedModel.maxContextLimit
        );
        if (answerTokenLimit + contextLimit > selectedModel.maxContextLimit) {
            dispatch(
                updateIsError({
                    message: "You are over the max model context limit!",
                })
            );
            return;
        }

        // const newPrompt: Prompt = {
        //     prompt_name: selectedPrompt?.prompt_name,
        //     prompt_value: textareaRef.current?.value || null,
        // };

        // updatePrompt(newPrompt)
        //     .then(() => dispatch(updateIsSuccess(true)))
        //     .catch((e) => {
        //         dispatch(updateIsError(true));
        //     });

        if (internalFlowSettings !== undefined) {
            console.log(olderThanFiltration, newerThanFiltration);

            console.log(internalFlowSettings);
            dispatch(
                updateFlowSettingsByCategory({
                    category: selectedInternalMessageCategory,
                    settings: internalFlowSettings,
                })
            );
            updateFlowSettingsByCategoryApi(
                selectedInternalMessageCategory,
                internalFlowSettings
            );
        }
        close();
    };

    const close = () => props.setPromptsPopup(false);

    return (
        <Popup title="Settings" onClose={close}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "600px",
                }}
            >
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        Selected Category
                    </span>
                    <Dropdown
                        controlClassName="bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
                        placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
                        menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
                        arrowClassName="flex flex-row"
                        arrowClosed={
                            <>
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </>
                        }
                        arrowOpen={
                            <div className="transform rotate-180">
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </div>
                        }
                        options={chatConfig.categories.map(
                            (category) => category
                        )}
                        onChange={async (selectedOption) => {
                            // let categoryPrompt = await getPromptByCategory(
                            //     selectedOption.value
                            // );
                            // setSelectedPrompt({
                            //     prompt_name: selectedOption.value,
                            //     prompt_value: categoryPrompt,
                            // });
                            setSelectedInternalMessageCategory(
                                MessageCategory[
                                    selectedOption.value as keyof typeof MessageCategory
                                ]
                            );

                            //update flow settings
                            setInternalFlowSettings(
                                flowSettingsMap.filter(
                                    (flowSetting) =>
                                        flowSetting.category ===
                                        selectedOption.value
                                )[0]?.settings || {}
                            );
                        }}
                        value={selectedInternalMessageCategory}
                        placeholder="Select an option"
                    />
                </div>
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        Selected Model
                    </span>
                    <Dropdown
                        controlClassName="bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
                        placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
                        menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
                        arrowClassName="flex flex-row"
                        arrowClosed={
                            <>
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </>
                        }
                        arrowOpen={
                            <div className="transform rotate-180">
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </div>
                        }
                        options={
                            models != undefined
                                ? models.map((model) => model.modelName)
                                : []
                        }
                        onChange={async (selectedOption) => {
                            if (internalFlowSettings == undefined) {
                                return;
                            }
                            let settings: FlowSettings = {
                                ...internalFlowSettings,
                                modelName: selectedOption.value,
                            };
                            setInternalFlowSettings(settings);
                        }}
                        value={String(internalFlowSettings?.modelName)}
                        placeholder="Select an option"
                    />
                </div>
                <LLMSettingsField
                    displayName="Context Token Limit"
                    settingName="contextLimit"
                    flowSettings={internalFlowSettings}
                    setInternalFlowSettings={setInternalFlowSettings}
                ></LLMSettingsField>
                <LLMSettingsField
                    displayName="Answer Token Limit"
                    settingName="answerTokenLimit"
                    flowSettings={internalFlowSettings}
                    setInternalFlowSettings={setInternalFlowSettings}
                ></LLMSettingsField>
                <LLMSettingsField
                    displayName="Chunks Search Limit"
                    settingName="chunksSearchLimit"
                    flowSettings={internalFlowSettings}
                    setInternalFlowSettings={setInternalFlowSettings}
                ></LLMSettingsField>
                <LLMSettingsField
                    displayName="Maximum Chunk Distance"
                    settingName="minimumChunkDistance"
                    flowSettings={internalFlowSettings}
                    setInternalFlowSettings={setInternalFlowSettings}
                ></LLMSettingsField>

                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        Select Sorting By
                    </span>
                    <Dropdown
                        controlClassName="bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
                        placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
                        menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
                        arrowClassName="flex flex-row"
                        arrowClosed={
                            <>
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </>
                        }
                        arrowOpen={
                            <div className="transform rotate-180">
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </div>
                        }
                        options={Object.values(SortBy).map(
                            (key) => SortBy[key]
                        )}
                        onChange={async (selectedOption) => {
                            if (internalFlowSettings == undefined) {
                                return;
                            }
                            let settings: FlowSettings = {
                                ...internalFlowSettings,
                                sortBy: SortBy[
                                    selectedOption.value as keyof typeof SortBy
                                ],
                            };
                            setInternalFlowSettings(settings);
                        }}
                        value={String(internalFlowSettings?.sortBy)}
                        placeholder="Select an option"
                    />
                </div>
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        Select Sorting Order
                    </span>
                    <Dropdown
                        controlClassName="bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
                        placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
                        menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
                        arrowClassName="flex flex-row"
                        arrowClosed={
                            <>
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </>
                        }
                        arrowOpen={
                            <div className="transform rotate-180">
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </div>
                        }
                        options={Object.values(SortOrder).map(
                            (key) => SortOrder[key]
                        )}
                        onChange={async (selectedOption) => {
                            if (internalFlowSettings == undefined) {
                                return;
                            }
                            let settings: FlowSettings = {
                                ...internalFlowSettings,
                                sortOrder:
                                    SortOrder[
                                        selectedOption.value as keyof typeof SortOrder
                                    ],
                            };
                            setInternalFlowSettings(settings);
                        }}
                        value={String(internalFlowSettings?.sortOrder)}
                        placeholder="Select an option"
                    />
                </div>

                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        Similarity Search
                    </span>
                    <button
                        type="button"
                        className="bg-brand text-white"
                        style={{
                            padding: 5,
                            borderRadius: 10,
                            // marginTop: 20,
                            // paddingTop: 15,
                            // paddingBottom: 15,
                            fontSize: 19,
                            fontWeight: 500,
                            lineHeight: "30px",
                            backgroundColor:
                                internalFlowSettings?.isSimilaritySearch
                                    ? "green"
                                    : "red",
                        }}
                        onClick={() => {
                            if (internalFlowSettings === undefined) {
                                return;
                            }
                            setInternalFlowSettings({
                                ...internalFlowSettings,
                                isSimilaritySearch:
                                    !internalFlowSettings.isSimilaritySearch,
                            });
                        }}
                    >
                        {internalFlowSettings?.isSimilaritySearch
                            ? "On"
                            : "Off"}
                    </button>
                </div>

                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        Timeframe Filtration
                    </span>
                    <div style={{ display: "flex", gap: "5px" }}>
                        <button
                            type="button"
                            className="bg-brand text-white"
                            style={{
                                padding: 5,
                                borderRadius: 10,
                                // marginTop: 20,
                                // paddingTop: 15,
                                // paddingBottom: 15,
                                fontSize: 19,
                                fontWeight: 500,
                                lineHeight: "30px",
                            }}
                            onClick={() => {
                                if (internalFlowSettings === undefined) {
                                    return;
                                }
                                let newerThanTimestamp = newerThanFiltration
                                    ? null
                                    : internalFlowSettings?.newerThanTimestamp;
                                console.log(newerThanTimestamp);
                                setInternalFlowSettings({
                                    ...internalFlowSettings,
                                    newerThanTimestamp: newerThanTimestamp,
                                });
                                dispatch(
                                    updateNewerThanFiltration(
                                        !newerThanFiltration
                                    )
                                );
                            }}
                        >
                            Newer Than
                        </button>
                        <button
                            type="button"
                            className="bg-brand text-white"
                            style={{
                                padding: 5,
                                borderRadius: 10,
                                // marginTop: 20,
                                // paddingTop: 15,
                                // paddingBottom: 15,
                                fontSize: 19,
                                fontWeight: 500,
                                lineHeight: "30px",
                            }}
                            onClick={() => {
                                if (internalFlowSettings === undefined) {
                                    return;
                                }
                                let olderThanTimestamp = olderThanFiltration
                                    ? null
                                    : internalFlowSettings?.olderThanTimestamp;
                                console.log(olderThanTimestamp);
                                setInternalFlowSettings({
                                    ...internalFlowSettings,
                                    olderThanTimestamp: olderThanTimestamp,
                                });
                                dispatch(
                                    updateOlderThanFiltration(
                                        !olderThanFiltration
                                    )
                                );
                            }}
                        >
                            Older Than
                        </button>
                    </div>
                </div>

                {newerThanFiltration ? (
                    <div
                        className="border-stroke-light dark:border-stroke-dark"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: 15,
                        }}
                    >
                        <span
                            style={{
                                fontSize: 17,
                                lineHeight: "30px",
                                fontWeight: 300,
                                display: "flex",
                                gap: "5px",
                            }}
                        >
                            Newer Than{" "}
                            {internalFlowSettings?.newerThanTimestamp ? null : (
                                <p style={{ color: "red" }}>(Not set)</p>
                            )}
                        </span>
                        <DateTimePicker
                            value={
                                new Date(
                                    internalFlowSettings?.newerThanTimestamp ||
                                        new Date()
                                )
                            }
                            onChange={(e: Value) => {
                                // let timestamp = e?.valueOf() || new Date().valueOf();
                                let timestamp =
                                    new Date(
                                        Date.UTC(1970, 0, 1) + e!.valueOf()
                                    ).valueOf() || 0;
                                if (internalFlowSettings === undefined) {
                                    return;
                                }
                                setInternalFlowSettings({
                                    ...internalFlowSettings,
                                    newerThanTimestamp: timestamp,
                                });
                            }}
                        />
                    </div>
                ) : null}

                {olderThanFiltration ? (
                    <div
                        className="border-stroke-light dark:border-stroke-dark"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: 15,
                        }}
                    >
                        <span
                            style={{
                                fontSize: 17,
                                lineHeight: "30px",
                                fontWeight: 300,
                                display: "flex",
                                gap: "5px",
                            }}
                        >
                            Older Than
                            {internalFlowSettings?.olderThanTimestamp ? null : (
                                <p style={{ color: "red" }}>(Not set)</p>
                            )}
                        </span>
                        <DateTimePicker
                            value={
                                new Date(
                                    internalFlowSettings?.olderThanTimestamp ||
                                        new Date()
                                )
                            }
                            onChange={(e: Value) => {
                                let timestamp =
                                    new Date(
                                        Date.UTC(1970, 0, 1) + e!.valueOf()
                                    ).valueOf() || 0;
                                if (internalFlowSettings === undefined) {
                                    return;
                                }
                                setInternalFlowSettings({
                                    ...internalFlowSettings,
                                    olderThanTimestamp: timestamp,
                                });
                            }}
                        />
                    </div>
                ) : null}

                {selectedCategory === MessageCategory.LATEST_NEWS ||
                selectedCategory === MessageCategory.NEWS ? (
                    <div
                        className="border-stroke-light dark:border-stroke-dark"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            paddingBottom: 15,
                        }}
                    >
                        <span
                            style={{
                                fontSize: 17,
                                lineHeight: "30px",
                                fontWeight: 300,
                                display: "flex",
                                gap: "5px",
                            }}
                        >
                            Vector Collections
                        </span>
                        <div style={{ display: "flex", gap: "20px" }}>
                            {internalFlowSettings ? Object.keys(NewsVectorCollections).map(
                                (collection, index) => (
                                    <CheckBox
                                        label={collection}
                                        key={index}
                                        defaultChecked={internalFlowSettings?.vectorCollections.includes(
                                            NewsVectorCollections[
                                                collection as keyof typeof NewsVectorCollections
                                            ]
                                        )}
                                        onChange={(isSelected: boolean) => {
                                            if (
                                                internalFlowSettings ===
                                                undefined
                                            ) {
                                                return;
                                            }
                                            let vectorCollections = [
                                                ...internalFlowSettings.vectorCollections,
                                            ];
                                            if (isSelected) {
                                                vectorCollections.push(
                                                    NewsVectorCollections[
                                                        collection as keyof typeof NewsVectorCollections
                                                    ]
                                                );
                                            } else {
                                                vectorCollections =
                                                    vectorCollections.filter(
                                                        (item) =>
                                                            item !==
                                                            NewsVectorCollections[
                                                                collection as keyof typeof NewsVectorCollections
                                                            ]
                                                    );
                                            }

                                            setInternalFlowSettings({
                                                ...internalFlowSettings,
                                                vectorCollections:
                                                    vectorCollections,
                                            });
                                            console.log(
                                                internalFlowSettings?.vectorCollections
                                            );
                                        }}
                                    />
                                )
                            ) : null}
                        </div>
                    </div>
                ) : null}

                {/* <div style={{ borderBottomWidth: 1 }}>Prompt</div>
                <textarea
                    ref={textareaRef}
                    className="w-full bg-altBackground-light dark:bg-altBackground-dark focus:outline-none text-text1-light dark:text-text1-dark placeholder-text2-light dark:placeholder-text2-dark"
                    style={{
                        borderRadius: 10,
                        paddingTop: 10,
                        paddingBottom: 10,
                        paddingLeft: 15,
                        paddingRight: 15,
                        width: 600,
                        marginTop: 10,
                        resize: "none",
                        height: 200,
                    }}
                    placeholder="Prompt..."
                    value={selectedPrompt?.prompt_value || ""}
                    onChange={(event) => {
                        const newPrompt: Prompt = {
                            prompt_name:
                                selectedPrompt?.prompt_name || "undefined",
                            prompt_value: event.target.value,
                        };
                        setSelectedPrompt(newPrompt);
                    }}
                /> */}
                {/* <button
                type="button"
                className="bg-brand text-white"
                style={{
                    borderRadius: 10,
                    marginTop: 20,
                    paddingTop: 15,
                    paddingBottom: 15,
                    fontSize: 19,
                    fontWeight: 500,
                    lineHeight: "30px",
                    backgroundColor: "red",
                }}
                onClick={() => resetPrompt()}
            >
                Reset
            </button> */}
                <button
                    type="button"
                    className="bg-brand text-white"
                    style={{
                        borderRadius: 10,
                        marginTop: 20,
                        paddingTop: 15,
                        paddingBottom: 15,
                        fontSize: 19,
                        fontWeight: 500,
                        lineHeight: "30px",
                    }}
                    onClick={() => handleSubmit()}
                >
                    Save
                </button>
            </div>
        </Popup>
    );
}

export default FlowSettingsPopup;
