import React, {useEffect, useState} from "react";
import CloseButton from "../../../widgets/CloseButton";
import {getAllCollections} from "../../../common/home/api";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import {setCollections} from "../../../redux/collections";
import AdminCollectionField from "./AdminCollectionField";
import {isMobile} from "../../../utils";

function AdminPopup() {
    const dispatch = useAppDispatch();
    const collections = useAppSelector(
        (state) => state.collections.collections.collections
    );

    const viewDetails = isMobile
        ? "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col h-1/2 w-full bg-altBackground-light dark:bg-altBackground-dark rounded-lg z-20"
        : "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col h-1/2 w-1/2 bg-altBackground-light dark:bg-altBackground-dark rounded-lg z-20";

    useEffect(() => {
        getAllCollections().then((allCollections) => {
            dispatch(setCollections(allCollections));
        });
    }, []);

    return (
        <div className={viewDetails}>
            <div className="relative flex items-center justify-center h-full">
                <div className="absolute top-11 right-0 left-0 bottom-0 overflow-y-scroll p-2">
                    {Array.isArray(collections) &&
                        collections.map((c, index) => (
                            <AdminCollectionField key={index} collection_name={c}/>
                        ))}
                </div>
            </div>
            <CloseButton/>
        </div>
    );
}

export default AdminPopup;
