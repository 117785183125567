import React, {useState} from "react";
import {addToIndex, deleteToIndex, editToIndex, getChunk} from "../../../common/home/api";
import {useAppDispatch} from "../../../hooks/hooks";
import {updateIsError, updateIsSuccess} from "../../../redux/error";

function AdminCollectionField({collection_name}: { collection_name: string }) {
    const dispatch = useAppDispatch();
    const [addContent, setAddContent] = useState('');
    const [editChunkIdContent, setEditChunkIdContent] = useState('');
    const [editContentContent, setEditContentContent] = useState('');
    const [deleteContent, setDeleteContent] = useState('');
    const [getContent, setGetContent] = useState('');
    const [selectedOption, setSelectedOption] = useState('id');
    const [getResponse, setGetResponse] = useState('');

    const handleAddToIndex = async () => {
        if (addContent === '') {
            return;
        }

        try {
            await addToIndex({collection_name: collection_name, content: addContent}).then((response) => {
                setEditChunkIdContent(response.chunk_id);
                setGetContent(response.chunk_id);
                dispatch(updateIsSuccess(true));
            })
                .catch((error) => {
                    dispatch(updateIsError(true));
                });
        } catch (error) {
            console.error('Error while adding data to index', error);
        }

        setAddContent('');
    };

    const handleEdit = async () => {
        setEditChunkIdContent('');

        if (editChunkIdContent === '' || editContentContent === '') {
            return;
        }
        if (!/^[0-9]+$/.test(editChunkIdContent)) {
            return;
        }

        try {
            await editToIndex({
                collection_name: collection_name,
                chunk_id: editChunkIdContent,
                content: editContentContent
            }).then((response) => {
                dispatch(updateIsSuccess(true));
                setEditChunkIdContent(response.chunk_id);
                setGetContent(response.chunk_id);
            })
                .catch((error) => {
                    dispatch(updateIsError(true));
                });
        } catch (error) {
            console.error('Error while editing data to index', error);
        }

        setEditContentContent('');
    };

    const handleDeleteToIndex = async () => {
        if (deleteContent === '') {
            return;
        }
        if (!/^[0-9]+$/.test(deleteContent)) {
            return;
        }

        try {
            await deleteToIndex({collection_name: collection_name, chunk_id: deleteContent}).then((response) => {
                if (response.status === 200) {
                    dispatch(updateIsSuccess(true));
                    setEditChunkIdContent('');
                    setGetContent('');
                }
            })
                .catch((error) => {
                    dispatch(updateIsError(true));
                });
        } catch (error) {
            console.error('Error while deleting data from index', error);
        }

        setDeleteContent('');
    };

    const handleGet = async () => {
        if (getContent === '') {
            return;
        }

        let source = null;
        let chunkID = null;

        if (selectedOption === 'id') {
            chunkID = getContent;
        } else {
            source = getContent;
        }

        try {
            const response = await getChunk(collection_name, source, chunkID);

            if (response.chunks[0].text) {
                dispatch(updateIsSuccess(true));
                setGetResponse(response.chunks[0].text);
            }
        } catch (error) {
            dispatch(updateIsError(true));
            console.error('Error while getting data', error);
        }
    };


    return (
        <div
            className="w-full flex flex-col items-center justify-center p-6 bg-altBackground-light dark:bg-altBackground-dark rounded-lg shadow-lg my-5">
            <h2 className="text-2xl font-semibold text-text1-light dark:text-text1-dark mb-4">
                {collection_name}
            </h2>
            <div className="flex flex-col w-full items-center justify-center">
                <div className="w-full mb-4">
                    <div className="grid grid-cols-4 gap-2">
                        <div className="col-span-3 flex items-center">
                            <input
                                type="text"
                                className="py-2 px-4 w-full font-sans font-semibold bg-altBackground-light dark:bg-altBackground-dark focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 text-text1-light dark:text-text1-dark placeholder-gray-500 rounded"
                                placeholder="Add content ..."
                                value={addContent}
                                onChange={(event) => setAddContent(event.target.value)}
                            />
                        </div>
                        <div className="flex items-center">
                            <button
                                type="button"
                                className="w-full py-2 px-4 bg-green-500 hover:bg-green-600 text-white font-semibold rounded-lg shadow-md transition duration-200 ease-in-out"
                                onClick={handleAddToIndex}
                            >
                                Add
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-2 mt-4">
                        <div className="col-span-3 flex items-center">
                            <input
                                type="text"
                                className="py-2 px-4 mr-2 w-full font-sans font-semibold bg-altBackground-light dark:bg-altBackground-dark focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 text-text1-light dark:text-text1-dark placeholder-gray-500 rounded"
                                placeholder="Edit chunk id ..."
                                value={editChunkIdContent}
                                onChange={(event) => setEditChunkIdContent(event.target.value)}
                            />
                            <input
                                type="text"
                                className="py-2 px-4 w-full font-sans font-semibold bg-altBackground-light dark:bg-altBackground-dark focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 text-text1-light dark:text-text1-dark placeholder-gray-500 rounded"
                                placeholder="Edit content ..."
                                value={editContentContent}
                                onChange={(event) => setEditContentContent(event.target.value)}
                            />
                        </div>
                        <div className="flex items-center">
                            <button
                                type="button"
                                className="w-full py-2 px-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg shadow-md transition duration-200 ease-in-out"
                                onClick={handleEdit}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-2 mt-4">
                        <div className="col-span-3 flex items-center">
                            <input
                                type="text"
                                className="py-2 px-4 w-full font-sans font-semibold bg-altBackground-light dark:bg-altBackground-dark focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 text-text1-light dark:text-text1-dark placeholder-gray-500 rounded"
                                placeholder="Delete by chunk id ..."
                                value={deleteContent}
                                onChange={(event) => setDeleteContent(event.target.value)}/>
                        </div>
                        <div className="flex items-center">
                            <button
                                type="button"
                                className="w-full py-2 px-4 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg shadow-md transition duration-200 ease-in-out"
                                onClick={handleDeleteToIndex}
                            >
                                Delete
                            </button>
                        </div>
                    </div>
                    <div className="grid grid-cols-4 gap-2 mt-4">
                        <div className="col-span-3 flex items-center">
                            <input
                                type="text"
                                className="py-2 px-4 mr-2 w-full font-sans font-semibold bg-altBackground-light dark:bg-altBackground-dark focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-500 text-text1-light dark:text-text1-dark placeholder-gray-500 rounded"
                                placeholder="Get content by chunk id or source ..."
                                value={getContent}
                                onChange={(event) => setGetContent(event.target.value)}
                            />
                            <select
                                className="py-2 px-4 w-full font-sans font-semibold bg-altBackground-light dark:bg-altBackground-dark focus:outline-none focus:ring-2 text-text1-light dark:text-text1-dark placeholder-gray-500 rounded"
                                value={selectedOption}
                                onChange={(event) => setSelectedOption(event.target.value)}
                            >
                                <option value="id">CHUNK ID</option>
                                <option value="source">SOURCE</option>
                            </select>
                        </div>
                        <div className="flex items-center">
                            <button
                                type="button"
                                className="w-full py-2 px-4 bg-purple-500 hover:bg-purple-600 text-white font-semibold rounded-lg shadow-md transition duration-200 ease-in-out"
                                onClick={handleGet}
                            >
                                Get
                            </button>
                        </div>
                    </div>
                    <div
                        className="w-full mt-4 flex flex-col items-center justify-center rounded-lg bg-altBackground-light dark:bg-altBackground-dark min-h-32 overflow-auto max-h-[height]"
                    >
                        <p className="text-text1-light dark:text-text1-dark font-bold">Output Of Get</p>
                        <pre className="text-text1-light dark:text-text1-dark font-semibold whitespace-pre-wrap">
                            {getResponse}
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminCollectionField;