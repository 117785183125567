import React from "react";
import { Mask } from "../../../widgets/Mask";

type PropsT = {
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  additionalMessage?: string;
  width?: number;
};

const Popup: React.FC<PropsT> = (props: PropsT) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        zIndex: 12,
        flexDirection: "column"
      }}
    >
      <Mask zIndex={13} />
      {props.additionalMessage ? (
        <div
          style={{
            zIndex: 14,
            borderRadius: 20,
            padding: 20,
            maxWidth: "95%",
            width: props.width,
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center"
          }}
          className="bg-mainBackground-light dark:bg-mainBackground-dark text-text1-light dark:text-text1-dark"
        >
          <span>{props.additionalMessage}</span>
        </div>
      ) : null}
      <div
        className="transition duration-300 ease-in-out bg-mainBackground-light dark:bg-mainBackground-dark text-text1-light dark:text-text1-dark"
        style={{
          display: "flex",
          maxWidth: "95%",
          margin: 20,
          zIndex: 14,
          alignSelf: "center",
          flexDirection: "column",
          borderRadius: 20,
          width: props.width
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "relative"
          }}
        >
          <div
            style={{
              paddingTop: 17,
              display: "flex",
              fontSize: 17,
              lineHeight: "30px",
              fontWeight: 500,
              marginLeft: 56,
              marginRight: 56
            }}
          >
            {props.title ? <span>{props.title}</span> : null}
          </div>
          <button
            style={{
              display: "flex",
              width: 32,
              height: 32,
              position: "absolute",
              top: 16,
              right: 14
            }}
            onClick={props.onClose}
          >
            <img src="/close-button.svg" className="dark:hidden" />
            <img src="/close-button-dark.svg" className="hidden dark:flex" />
          </button>
        </div>
        <div style={{ display: "flex", padding: 20 }}>{props.children}</div>
      </div>
    </div>
  );
};

export default Popup;
