import React, { useState } from "react";
import Popup from "./Popup";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { updateGraphPopup } from "../../../redux/utils";
import { generateGraph } from "../../../common/home/api";
import { updateIsError } from "../../../redux/error";
import Graphviz from "graphviz-react";

export type GraphPopupProps = {
    open: boolean;
    selectedUrl: string | null;
};

function GraphPopup() {
    const dispatch = useAppDispatch();
    const graphPopup = useAppSelector((state) => state.utils.graphPopup);
    const [graphScript, setGraphScript] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const handleClose = () => {
        setGraphScript(undefined);
        dispatch(updateGraphPopup({ open: false, selectedUrl: null }));
    };

    const handleGraphGeneration = () => {
        setLoading(true);
        if (graphPopup.selectedUrl) {
            generateGraph(graphPopup.selectedUrl).then((res) => {
                if (res.trim() === "null") {
                    dispatch(updateIsError({ message: "Graph cannot be generated. Most likely paid article" }));
                    return;
                }
                setGraphScript(res);
                setLoading(false);
            }).catch((err) => { 
                console.log(err);
                dispatch(updateIsError({ message: "Graph cannot be generated. There was an error" }));
                setLoading(false);
            });
        }
    };

    const graphOptions = {
        fit: true,
        height: 1000,
        width: 1000,
        zoom: true
    }

    return (
        <div>
            {graphPopup !== undefined && graphPopup.open ? (
                <Popup title="Graph" onClose={handleClose}>
                    {loading ? (<h1>Generating graph...</h1>) : null}
                    {graphScript ? (
                        <div>
                            <Graphviz dot={graphScript} options={graphOptions}></Graphviz>
                        </div>
                    ) : null }
                    { loading === false && graphScript === undefined ? (
                        <div>
                            <div>
                                Do you want to generate entity graph or go visit
                                the link?
                            </div>
                            <div style={{display: "flex", gap: "10px"}}>
                                <button
                                    type="button"
                                    className="w-60 my-5 py-2 mr-1 bg-blue-500 shadow-lg hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                                    onClick={() => {
                                        handleGraphGeneration();
                                    }}
                                >
                                    Generate Entity Graph
                                </button>
                                <button
                                    type="button"
                                    className="w-60 my-5 py-2 mr-1 bg-blue-500 shadow-lg hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                                    onClick={() => {
                                        handleClose();
                                        window.open(
                                            graphPopup.selectedUrl || undefined
                                        );
                                    }}
                                >
                                    Visit Link
                                </button>
                            </div>
                        </div>
                    ) : null}
                </Popup>
            ) : null}
        </div>
    );
}

export default GraphPopup;
