import { updateIsError } from "./redux/error";
import store from "./redux/store";
import { disconnect as disconnectWebSocket } from "./hooks/WebSocket";
import { resetStore } from "./redux/resetStore";
import axios from "axios";
import { updateIsLoginOpen, updateLoginPopupMessage } from "./redux/utils";
import { updateCurrentChatId } from "./redux/chats";

export const isMobile = window.innerWidth < 768;

export const maybeHandleError = (error: any, shouldCatch?: boolean) => {
  if (!error) {
    store.dispatch(updateIsError(true));

    return;
  }

  if (error.response?.status === 401) {
    if (error.response?.data?.detail === "Incorrect email or password") {
      store.dispatch(
        updateIsError({
          message: "Incorrect email or password"
        })
      );

      return;
    } else if (
      !store.getState().utils.isLoginOpen &&
      !store.getState().utils.isRegisterOpen
    ) {
      logOut();
      return;
    }
  }

  if (error.response?.status === 400) {
    if (error.response.data.detail.error === "User Already Exists") {
      store.dispatch(
        updateIsError({
          message: "Email already in use",
          err: error.message
        })
      );

      return;
    }
  }

  if (error.response?.status === 429) {
    store.dispatch(
      updateLoginPopupMessage(
        `You have reached the allowed messages limit. <br/> Try again in ${error.response.data?.reset_after?.replace(
          /\.\d+/g,
          ""
        )} or Sign up for unlimited access.`
      )
    );
    store.dispatch(store.dispatch(updateIsLoginOpen(true)));

    return;
  }

  if (shouldCatch) {
    store.dispatch(updateIsError(true));
  } else {
    throw error;
  }
};

export const validateEmail = (email: string) => {
  return new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  ).test(email);
};

export const validatePassword = (password: string) => {
  if (!password.trim().length || password.length < 6) {
    return false;
  }

  return true;
};

const baseURL = window.location.origin.includes("chatbot-212")
  ? window.location.origin.replace("chatbot-212", "edge")
  : window.location.origin;

axios.defaults.baseURL = baseURL;

export const axiosInstance = axios;

export const logOut = () => {
  disconnectWebSocket();
  store.dispatch(updateCurrentChatId(-1));
  store.dispatch(resetStore());
  document.cookie = "t212ChatAuth=;max-age=0;";
};
