import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { updateIsLoginOpen, updateIsPreferences } from "../../../redux/utils";

export default function ProfileItem() {
  const dispatch = useAppDispatch();

  const email = useAppSelector((state) => state.user.email);

  return (
    <div
      className="hover:bg-opacity-5 hover:bg-highlight dark:hover:bg-opacity-5 dark:hover:bg-highlight transition duration-200 ease-in-out"
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: 50,
        alignItems: "center",
        paddingLeft: 10,
        paddingRight: 10,
        overflow: "hidden",
        cursor: "pointer",
        margin: 10,
        borderRadius: 10,
        userSelect: "none"
      }}
      onClick={() => {
        dispatch(updateIsPreferences(true));
      }}
    >
      <div
        className="transition duration-300 ease-in-out flex"
        style={{ overflow: "hidden" }}
      >
        <div style={{ marginRight: 20 }}>
          <img src="/avatar-placeholder.svg" className="dark:hidden" />
          <img
            src="/avatar-placeholder-dark.svg"
            className="hidden dark:flex"
          />
        </div>

        <span
          className="text-text1-light dark:text-text1-dark"
          style={{
            display: "inline-block",
            fontSize: 17,
            fontWeight: 300,
            lineHeight: "30px",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {email}
        </span>
      </div>
      <img src="/settings-button.svg" className="dark:hidden" />
      <img src="/settings-button-dark.svg" className="hidden dark:flex" />
    </div>
  );
}
