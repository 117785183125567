import React from "react";

type ButtonProps = {
  text: string;
  onClick: () => void;
  type?: "button" | "submit";
};

function Button({ text, onClick, type = "button" }: ButtonProps) {
  return (
    <button
      type={type}
      className="transition duration-300 ease-in-out w-full mt-5 py-2 bg-blue-500 bg-brand text-white font-semibold"
      style={{
        borderRadius: 10,
        fontSize: 19,
        fontWeight: 500,
        lineHeight: "30px",
        paddingTop: 15,
        paddingBottom: 15
      }}
      onClick={() => onClick()}
    >
      {text}
    </button>
  );
}

export default Button;
