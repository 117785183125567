import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "../../../utils";
import { useAppSelector } from "../../../hooks/hooks";
import MessageInput from "./MessageInput";
import { useDispatch, useSelector } from "react-redux";
import { updateIsLoginOpen } from "../../../redux/utils";

// Not quite unique for mobile. We want to have a lot of messages to scroll through. Used for measurement also.
const suggestedBaseMessages = !isMobile
  ? [
      "Perform a technical analysis on Tesla.",
      "How do I automate my investments with Trading 212?",
      "What are fractional shares, and how to invest with them?",
      "Analyse Apple's latest financial statement.",
      "Analyse Nvidia's last 4 quarters.",
      "What is the cash flow trend for Lucid Motors?",
      "How to use limit orders?"
    ]
  : [
      "Perform a technical analysis on Tesla.",
      "How do I automate my investments with Trading 212?",
      "What are fractional shares, and how to invest with them?",
      "Analyse Apple's latest financial statement.",
      "Analyse Nvidia's last 4 quarters.",
      "What is the cash flow trend for Lucid Motors?",
      "How to use limit orders?",
      "Perform a technical analysis on Tesla.",
      "How do I automate my investments with Trading 212?",
      "What are fractional shares, and how to invest with them?",
      "Analyse Apple's latest financial statement.",
      "Analyse Nvidia's last 4 quarters.",
      "What is the cash flow trend for Lucid Motors?",
      "How to use limit orders?",
      "Perform a technical analysis on Tesla.",
      "How do I automate my investments with Trading 212?",
      "What are fractional shares, and how to invest with them?",
      "Analyse Apple's latest financial statement.",
      "Analyse Nvidia's last 4 quarters.",
      "What is the cash flow trend for Lucid Motors?",
      "How to use limit orders?",
      "Perform a technical analysis on Tesla.",
      "How do I automate my investments with Trading 212?",
      "What are fractional shares, and how to invest with them?",
      "Analyse Apple's latest financial statement.",
      "Analyse Nvidia's last 4 quarters.",
      "What is the cash flow trend for Lucid Motors?",
      "How to use limit orders?",
      "Perform a technical analysis on Tesla.",
      "How do I automate my investments with Trading 212?",
      "What are fractional shares, and how to invest with them?",
      "Analyse Apple's latest financial statement.",
      "Analyse Nvidia's last 4 quarters.",
      "What is the cash flow trend for Lucid Motors?",
      "How to use limit orders?"
    ];

// Used for render. For mobile render the base messages, as they are already multiplied
const suggestedMessages = [
  ...suggestedBaseMessages,
  ...suggestedBaseMessages,
  ...suggestedBaseMessages
];

const suggestedMessagesScrollEveryMSDefault = 25;
const suggestedMessagesScrollByDefault = 1;

export default function SuggestedMessages(props: { onSubmit: () => void }) {
  const dispatch = useDispatch();

  const [suggestedMessagesScrollEveryMS, setSuggestedMessagesScrollEveryMS] =
    useState(suggestedMessagesScrollEveryMSDefault);
  const [scrollBy, setScrollBy] = useState(suggestedMessagesScrollByDefault);
  const [hasPerformedScrollByAdjustment, setHasPerformedScrollByAdjustment] =
    useState(false);

  const currentChatId = useAppSelector((state) => state.chats.currentChatId);

  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);

  const [suggestedMessagesHeight, setSuggestedMessagesHeight] = useState<
    number | undefined
  >(undefined);

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const measureContainerRef = React.useRef<HTMLDivElement>(null);
  const messageInputRef = React.useRef<typeof MessageInput>();

  const startAutoScroll = useCallback(() => {
    setShouldAutoScroll(true);
  }, [isHovered, isDragging]);

  const stopAutoScroll = useCallback(() => {
    setShouldAutoScroll(false);
  }, []);

  useEffect(() => {
    const current = measureContainerRef?.current;
    if (current) {
      if (current.clientHeight !== suggestedMessagesHeight) {
        setSuggestedMessagesHeight(current.clientHeight);
      }
    }
  }, [measureContainerRef.current, measureContainerRef?.current?.clientHeight]);

  useEffect(() => {
    if (!shouldAutoScroll || (isMobile && (isDragging || isScrolling))) {
      return;
    }

    const intervalId = setInterval(() => {
      if (scrollRef.current) {
        const isBottom =
          suggestedMessagesHeight &&
          scrollRef.current.scrollTop >= suggestedMessagesHeight;

        if (isBottom && !isMobile) {
          scrollRef.current.scrollTo({
            top: scrollBy
          });
        } else {
          const scrollTopBefore = scrollRef.current.scrollTop;

          scrollRef.current.scrollTo({
            top: scrollRef.current.scrollTop + scrollBy
          });

          const scrollTopAfter = scrollRef.current.scrollTop;

          if (
            scrollTopBefore === scrollTopAfter &&
            !(
              isMobile &&
              suggestedMessagesHeight &&
              scrollRef.current.scrollTop >= suggestedMessagesHeight
            )
          ) {
            let scrollAdjustment = 0;
            let scrollEveryMSAdjustmentCoeff = 1;
            let scrollTop = scrollRef.current.scrollTop;

            do {
              scrollTop = scrollRef.current.scrollTop;

              scrollRef.current.scrollTo({
                top:
                  scrollRef.current.scrollTop +
                  suggestedMessagesScrollByDefault +
                  scrollAdjustment
              });

              scrollAdjustment += 0.001;
            } while (scrollTop === scrollRef.current.scrollTop);

            const scrollByAdjusted =
              suggestedMessagesScrollByDefault + scrollAdjustment;

            scrollEveryMSAdjustmentCoeff =
              suggestedMessagesScrollByDefault + scrollAdjustment;

            setScrollBy(scrollByAdjusted);
            setSuggestedMessagesScrollEveryMS(
              suggestedMessagesScrollEveryMSDefault *
                scrollEveryMSAdjustmentCoeff
            );
            setHasPerformedScrollByAdjustment(true);
          } else {
            // No adjustment needed
            if (!hasPerformedScrollByAdjustment) {
              setHasPerformedScrollByAdjustment(true);
            }
          }
        }
      }
    }, suggestedMessagesScrollEveryMS);
    return () => clearInterval(intervalId);
  }, [
    shouldAutoScroll,
    suggestedMessagesHeight,
    suggestedMessagesScrollEveryMS,
    scrollBy,
    isMobile,
    isDragging,
    isScrolling
  ]);

  let scrollTimeoutId: NodeJS.Timeout | undefined;

  return (
    <div className="flex flex-1 flex-col bg-altBackground-light dark:bg-altBackground-dark m-0 h-full overflow-hidden">
      <div
        className="flex flex-1 flex-col overflow-hidden bg-mainBackground-light h-full dark:bg-mainBackground-dark"
        style={{
          marginTop: isMobile ? 1 : 10,
          marginBottom: isMobile ? 0 : 10,
          marginRight: isMobile ? 0 : 10,
          marginLeft: isMobile ? 0 : 0,
          borderRadius: isMobile ? 0 : 10,
          position: "relative"
        }}
      >
        <div
          className="suggested-messages-container flex flex-1 flex-col w-full"
          style={{
            marginTop: 0,
            marginBottom: 50,
            justifyContent: "center",
            display: "flex"
          }}
        >
          <div
            className="bg-gradient-to-b from-mainBackground-light dark:from-mainBackground-dark"
            style={{
              position: "relative",
              height: 70,
              left: 0,
              right: 0,
              pointerEvents: "none",
              top: 60,
              zIndex: 1
            }}
          />
          <div
            className="flex flex-col items-center bg-mainBackground-light dark:bg-mainBackground-dark h-full overflow-y-scroll"
            style={{
              position: "relative",
              maxHeight: suggestedMessagesHeight ?? 0
            }}
          >
            <div
              style={{
                display: "flex",
                flex: 1,
                width: "100%",
                maxWidth: 850
              }}
            >
              <div
                className="empty-state-scroll-wrapper"
                ref={scrollRef}
                onScroll={(event) => {
                  const isBottom =
                    suggestedMessagesHeight &&
                    event.currentTarget.scrollTop >= suggestedMessagesHeight;

                  if (
                    !scrollRef.current ||
                    (!isMobile && shouldAutoScroll) ||
                    !suggestedMessagesHeight
                  ) {
                    return;
                  }

                  if (scrollTimeoutId) {
                    clearTimeout(scrollTimeoutId);
                    scrollTimeoutId = undefined;
                  }

                  if (!scrollTimeoutId) {
                    scrollTimeoutId = setTimeout(() => {
                      if (!shouldAutoScroll && isScrolling) {
                        setIsScrolling(false);
                        setShouldAutoScroll(true);
                      }

                      scrollTimeoutId = undefined;
                    }, 1000);
                  }

                  if (isBottom && !isMobile) {
                    scrollRef.current.scrollTo({
                      top: scrollBy
                    });
                  } else if (event.currentTarget.scrollTop <= 0 && !isMobile) {
                    scrollRef.current.scrollTo({
                      top: suggestedMessagesHeight
                    });
                  }
                }}
              >
                {/* Suggested messages hidden container for measurement */}
                <div
                  className="empty-state-scroll-container empty-state-measure-scroll-container"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    pointerEvents: "none",
                    opacity: 0,
                    paddingBottom: isMobile ? 40 : 0
                  }}
                  ref={measureContainerRef}
                >
                  {suggestedBaseMessages.map((message, index) => (
                    <div
                      key={index}
                      className="empty-state-message transition duration-300 ease-in-out"
                      style={{
                        borderRadius: 10,
                        paddingRight: 20,
                        paddingBottom: 20,
                        paddingTop: 19,
                        paddingLeft: 19,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start"
                      }}
                    >
                      <img
                        src="/empty-state-message.svg"
                        className="dark:hidden"
                        width={32}
                        height={32}
                      />
                      <img
                        src="/empty-state-message-dark.svg"
                        className="hidden dark:flex"
                        width={32}
                        height={32}
                      />
                      <span
                        className="text-text1-light dark:text-text1-dark"
                        style={{
                          fontSize: 17,
                          fontWeight: 300,
                          lineHeight: "30px",
                          marginLeft: 19,
                          marginTop: 2
                        }}
                      >
                        {message}
                      </span>
                    </div>
                  ))}
                </div>

                {/* Actual suggested messages container */}
                <div
                  className="empty-state-scroll-container"
                  onMouseEnter={() => {
                    stopAutoScroll();
                    setIsHovered(true);
                  }}
                  onMouseLeave={() => {
                    setIsHovered(false);
                    startAutoScroll();
                  }}
                  onTouchStart={() => {
                    setShouldAutoScroll(false);
                    setIsScrolling(true);
                    setIsDragging(true);
                  }}
                  onTouchEnd={() => {
                    setIsDragging(false);
                  }}
                  style={{ paddingLeft: 5, paddingBottom: isMobile ? 40 : 0 }}
                >
                  {suggestedMessages.map((message, index) => (
                    <div
                      key={index}
                      onClick={() => {
                        if (messageInputRef.current) {
                          // TS doesn't know about the submit method, no time to debug
                          (messageInputRef.current as any).submit?.(message);
                        }
                      }}
                      className="empty-state-message transition duration-300 ease-in-out"
                      style={{
                        borderRadius: 10,
                        paddingRight: 20,
                        paddingBottom: 20,
                        paddingTop: 19,
                        paddingLeft: 19,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start"
                      }}
                    >
                      <img
                        src="/empty-state-message.svg"
                        className="dark:hidden"
                        width={32}
                        height={32}
                      />
                      <img
                        src="/empty-state-message-dark.svg"
                        className="hidden dark:flex"
                        width={32}
                        height={32}
                      />
                      <span
                        className="text-text1-light dark:text-text1-dark"
                        style={{
                          fontSize: 17,
                          fontWeight: 300,
                          lineHeight: "30px",
                          marginLeft: 19,
                          marginTop: 2
                        }}
                      >
                        {message}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div
            className="bg-gradient-to-t from-mainBackground-light dark:from-mainBackground-dark"
            style={{
              height: 70,
              left: 0,
              right: 0,
              pointerEvents: "none",
              marginTop: -60,
              zIndex: 1
            }}
          />
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            paddingRight: 10,
            maxWidth: 850,
            alignSelf: "center"
          }}
        >
          <MessageInput
            ref={messageInputRef}
            placeholderText="Ask anything about stocks, news, and Trading&nbsp;212"
            onSubmit={() => {
              props.onSubmit();
            }}
          />
        </div>
      </div>
    </div>
  );
}
