import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageBody } from "../common/home/models/MessageBody";
import { resetStore } from "./resetStore";
import {
  MessageTagsByMessageTypeТ,
  MessageTagТ,
  MessageType,
  MessageTypeResponse
} from "../common/home/models/MessageType";

type InitialState = {
  currentMessage: MessageBody;
  messages: MessageBody[];
  messageTagsByMessageType?: MessageTagsByMessageTypeТ;
};

const initialState: InitialState = {
  currentMessage: {
    message_type: MessageType.general,
    isBot: false,
    message_id: 0,
    message: "",
    docs: [],
    thoughts: [],
    categories: []
  },
  messages: [],
  messageTagsByMessageType: undefined
};

export const messageSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    updateMessageStatus: (
      state,
      action: PayloadAction<{ id: MessageBody["message_id"]; status: number }>
    ) => {
      const messageIndex = state.messages.findIndex((message) => {
        return message.message_id === action.payload.id && message.isBot;
      });

      if (messageIndex) {
        state.messages[messageIndex].status = action.payload.status;
      }
    },
    updateCurrentMessage: (state, action: PayloadAction<MessageBody>) => {
      state.currentMessage = action.payload;
    },
    addMessage: (state, action: PayloadAction<MessageBody>) => {
      state.messages.push(action.payload);
    },
    updateLastMessage: (state, action: PayloadAction<MessageBody>) => {
      const lastIndex = state.messages.length - 1;
      if (lastIndex >= 0) {
        state.messages[lastIndex] = action.payload;
      }
    },
    updateMessageSelectedTags: (
      state,
      action: PayloadAction<{
        messageId: MessageBody["message_id"];
        tags: MessageTagТ["id"][];
      }>
    ) => {
      state.messages = state.messages.map((message) => {
        if (message.message_id === action.payload.messageId) {
          return {
            ...message,
            tags: action.payload.tags
          };
        }

        return message;
      });
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    setMessageTagsByMessageType: (
      state,
      action: PayloadAction<MessageTypeResponse>
    ) => {
      state.messageTagsByMessageType =
        action.payload.reduce<MessageTagsByMessageTypeТ>(
          (acc, messageTypeInfo) => {
            acc[messageTypeInfo.id] = {
              tags: messageTypeInfo.tags,
              messageTypeId: messageTypeInfo.id
            };
            return acc;
          },
          {} as MessageTagsByMessageTypeТ
        );
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, () => initialState);
  }
});

export const {
  updateMessageStatus,
  updateCurrentMessage,
  addMessage,
  updateLastMessage,
  clearMessages,
  updateMessageSelectedTags,
  setMessageTagsByMessageType
} = messageSlice.actions;
export default messageSlice.reducer;
