import React, { useEffect, useState } from "react";
import Popup from "./Popup";
import Dropdown from "react-dropdown";
import { Prompt } from "../../../common/home/models/Prompt";
import {
    getAllPromptTypes,
    getPromptByType,
    updatePrompt,
} from "../../../common/home/api";
import { updateIsSuccess } from "../../../redux/error";
import { useAppDispatch } from "../../../hooks/hooks";

type Props = {
    onClose: () => void;
};

function PromptsPopup(props: Props) {
    const dispatch = useAppDispatch();
    const [selectedPrompt, setSelectedPrompt] = useState<Prompt>({
        type: "",
        prompt: "",
    });
    const [promptTypes, setPromptTypes] = useState<string[]>([]);

    useEffect(() => {
        //TODO: send request to get all prompts;
        getAllPromptTypes().then((prompts) => setPromptTypes(prompts));
    }, []);

    const handleSubmit = () => {
        updatePrompt(selectedPrompt);
        dispatch(updateIsSuccess({ message: "Prompt updated" }));
    };

    return (
        <Popup onClose={props.onClose} >
            <div
                className="popup-content"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    width: 800,
                    padding: 30,
                }}
            >
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        Selected Prompt
                    </span>
                    <Dropdown
                        controlClassName="bg-mainBackground-light dark:bg-mainBackground-dark preferences-dropdown border-stroke-light dark:border-stroke-dark"
                        placeholderClassName="text-text1-light dark:text-text1-dark dropdown-placeholder"
                        menuClassName="bg-mainBackground-light dark:bg-mainBackground-dark border-text1-light dark:border-text1-dark dropdown-menu text-text1-light dark:text-text1-dark"
                        arrowClassName="flex flex-row"
                        arrowClosed={
                            <>
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </>
                        }
                        arrowOpen={
                            <div className="transform rotate-180">
                                <img
                                    src="/dropdown-arrow.svg"
                                    className="dark:hidden"
                                />
                                <img
                                    src="/dropdown-arrow-dark.svg"
                                    className="hidden dark:flex"
                                />
                            </div>
                        }
                        options={promptTypes.map((prompt) => prompt)}
                        onChange={async (selectedOption) => {
                            if (selectedOption) {
                                let prompt: Prompt = await getPromptByType(
                                    selectedOption.value
                                );
                                setSelectedPrompt(prompt);
                            }
                        }}
                        value={selectedPrompt.type}
                        placeholder="Select an option"
                    />
                </div>
                <div>
                    <div style={{ borderBottomWidth: 1 }}>Prompt</div>
                    <textarea
                        className="w-full bg-altBackground-light dark:bg-altBackground-dark focus:outline-none text-text1-light dark:text-text1-dark placeholder-text2-light dark:placeholder-text2-dark"
                        style={{
                            borderRadius: 10,
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 15,
                            paddingRight: 15,
                            width: "100%",
                            marginTop: 10,
                            resize: "none",
                            height: 200,
                        }}
                        placeholder="Prompt..."
                        value={selectedPrompt?.prompt || ""}
                        onChange={(event) => {
                            const newPrompt: Prompt = {
                                type: selectedPrompt?.type || "undefined",
                                prompt: event.target.value,
                            };
                            setSelectedPrompt(newPrompt);
                        }}
                    />
                </div>
                <button
                    type="button"
                    className="bg-brand text-white"
                    style={{
                        borderRadius: 10,
                        marginTop: 20,
                        paddingTop: 15,
                        paddingBottom: 15,
                        fontSize: 19,
                        fontWeight: 500,
                        lineHeight: "30px",
                    }}
                    onClick={() => handleSubmit()}
                >
                    Update
                </button>
            </div>
        </Popup>
    );
}

export default PromptsPopup;
