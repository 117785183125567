export type User = {
  email: string;
  access_token: string;
  isAdmin: boolean | null;
};

export function isUser(obj: any): obj is User {
  return typeof obj.email === "string" && typeof obj.access_token === "string";
}

export function isValidUser(obj: any): boolean {
  if (!isUser(obj)) {
    return false;
  }

  if (obj.email === "" || obj.access_token === "") {
    return false;
  }

  return true;
}
