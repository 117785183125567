import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
    updateAdminModeEnabled,
    updateIsChangePasswordOpen,
    updateIsNewChatAnonPopupOpen,
    updateIsPreferences,
} from "../../../redux/utils";
import Popup from "./Popup";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import useDarkMode from "../../../hooks/Theme";
import { useNavigate } from "react-router-dom";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { isMobile, logOut } from "../../../utils";
import { updateCurrentChatId } from "../../../redux/chats";
import { clearMessages } from "../../../redux/messages";
import FlowSettingsPopup from "./FlowSettingsPopup";

function NewChatAnonPopup() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const isNewChatAnonPopupOpen = useAppSelector(
        (state) => state.utils.isNewChatAnonPopupOpen
    );
    const adminModeEnabled = useAppSelector(
        (state) => state.utils.adminModeEnabled
    );
    const isAdminUser = useAppSelector((state) => state.user.isAdmin);
    const authToken = useAppSelector((state) => state.user.access_token);
    const messages = useAppSelector((state) => state.messages.messages);

    const [darkTheme, setDarkTheme] = useDarkMode();

    const options = [
        {
            value: "Dark",
            label: "Dark",
            className: "text-text1-light dark:text-text1-dark",
        },
        {
            value: "Light",
            label: "Light",
            className: "text-text1-light dark:text-text1-dark",
        },
    ];
    const defaultOption = darkTheme ? "Dark" : "Light";

    return isNewChatAnonPopupOpen ? (
        <Popup
            onClose={() => {
                dispatch(updateIsNewChatAnonPopupOpen(false));
            }}
        >
            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    width: 390,
                }}
            >
                <span>
                    Creating a new chat will delete your current one. Log in or
                    sign up for full chat history.
                </span>

                <button
                    type="button"
                    className="w-full mb-5 py-2 text-text1-light dark:text-text1-dark border-text1-light dark:border-text1-dark hover:opacity-70 dark:hover:bg-opacity-5 dark:hover:bg-text1-dark transition duration-200 ease-in-out"
                    style={{
                        height: 50,
                        fontSize: 15,
                        fontWeight: 500,
                        borderWidth: 1,
                        borderRadius: 10,
                        marginLeft: 20,
                        marginRight: 20,
                        lineHeight: "30px",
                    }}
                    onClick={() => {
                        const lastIndex: number = messages.length - 1;
                        if (messages[lastIndex]?.message === "") {
                            return;
                        }

                        dispatch(updateCurrentChatId(-1));
                        dispatch(clearMessages());
                        dispatch(updateIsNewChatAnonPopupOpen(false));
                    }}
                >
                    New chat
                </button>
            </div>
        </Popup>
    ) : null;
}

export default NewChatAnonPopup;
