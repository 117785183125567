import { MessageRequest } from "../common/home/requests/MessageRequest";
import { MessageBody } from "../common/home/models/MessageBody";
import { updateLastMessage } from "../redux/messages";
import store from "../redux/store";
import { addAnonChat, updateCurrentChatId } from "../redux/chats";
import {
    updateFollowups,
    updateIsSocketConnected,
    updateIsSocketConnecting,
} from "../redux/utils";

let socket: WebSocket | null = null;
let chat_id: number | undefined = -1;
let retryTimeStart = 0;
const port = process.env.REACT_APP_API_SERVER_PORT || "";

export function connect(navigate?: (path: string) => void): void {
    // const authToken = store.getState().user.access_token;
    const customerId = store.getState().user.customerId;

    if (socket) {
        console.warn("WebSocket is already connected.");
        store.dispatch(updateIsSocketConnected(true));
        store.dispatch(updateIsSocketConnecting(false));

        return;
    }

    function makeLinks(input: string) {
        const URL_REGEX =
            /(?!<img[^>]*)(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9%_\+.~#?&//=]*))(?![^<]*>)/g;

        return input.replace(URL_REGEX, function (url) {
            return `<a href="${url}" class="generated-link">${url}</a>`;
        });
    }

    store.dispatch(updateIsSocketConnecting(true));

    const protocol = document.location.protocol === "https:" ? "wss:" : "ws:";
    const hostname = window.location.hostname;
    console.log(`Hostname: ${hostname}`)

    socket = new WebSocket(
        `${protocol}//${document.location.hostname}${port}/ws/chat?customerId=${customerId}`
    );

    if (hostname === "localhost") {
        socket = new WebSocket(
            `ws://localhost:8090/ws/chat?customerId=${customerId}`
        );
    }

    socket.onopen = (): void => {
        retryTimeStart = 0;
        console.log("WebSocket connection opened");
        store.dispatch(updateIsSocketConnected(true));
        store.dispatch(updateIsSocketConnecting(false));
    };

    socket.onmessage = (event: MessageEvent): void => {
        try {
            const response: MessageBody = JSON.parse(event.data);

            // parse all links
            const responseMessage = makeLinks(response.message);

            const message: MessageBody = {
                message_type: response.message_type,
                isBot: true,
                chat_id: response.chat_id,
                message_id: response.message_id,
                total_tokens: response.total_tokens,
                total_cost: response.total_cost,
                message: responseMessage,
                docs: response?.docs?.map((doc) => ({
                    page_content: doc.page_content,
                    metadata: {
                        chunk: doc.metadata.chunk,
                        source: doc.metadata.source,
                    },
                })),
                thoughts: response.thoughts,
                qa_prompt: response.qa_prompt,
                categorization_prompt: response.categorization_prompt,
                qa_answer: response.qa_answer,
                categorization_answer: response.categorization_answer,
                categories: response.categories,
                response_time: response.response_time,
                prompt: response.prompt,
                chunks: response.chunks,
                followupChunks: response.followupChunks
            };

            const currentChatId = store.getState().chats.currentChatId;
            store.dispatch(updateFollowups(response.followupChunks || []));

            if (
                currentChatId === -1 ||
                currentChatId === message.chat_id ||
                currentChatId === store.getState().chats.lastAnonChatId
            ) {
                let chatId = message.chat_id;

                // // For anons create a chat also
                // if (!authToken && currentChatId === -1) {
                //   chatId = Date.now();

                //   store.dispatch(
                //     addAnonChat({
                //       id: chatId,
                //       chat_name: response.message.slice(0, 40)
                //     })
                //   );
                // }
                store.dispatch(updateLastMessage(message));

                if (chatId) {
                    store.dispatch(updateCurrentChatId(chatId));
                }
            }
        } catch (err) {
            console.log("WS Message received error: ", err);
        }
    };

    socket.onerror = (event): void => {
        console.error("WebSocket error: ", event);
    };

    socket.onclose = (event): void => {
        socket = null;
        store.dispatch(updateIsSocketConnected(false));

        if (event.code == 1000) {
            console.log("WebSocket connection closed");
        } else {
            scheduleReconnect(navigate);
        }
    };
}

export function disconnect() {
    if (socket) {
        socket.close();
    }
}

export function send(messageRequest: MessageRequest): void {
    if ("chat_id" in messageRequest) {
        chat_id = messageRequest.chat_id;
    }

    socket?.send(JSON.stringify(messageRequest));
}

function scheduleReconnect(navigate?: (path: string) => void) {
    if (!retryTimeStart) {
        retryTimeStart = Date.now();
    }

    if (Date.now() - retryTimeStart < 10 * 1000) {
        store.dispatch(updateIsSocketConnecting(true));

        setTimeout(() => connect(navigate), 5000);
        console.log(
            `WebSocket reconnect, try ${Math.floor(
                (Date.now() - retryTimeStart) / (5 * 1000) + 1
            )}`
        );
    } else {
        console.log(
            "Could not reconnect within the allowed time. Stopping reconnection attempts."
        );
        retryTimeStart = 0;
        store.dispatch(updateIsSocketConnecting(false));

        // store.dispatch(resetStore());
        // if (typeof navigate === "function") {
        //   navigate("/");
        // } else {
        //   console.error("navigate is not a function");
        // }
    }
}
