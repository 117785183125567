import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { resetStore } from "./resetStore";

type InitialState = {
  isError: { message: string; err?: any; url?: string } | boolean;
  isSuccess: { message: string } | boolean;
};

const initialState: InitialState = {
  isError: false,
  isSuccess: false
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    updateIsError: (
      state,
      action: PayloadAction<
        { message: string; err?: any; url?: string } | boolean
      >
    ) => {
      if (typeof action.payload === "boolean") {
        state.isError = action.payload;
        return;
      }

      state.isError = {
        message: action.payload.message,
        err: JSON.stringify(action.payload.err, null, 2),
        url: action.payload.url
      };
    },
    updateIsSuccess: (
      state,
      action: PayloadAction<{ message: string } | boolean>
    ) => {
      state.isSuccess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(resetStore, () => initialState);
  }
});

export default errorSlice.reducer;
export const { updateIsError, updateIsSuccess } = errorSlice.actions;
