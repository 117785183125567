import React from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { Chunk } from "../../../common/home/models/MessageBody";
import MessageInput from "./MessageInput";
import { updateFollowups } from "../../../redux/utils";


type FollowUpBubbleProps = {
    onClick: () => void;
    question: string;
};

function FollowupBubble(props: FollowUpBubbleProps) {
    return (
        <div
            className="bg-stroke-light"
            style={{
                fontSize: "14px",
                border: "1px solid black",
                borderRadius: "5px",
                padding: "8px",
                textAlign: "center",
            }}
            onClick={props.onClick}
        >
            {props.question}
        </div>
    );
}

type FollowUpsComponentProps = {
    messageInputRef: React.RefObject<any>;
};
export function FollowUpsComponent(props: FollowUpsComponentProps) {
    const dispatch = useAppDispatch();
    
    const submitMessage = (message: string) => {
        console.log("submitting message", message);
        console.log("messageInputRef", props.messageInputRef);
        if (props.messageInputRef.current) {
            // TS doesn't know about the submit method, no time to debug
            (props.messageInputRef.current as any).submit?.(message);
            dispatch(updateFollowups([]))
        }
    };

    const followupChunks = useAppSelector(
        (state) => state.utils.followupChunks
    );

    return (
        <div style={{ display: "flex", gap: "5px", marginRight: "15px", marginLeft: "15px" }}>
            {followupChunks && followupChunks.map((chunk: Chunk, index) => (
                <FollowupBubble key={index} question={chunk.fields.text} onClick={() => submitMessage(chunk.fields.text)} />
            ))}
        </div>
    );
}

export default FollowUpsComponent;
