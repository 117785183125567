import React from "react";

function Logo() {
    return (
        <>
            <img
                src="/212-logo.png"
                className="dark:hidden"
                width={158}
                height={20}
                style={{userSelect: "none"}}
            />
            <img
                src="/212-logo-dark.png"
                className="hidden dark:flex"
                width={158}
                height={20}
                style={{userSelect: "none"}}
            />
        </>
    );
}

export default Logo;
