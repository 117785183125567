import { useCallback } from "react";

export function MainButton(props: {
  text: string;
  onClick: () => void;
  isDisabled?: boolean;
  paddingVertical?: number;
}) {
  const onClick = useCallback(() => {
    if (props.isDisabled) {
      return;
    }
    props.onClick();
  }, [props.onClick, props.isDisabled]);

  return (
    <button
      className="transition duration-300 ease-in-out bg-brand text-white hover:opacity-70"
      onClick={onClick}
      style={{
        display: "flex",
        flex: 1,
        paddingTop: props.paddingVertical ?? 5,
        paddingBottom: props.paddingVertical ?? 5,
        paddingLeft: 15,
        paddingRight: 15,
        fontSize: 15,
        lineHeight: "30px",
        fontWeight: 500,
        borderRadius: 10,
        opacity: props.isDisabled ? 0.5 : undefined,
        userSelect: "none",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {props.text}
    </button>
  );
}
