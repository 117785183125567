import { useEffect, useState } from "react";
import { FlowSettings } from "../common/home/models/FlowSettings";
import React from "react";

type LLMSettingsFieldProps = {
    displayName: string;
    settingName: keyof FlowSettings;
    flowSettings: FlowSettings | undefined;
    setInternalFlowSettings: React.Dispatch<React.SetStateAction<FlowSettings | undefined>>;
};

export function LLMSettingsField(props: LLMSettingsFieldProps) {
    const [inputValue, setInputValue] = useState("");
    // update local state when prop changes
    useEffect(() => {
        if (props.flowSettings) {
            setInputValue(String(props.flowSettings[props.settingName]));
        }
    }, [props.flowSettings, props.settingName]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (props.flowSettings) {
            let settings: FlowSettings = {
                ...props.flowSettings,
                [props.settingName]: value,
            };
            props.setInternalFlowSettings(settings);
            setInputValue(e.target.value);
        }
    };


    return (
        <div>
            {props.flowSettings ? (
                <div
                    className="border-stroke-light dark:border-stroke-dark"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        paddingBottom: 15,
                    }}
                >
                    <span
                        style={{
                            fontSize: 17,
                            lineHeight: "30px",
                            fontWeight: 300,
                        }}
                    >
                        {props.displayName}
                    </span>
                    <input
                        onChange={handleInputChange}
                        value={inputValue}
                        type="text"
                        className="text-text1-light dark:text-text1-dark bg-altBackground-light dark:bg-altBackground-dark transition duration-300 ease-in-out hover:opacity-70 border-text1-light dark:border-text1-dark text-text1-light dark:text-text1-dark focus:outline-none placeholder-text2-light dark:placeholder-text2-dark"
                        placeholder="context limit"
                        style={{
                            width: "50%",
                            paddingTop: 10,
                            paddingBottom: 10,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderWidth: 1,
                            borderRadius: 10,
                            fontSize: 15,
                            fontWeight: 300,
                            lineHeight: "20px",
                        }}
                    />
                </div>
            ) : null}
        </div>
    );
}
