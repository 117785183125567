import React, { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  passwordChangedSuccessfully,
  updateIsChangePasswordOpen
} from "../../../redux/utils";
import Popup from "./Popup";
import "react-dropdown/style.css";
import { useNavigate } from "react-router-dom";
import "react-toggle/style.css";
import { changePassword } from "../../../common/auth/api";
import { updateIsError, updateIsSuccess } from "../../../redux/error";
import { MainButton } from "../../../widgets/MainButton";
import { maybeHandleError, validatePassword } from "../../../utils";

function ChangePasswordPopup() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isChangePasswordOpen = useAppSelector(
    (state) => state.utils.isChangePasswordOpen
  );
  const auth_token = useAppSelector((state) => state.user.access_token);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const onSubmit = useCallback(() => {
    if (!validatePassword(newPassword)) {
      dispatch(
        updateIsError({
          message:
            "Password must be at least 6 characters long and cannot contain only spaces!"
        })
      );

      return;
    }

    if (oldPassword === newPassword) {
      return dispatch(
        updateIsError({
          message: "Your new password cannot be the same as the old one!"
        })
      );
    }

    if (newPassword !== newPasswordConfirm) {
      return dispatch(updateIsError({ message: "Passwords do not match!" }));
    }

    changePassword(oldPassword, newPassword, auth_token)
      .then(() => {
        dispatch(passwordChangedSuccessfully());
        dispatch(
          updateIsSuccess({ message: "Password changed successfully!" })
        );
        setOldPassword("");
        setNewPassword("");
        setNewPasswordConfirm("");
      })
      .catch((err) => {
        maybeHandleError(err, true);
      });
  }, [oldPassword, newPassword, newPasswordConfirm, auth_token]);

  useEffect(() => {
    if (!isChangePasswordOpen) {
      setOldPassword("");
      setNewPassword("");
      setNewPasswordConfirm("");
    }
  }, [isChangePasswordOpen]);

  return isChangePasswordOpen ? (
    <Popup
      title="Change password"
      onClose={() => {
        dispatch(updateIsChangePasswordOpen(false));
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: 390
        }}
      >
        <div
          className="max-w-[400px] w-full mx-auto bg-mainBackground-light dark:bg-mainBackground-dark"
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 20
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();

              onSubmit();
            }
          }}
        >
          <div className="flex flex-col" style={{ marginBottom: 13 }}>
            <label
              className="text-text2-light dark:text-text2-dark"
              style={{ fontSize: 12, fontWeight: 600, lineHeight: "19px" }}
            >
              OLD PASSWORD
            </label>
            <input
              className="border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark bg-mainBackground-light dark:bg-mainBackground-dark focus:outline-none"
              style={{
                borderBottomWidth: 1,
                paddingBottom: 10
              }}
              type="password"
              value={oldPassword}
              onChange={(event) => setOldPassword(event.target.value)}
              autoCapitalize="off"
            />
          </div>
          <div className="flex flex-col" style={{ marginBottom: 13 }}>
            <label
              className="text-text2-light dark:text-text2-dark"
              style={{
                fontSize: 12,
                fontWeight: 600,
                lineHeight: "19px"
              }}
            >
              NEW PASSWORD
            </label>
            <input
              className="border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark bg-mainBackground-light dark:bg-mainBackground-dark focus:outline-none"
              type="password"
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              style={{
                borderBottomWidth: 1,
                paddingBottom: 10
              }}
              autoCapitalize="off"
            />
          </div>
          <div className="flex flex-col">
            <label
              className="text-text2-light dark:text-text2-dark"
              style={{ fontSize: 12, fontWeight: 600, lineHeight: "19px" }}
            >
              CONFIRM NEW PASSWORD
            </label>
            <input
              className="border-stroke-light dark:border-stroke-dark text-text1-light dark:text-text1-dark bg-mainBackground-light dark:bg-mainBackground-dark focus:outline-none"
              type="password"
              value={newPasswordConfirm}
              onChange={(event) => setNewPasswordConfirm(event.target.value)}
              style={{
                borderBottomWidth: 1,
                paddingBottom: 10
              }}
              autoCapitalize="off"
            />
          </div>
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flex: 1,
            borderRadius: 10,
            alignItems: "stretch",
            justifyContent: "stretch"
          }}
        >
          <MainButton
            text="Confirm password change"
            onClick={onSubmit}
            paddingVertical={10}
            isDisabled={
              !newPassword.trim().length ||
              !newPasswordConfirm.trim().length ||
              !oldPassword.length
            }
          />
        </div>
      </div>
    </Popup>
  ) : null;
}

export default ChangePasswordPopup;
