import React from "react";

const TypingDots = () => {
  return (
    <div className="flex items-center h-5 mx-3">
      <div className="w-1.5 h-1.5 rounded-full bg-brand animate-pulse wave-dot-1 mr-1"></div>
      <div className="w-1.5 h-1.5 rounded-full bg-brand animate-pulse wave-dot-2 mr-1"></div>
      <div className="w-1.5 h-1.5 rounded-full bg-brand animate-pulse wave-dot-3"></div>
    </div>
  );
};

export default TypingDots;
