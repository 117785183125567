import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AllCollections} from "../common/home/models/AllCollections";
import {resetStore} from "./resetStore";

type InitialState = {
    collections: AllCollections;
}

const initialState: InitialState = {
    collections: {collections: []},
};

export const collectionSlice = createSlice({
    name: "collection",
    initialState,
    reducers: {
        setCollections: (state, action: PayloadAction<AllCollections>) => {
            state.collections = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetStore, () => initialState);
    },
});

export const {setCollections} = collectionSlice.actions;
export default collectionSlice.reducer;