import React, { useEffect, useState } from "react";
import ThemeIcon from "../../widgets/ThemeIcon";
import HomeMenuButton from "../../widgets/HomeMenuButton";
import AuthButton from "../../widgets/AuthButton";
import SettingsButton from "../../widgets/SettingsButton";
import {
  getAllVotes,
  getAllVotesByStatus,
  getChatIdByMessageId
} from "../../common/home/api";
import { VoteBody } from "../../common/home/models/VoteBody";
import VotePopup from "../home/widgets/VotePopup";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { updateIsVote } from "../../redux/utils";
import { updateCurrentMessage } from "../../redux/messages";
import { MessageBody } from "../../common/home/models/MessageBody";
import { useNavigate } from "react-router-dom";
import { MessageType } from "../../common/home/models/MessageType";

function Vote() {
  const [filterByGood, setFilterByGood] = useState(true);
  const [filterByBad, setFilterByBad] = useState(true);
  const isVote = useAppSelector((state) => state.utils.isVote);
  const authToken = useAppSelector((state) => state.user.access_token);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [votes, setVotes] = useState<VoteBody[]>([]);

  useEffect(() => {
    if (filterByGood && filterByBad) {
      getAllVotes().then((allVotes) => {
        if (Array.isArray(allVotes)) {
          setVotes(allVotes);
        } else {
          console.error(
            "The response from getAllVotes is not an array:",
            allVotes
          );
        }
      });
      return;
    }
    if (filterByGood) {
      getAllVotesByStatus("1").then((allVotes) => {
        if (Array.isArray(allVotes)) {
          setVotes(allVotes);
        } else {
        }
      });
      return;
    }
    if (filterByBad) {
      getAllVotesByStatus("2").then((allVotes) => {
        if (Array.isArray(allVotes)) {
          setVotes(allVotes);
        }
      });
      return;
    }

    setVotes([]);
  }, [filterByGood, filterByBad]);

  const handleStatusClick = (e: any, vote: VoteBody) => {
    const msg: MessageBody = {
      message_type: MessageType.general,
      message_id: vote.message_id,
      message: vote.question,
      status: vote.status
    };
    dispatch(updateCurrentMessage(msg));
    dispatch(updateIsVote(!isVote));
  };

  const handleVoteSubmit = () => {
    getAllVotes().then((allVotes) => {
      if (Array.isArray(allVotes)) {
        setVotes(allVotes);
      } else {
        console.error(
          "The response from getAllVotes is not an array:",
          allVotes
        );
      }
    });
  };

  const handleQuestionClick = async (e: any, vote: VoteBody) => {
    const chatId = await getChatIdByMessageId(vote.message_id, authToken);
    navigate(`/chat/${chatId}`);
  };

  return (
    <div className="flex flex-col bg-altBackground-light dark:bg-altBackground-dark m-0 h-full overflow-y-scroll">
      <div
        style={{ paddingTop: 20, paddingBottom: 20 }}
        className="flex items-center justify-end bg-mainBackground-light dark:bg-mainBackground-dark h-16"
      >
        <HomeMenuButton />
        {isVote && <VotePopup handleVoteSubmit={handleVoteSubmit} />}
      </div>
      <div className="p-4">
        <div className="flex flex-row justify-center items-center">
          <button
            type="button"
            className={`w-1/5 py-2 bg-green-500 hover:bg-green-600 text-white font-semibold rounded m-1 ${
              filterByGood ? "border-4 border-blue-700" : ""
            }`}
            onClick={() => setFilterByGood(!filterByGood)}
          >
            GOOD
          </button>
          <button
            type="button"
            className={`w-1/5 py-2 bg-red-500 hover:bg-red-600 text-white font-semibold rounded m-1 ${
              filterByBad ? "border-4 border-blue-700" : ""
            }`}
            onClick={() => setFilterByBad(!filterByBad)}
          >
            BAD
          </button>
        </div>
        {votes.map((vote) => (
          <div
            key={vote.message_id}
            className="flex items-start border p-4 my-4 rounded blocktext-text1-light dark:text-text1-dark"
          >
            {vote.status && (
              <button
                type="button"
                className={`mr-4 px-2 py-1 text-sm font-semibold rounded ${
                  vote.status === 1
                    ? "bg-green-500 hover:bg-green-600 text-white"
                    : vote.status === 2
                    ? "bg-red-500 hover:bg-red-600 text-white"
                    : ""
                }`}
                onClick={(e) => handleStatusClick(e, vote)}
              >
                {vote.status === 1 ? "GOOD" : vote.status === 2 ? "BAD" : ""}
              </button>
            )}
            <div>
              <h3
                className="font-bold text-lg"
                onClick={(e) => handleQuestionClick(e, vote)}
              >
                Question: {vote.question}
              </h3>
              <p className="mt-2">Answer: {vote.answer}</p>
              {vote.comment && <p className="mt-2">Comment: {vote.comment}</p>}
              {vote.manual_answer && (
                <p className="mt-2">Manual Answer: {vote.manual_answer}</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Vote;
