import React, { useState } from "react";
import CloseButton from "../../../widgets/CloseButton";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import { MessageBody } from "../../../common/home/models/MessageBody";
import { isMobile } from "../../../utils";
import { generateThumbnailRequest } from "../../../common/home/api";
import { updateIsError, updateIsSuccess } from "../../../redux/error";
import Popup from "./Popup";

type DetailsStatus = "Sources" | "Thought Process";

function DetailsPopup() {
    const message: MessageBody = useAppSelector(
        (state) => state.messages.currentMessage
    );
    const [detailsStatus, setDetailsStatus] =
        useState<DetailsStatus>("Sources");
    const [thumbnailPopup, setThumbnailPopup] = useState<boolean>(false);

    const [thumbnailLink, setThumbnailLink] = useState<string>("");

    const handleThumbnailPopupClose = () => {
        setThumbnailPopup(false);
    };

    const dispatch = useAppDispatch();

    const viewDetails = isMobile
        ? "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col h-1/2 w-full bg-altBackground-light dark:bg-altBackground-dark rounded-lg z-20"
        : "fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex-col h-1/2 w-1/2 bg-altBackground-light dark:bg-altBackground-dark rounded-lg z-20";

    const generateThumbnail = (text: string) => {
        setThumbnailLink("");
        setThumbnailPopup(true);
        generateThumbnailRequest(text)
            .then((res) => {
                dispatch(updateIsSuccess({ message: "Thumbnail Generated" }));
                setThumbnailLink(res.url)
            })
            .catch((err) => {
                dispatch(updateIsError({ message: "Thumbnail Safety Error" }));
                handleThumbnailPopupClose();
            });
    };

    return (
        <div className={viewDetails}>
            {thumbnailPopup && 
            (<Popup title="Thumbnail" onClose={handleThumbnailPopupClose}>
                <div>
                    <img src={thumbnailLink} alt="Generating thumbnail" />
                </div>
            </Popup>)}
            <div className="relative flex items-center justify-center h-full">
                <div className="absolute top-10 right-0 left-0 bottom-0 overflow-y-scroll p-2">
                    <div className="flex flex-row p-1 w-full rounded-md leading-6 whitespace-pre-wrap text-sm text-white">
                        <button
                            type="button"
                            className="w-full my-5 py-2 mr-1 bg-blue-500 shadow-lg hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                            onClick={() => {
                                setDetailsStatus("Sources");
                            }}
                        >
                            Sources
                        </button>
                        <button
                            type="button"
                            className="w-full my-5 py-2 ml-1 bg-blue-500 shadow-lg hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                            onClick={() => {
                                setDetailsStatus("Thought Process");
                                console.log(message);
                            }}
                        >
                            Thought Process
                        </button>
                    </div>
                    {detailsStatus === "Sources" ? (
                        <div className="flex flex-col break-words m-3 p-1 rounded-md leading-6 whitespace-pre-wrap text-sm text-text1-light dark:text-text1-dark">
                            {message?.chunks?.map((chunk, index) => (
                                <div key={index} className="mb-4">
                                    <h2 className="text-xl font-semibold mb-2">
                                        ID: {chunk.fields.id}, SOURCE=
                                        {chunk.fields.source}
                                    </h2>
                                    <h2 className="text-xl font-semibold mb-2">
                                        Distance: {chunk.distance}
                                    </h2>
                                    <h2 className="text-xl font-semibold mb-2">
                                        Timestamp:{" "}
                                        {new Date(
                                            chunk.fields.timestamp
                                        ).toLocaleString()}
                                    </h2>
                                    <p className="text-sm">
                                        {chunk.fields.text}
                                    </p>
                                    {/* <button
                                        type="button"
                                        className="w-60 my-5 py-2 mr-1 bg-blue-500 shadow-lg hover:shadow-blue-500/40 text-white font-semibold rounded-lg"
                                        onClick={() => {
                                            generateThumbnail(
                                                chunk.fields.text
                                            );
                                        }}
                                    >
                                        Generate Thumbnail
                                    </button> */}
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="w-full h-full">
                            <div className="flex flex-col m-3 p-1 rounded-md leading-6 whitespace-pre-wrap text-sm text-text1-light dark:text-text1-dark">
                                <div className="mb-4">
                                    {/* <h2 className="text-xl font-semibold mb-2">
                                            Prompt
                                        </h2> */}
                                    <p className="text-sm">{message.prompt}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <CloseButton />
        </div>
    );
}

export default DetailsPopup;
