import React from "react";
import { isMobile } from "../../../utils";

type PropsT = {
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
};

const Alert: React.FC<PropsT> = (props: PropsT) => {
  return (
    <div
      className="border-altBackground-light dark:border-altBackground-dark transition duration-300 ease-in-out bg-mainBackground-light dark:bg-mainBackground-dark text-text1-light dark:text-text1-dark"
      style={{
        position: "absolute",
        top: 0,
        right: 0,
        display: "flex",
        maxWidth: isMobile ? undefined : "20%",
        margin: 20,
        zIndex: 14,
        alignSelf: "center",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 20,
        borderWidth: 1
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          position: "relative"
        }}
      >
        <div
          style={{
            paddingTop: 17,
            display: "flex",
            fontSize: 15,
            lineHeight: "30px",
            fontWeight: 500,
            marginLeft: 56,
            marginRight: 56,
            minHeight: 50
          }}
        >
          {props.title ? <span>{props.title}</span> : null}
        </div>
        <button
          style={{
            display: "flex",
            width: 32,
            height: 32,
            position: "absolute",
            top: 16,
            right: 14
          }}
          onClick={props.onClose}
        >
          <img src="/close-button.svg" className="dark:hidden" />
          <img src="/close-button-dark.svg" className="hidden dark:flex" />
        </button>
      </div>
      <div style={{ display: "flex", padding: 20 }}>{props.children}</div>
    </div>
  );
};

export default Alert;
