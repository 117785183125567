import React, { useState } from "react";
import { MessageTagТ, MessageType } from "../common/home/models/MessageType";
import { MessageBody } from "../common/home/models/MessageBody";
import { CheckBox } from "./CheckBox";

export function MessageTag(
  props: MessageTagТ & {
    messageType: MessageBody["message_type"];
    onChangeSelection: (isSelected: boolean) => void;
    isDisabled?: boolean;
    isSelected?: boolean;
  }
) {
  const [isSelected, setIsSelected] = useState(false);

  return (
    <div
      className={`text-text1-light dark:text-text1-dark transition duration-300 ease-in-out ${
        !props.isDisabled ? "hover:opacity-60" : ""
      } ${
        props.isSelected ?? isSelected
          ? "bg-highlight dark:bg-highlight border-brand dark:border-brand"
          : "border-stroke-light dark:border-stroke-dark"
      }`}
      style={{
        display: "flex",
        fontSize: 15,
        lineHeight: "24px",
        fontWeight: 400,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 8,
        paddingRight: 14,
        borderRadius: 10,
        borderWidth: 1,
        marginRight: 10,
        marginTop: 10,
        cursor: "pointer",
        opacity: props.isDisabled ? 0.5 : undefined
      }}
      onClick={() => {
        if (props.isDisabled) {
          return;
        }
        setIsSelected(!isSelected);
        props.onChangeSelection(!isSelected);
      }}
    >
      {props.messageType !== MessageType.confirmation ? (
        <CheckBox isSelected={props.isSelected ?? isSelected} />
      ) : null}
      <span
        style={{ marginLeft: 7, display: "flex", flex: 1, userSelect: "none" }}
      >
        {props.tag_name}
      </span>
    </div>
  );
}
