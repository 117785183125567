import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user";
import messagesReducer from "./messages";
import chatsReducer from "./chats";
import errorReducer from "./error";
import utilsReducer from "./utils";
import collectionReducer from "./collections";
import SecureLS from "secure-ls";

const ls = new SecureLS();

let savedState;

const oldSavedState = localStorage.getItem("reduxState");

if (oldSavedState) {
  try {
    savedState = JSON.parse(oldSavedState);
  } catch (err) {
    // Ignore
  } finally {
    localStorage.removeItem("reduxState");
  }
} else {
  try {
    savedState = JSON.parse(ls.get("state"));
  } catch (err) {
    ls.remove("state");
  }
}

const store = configureStore({
  reducer: {
    user: userReducer,
    messages: messagesReducer,
    chats: chatsReducer,
    error: errorReducer,
    utils: utilsReducer,
    collections: collectionReducer
  },
  preloadedState: savedState ? savedState : undefined
});

store.subscribe(() => {
  const currentState = store.getState();

  const { messages, ...rest } = currentState;

  ls.set("state", JSON.stringify(rest));
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
