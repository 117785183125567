type PropsT = {
  onClick?: () => void;
  zIndex: number;
};

export function Mask(props: PropsT) {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: props.zIndex
      }}
      onClick={props.onClick}
    />
  );
}
