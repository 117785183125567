import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import { updateIsSuccess } from "../../redux/error";

function Success() {
  const dispatch = useAppDispatch();
  const successInfo = useAppSelector((state) => state.error.isSuccess);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(updateIsSuccess(false));
    }, 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [successInfo]);

  return successInfo ? (
    <div
      style={{
        marginTop: 85,
        zIndex: 100
      }}
      className="fixed inset-0 pointer-events-none"
    >
      <div
        style={{
          borderWidth: 1,
          borderRadius: 10,
          overflow: "hidden"
        }}
        className="relative max-w-md mx-auto flex items-center justify-center px-6 py-4 border-stroke-light dark:border-stroke-dark bg-altBackground-light dark:bg-altBackground-dark text-text1-light dark:text-text1-dark shadow-xl"
      >
        <div className="text-center font-semibold text-text1-light dark:text-text1-dark">
          {typeof successInfo === "boolean" ? "Success!" : successInfo.message}
        </div>
      </div>
    </div>
  ) : null;
}

export default Success;
